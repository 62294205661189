import React, { FunctionComponent } from "react";
import { CompanyType } from "../../../enums/companies-enums";
import { translate } from "../../../i18n/i18n";

import styles from "./CompanyCard.module.scss";

interface CompanyCardProps {
  company?: any,
  index?: number,
  header?: boolean,
  handleEdit?: any
}

export const CompanyCard: FunctionComponent<CompanyCardProps> = ({ company, index, header, handleEdit }) => {
  console.log(company);
  const edit = (company: CompanyType) => {
    const editCompany: CompanyType = {
      ...company,
      locations: company?.locations?.map((location: any) =>  location.id)
    };

    handleEdit(company);
  };

  return <div key={index}>
    {header && !index ? (<div className={`${styles.wrapper} ${styles.header}`}>
      <div className={styles.header}>{translate(`CompanyCard.name`)}</div>
      <div className={styles.value}>{translate(`CompanyCard.apiConstant`)}</div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.street`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.streetNo`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.zip`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.city`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.country`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.taxId`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.locations`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.edit`)}</span>
      </div>
    </div>) : (<div key={index} className={`${styles.wrapper} ${styles.body}`}>
      <div className={styles.header}>{company.name}</div>
      <div className={styles.value}>{company.apiConstant}</div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.street`)}</span>
        <span className={styles.attributeValue}>{company.street}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.streetNo`)}</span>
        <span className={styles.attributeValue}>{company.streetNo}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.zip`)}</span>
        <span className={styles.attributeValue}>{company.zip}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.city`)}</span>
        <span className={styles.attributeValue}>{company.city}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.country`)}</span>
        <span className={styles.attributeValue}>{company.country}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.taxId`)}</span>
        <span className={styles.attributeValue}>{company.taxId}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`CompanyCard.locations`)}</span>
        <span className={styles.attributeValue}>{company.locations.map((location: any, index: number) => <span
          className={styles.location} key={index}>{location.name}</span>)}</span>
      </div>
      <button className={styles.confirmButton} onClick={() => {
        edit(company);
      }
      }>{translate(`CompanyCard.edit`)}</button>
    </div>)}
  </div>;

};