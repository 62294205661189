import React, {Dispatch, FunctionComponent, useEffect, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNil } from "lodash";
import { fetchDashboard, kpiSelector, triggerKpiFetch } from "../../store/kpis/kpiSlice";
import { notificationSelector } from "../../store/notifications/notificationSlice";
import { userSelector } from "../../store/users/userSlice";
import LocationOverview from "../../components/shared/LocationOverview/LocationOverview";
import Loader from "../../components/shared/Loader";
import styles from "./Dashboard.module.scss";

interface DashboardProps { }

const Dashboard: FunctionComponent<DashboardProps> = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { token } = useSelector(notificationSelector);
  const { isLoading, locations, reload } = useSelector(kpiSelector);
  const { myUser } = useSelector(userSelector);
  const interval = useRef<any>(null);
  useEffect(() => {
    const lsToken: string | null = localStorage.getItem("notificationToken");
    if (isNil(lsToken)) {
      localStorage.setItem("notificationToken", token);
    }
  }, []);
  useEffect(() => {
    if(interval?.current) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(() => {
      dispatch({
        ...fetchDashboard({
          requestType: "GET",
          request: `/kpi/locations`
        })
      });
    }, 30000);
    return () => {
      clearInterval(interval?.current);
    };
  }, []);

  return (
    <div className={styles.dashboardWrapper}>
      {
        !isEmpty(locations) && myUser ?
          <>
            <div className={styles.greetings}>Welcome back, {myUser.firstName}.</div>
            {
              locations.map((location: any, index: number) => (
                <div key={index} className={styles.locationOverviewWrapper}>
                  <LocationOverview location={location} />
                </div>
              ))
            }
          </> :
          <Loader />
      }
    </div>
  );
};

export default Dashboard;
