import React, { FunctionComponent } from "react";
import { ContractType } from "../../../enums/contracts-enums";
import { translate } from "../../../i18n/i18n";
import {
  BaseCard,
  BaseCardAttribute,
  BaseCardAttributeHeader,
  BaseCardEdit,
  BaseCardHeader,
  BaseCardValue
} from "../BaseCard/BaseCard";

interface ContractCardProps {
  contract?: any,
  index?: number,
  header?: boolean,
  handleEdit?: any
}

export const ContractCard: FunctionComponent<ContractCardProps> = ({
                                                                     contract,
                                                                     index,
                                                                     header,
                                                                     handleEdit
                                                                   }) => {

  const edit = (contract: ContractType) => {
    handleEdit(contract);
  };

  return <BaseCard index={index} header={header} columns={10}>
    {header && !index ? (
      <>
        <BaseCardHeader>{translate(`ContractCard.type`)}</BaseCardHeader>
        <BaseCardValue>{translate(`ContractCard.company`)}</BaseCardValue>
        <BaseCardAttributeHeader>{translate(`ContractCard.time`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`ContractCard.severity`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`ContractCard.min`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`ContractCard.max`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`ContractCard.notification`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`ContractCard.alert`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`ContractCard.goal`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`ContractCard.edit`)}</BaseCardAttributeHeader>
      </>
    ) : <>
      <BaseCardHeader>{contract.type}</BaseCardHeader>
      <BaseCardValue>{contract.company.name}</BaseCardValue>
      <BaseCardAttribute title={translate(`ContractCard.time`)}>{contract.timeReference}</BaseCardAttribute>
      <BaseCardAttribute title={translate(`ContractCard.severity`)}>{contract.severityReference}</BaseCardAttribute>
      <BaseCardAttribute title={translate(`ContractCard.min`)}>{contract.min || ''}</BaseCardAttribute>
      <BaseCardAttribute title={translate(`ContractCard.max`)}>{contract.max || ''}</BaseCardAttribute>
      <BaseCardAttribute title={translate(`ContractCard.notification`)}>{contract.notification ? translate(`ContractCard.yes`) : translate(`ContractCard.no`)}</BaseCardAttribute>
      <BaseCardAttribute title={translate(`ContractCard.alert`)}>{contract.alert ? translate(`ContractCard.yes`) : translate(`ContractCard.no`)}</BaseCardAttribute>
      <BaseCardAttribute title={translate(`ContractCard.goal`)}>{contract.goal ? translate(`ContractCard.yes`) : translate(`ContractCard.no`)}</BaseCardAttribute>
      <BaseCardEdit edit={() => edit(contract)} data={contract}>{translate(`ContractCard.edit`)}</BaseCardEdit>
    </>}
  </BaseCard>;
};