import React, { FunctionComponent } from "react";
import { Kpis, KpiType } from "../../../enums/kpi.enum";
import { ProcessesEnums } from "../../../enums/processes-enums";
import { useAlerts } from "../../../hooks/alertHook";
import { AlertListByProcess } from "../../shared/AlertListByProcess/AlertListByProcess";
import KpiContainer from "../../shared/KpiContainer/KpiContainer";
import OverviewTabContainer from "../../shared/OverviewTabContainer/OverviewTabContainer";
import styles from "./LocationTabsOutbound.module.scss";

interface LocationTabsOutboundProps {
  locationId: string;
}

const LocationTabsOutbound: FunctionComponent<LocationTabsOutboundProps> = ({ locationId }) => {
  const { alertsData } = useAlerts(locationId, ProcessesEnums.OUTBOUND);
  return (
    <div className={styles.outboundTab}>
      <div className={styles.onlyMobile}>
        <AlertListByProcess alertsData={alertsData} locationId={locationId} />
      </div>

      <div className={styles.overview}>
        <OverviewTabContainer type={Kpis.DELIVERY_AMOUNT} />
      </div>

      <div className={styles.chart}>
        <KpiContainer location={locationId} kpiType={KpiType.OUTBOUND} comparison={true} />
      </div>

      <div className={styles.chart}>
        <KpiContainer location={locationId} kpiType={KpiType.OUTBOUND_TIME} comparison={true} />
      </div>

      <div className={styles.chart}>
        <KpiContainer location={locationId} kpiType={KpiType.PARCEL_AMOUNT} comparison={true} />
      </div>
    </div>
  );
};

export default LocationTabsOutbound;