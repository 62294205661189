import { CSSProperties } from "react";
import { isNil } from "lodash";
import { ChartOptions } from "../interfaces/chart.interface";
import { KpiListElement } from "../interfaces/kpi.interface";
import { DropdownPosition } from "../enums/chart.enum";

const getStorageBarColorByValue = (value: number): string => {
  if (value <= 50) {
    return "#2fc07d";
  } else if (value < 90) {
    return "#e79f34";
  } else {
    return "#eb5757";
  }
};

export const getStorageValueStyle = (storageBarValue: number): CSSProperties => ({
  "--bar-height": `${storageBarValue > 100 ? 100 : storageBarValue}%`,
  "--bar-color": getStorageBarColorByValue(storageBarValue > 100 ? 100 : storageBarValue)
}) as CSSProperties;

export const getBarChartStyle = (storageBarValue: number): CSSProperties => ({
  "--bar-width": `${storageBarValue > 100 ? 100 : storageBarValue}%`,
  "--bar-color": getStorageBarColorByValue(storageBarValue > 100 ? 100 : storageBarValue)
}) as CSSProperties;

export const getBarValue = (location: any): number => {
  if (!isNil(location.storageAll)) {
    return Number(location.storageAll.ofValue) === Number(location.storageAll.value) ? 100 :
           Math.floor((Number(location.storageAll.ofValue) - (Number(location.storageAll.value))) / Number(location.storageAll.ofValue) * 100);
  } else {
    return 0;
  }
};

export const getAlertsCount = (location: any): number => {
  return location.alerts.inbound.length +
    location.alerts.outbound.length +
    location.alerts.storage.length;
};

export const getInitialDropdownOptions = (fullList: KpiListElement[], useNonDefault: boolean = false): ChartOptions => {
  const clearOption: KpiListElement = {
    name: "Select metric",
    value: "CLEAR",
    type: null,
    color: "#fff"
  };

  if (useNonDefault) {
    const filteredFullList: KpiListElement[] = fullList.filter((el: KpiListElement) => el.value !== fullList[0].value && el.value !== fullList[1].value);

    return {
      [DropdownPosition.FIRST]: {
        selected: { ...fullList[0], dropdownPosition: DropdownPosition.FIRST },
        list: [clearOption, ...filteredFullList]
      },
      [DropdownPosition.SECOND]: {
        selected: { ...fullList[1], dropdownPosition: DropdownPosition.SECOND },
        list: [clearOption, ...filteredFullList]
      }
    };
  } else {
    return {
      [DropdownPosition.FIRST]: {
        selected: clearOption,
        list: [...fullList]
      },
      [DropdownPosition.SECOND]: {
        selected: clearOption,
        list: [...fullList]
      }
    };
  }
};

export const getDropdownOptions = (
  selectedKpi: KpiListElement,
  prevChartOptions: ChartOptions,
  fullList: KpiListElement[],
  dropdownPosition: DropdownPosition
): ChartOptions => {
  const clearOption: KpiListElement = {
    name: "Select metric",
    value: "CLEAR",
    type: null,
    color: "#fff"
  };

  if (selectedKpi.value === "CLEAR") {
    const otherSelectedKpiValue: string = dropdownPosition === DropdownPosition.FIRST ?
                                          prevChartOptions[DropdownPosition.SECOND].selected.value :
                                          prevChartOptions[DropdownPosition.FIRST].selected.value;
    const selectedKpiList: KpiListElement[] = fullList.filter((el: KpiListElement) => el.value !== otherSelectedKpiValue);
    const otherSelectedKpiList: KpiListElement[] = otherSelectedKpiValue === "CLEAR" ? selectedKpiList : [clearOption, ...selectedKpiList];

    return {
      [DropdownPosition.FIRST]: {
        selected: dropdownPosition === DropdownPosition.FIRST ? selectedKpi : prevChartOptions[DropdownPosition.FIRST].selected,
        list: dropdownPosition === DropdownPosition.FIRST ? selectedKpiList : otherSelectedKpiList
      },
      [DropdownPosition.SECOND]: {
        selected: dropdownPosition === DropdownPosition.SECOND ? selectedKpi : prevChartOptions[DropdownPosition.SECOND].selected,
        list: dropdownPosition === DropdownPosition.SECOND ? selectedKpiList : otherSelectedKpiList
      }
    };
  } else {
    const otherSelectedKpiValue: string = dropdownPosition === DropdownPosition.FIRST ?
                                prevChartOptions[DropdownPosition.SECOND].selected.value :
                                prevChartOptions[DropdownPosition.FIRST].selected.value;
    const filteredList: KpiListElement[] = fullList.filter((el: KpiListElement) => el.value !== selectedKpi.value && el.value !== otherSelectedKpiValue);

    return {
      [DropdownPosition.FIRST]: {
        selected: dropdownPosition === DropdownPosition.FIRST ? selectedKpi : prevChartOptions[DropdownPosition.FIRST].selected,
        list: dropdownPosition === DropdownPosition.SECOND && otherSelectedKpiValue === "CLEAR" ?
          [...filteredList] :
          [clearOption, ...filteredList]
      },
      [DropdownPosition.SECOND]: {
        selected: dropdownPosition === DropdownPosition.SECOND ? selectedKpi : prevChartOptions[DropdownPosition.SECOND].selected,
        list: dropdownPosition === DropdownPosition.FIRST && otherSelectedKpiValue === "CLEAR" ?
          [...filteredList] :
          [clearOption, ...filteredList]
      }
    };
  }
};