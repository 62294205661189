import { isNil } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { usePagination } from "../../../helpers/usePagination";
import { useTableSearch } from "../../../helpers/useTableSearch/useTableSearch";
import { translate } from "../../../i18n/i18n";
import { initialPaginationState } from "../../../interfaces/pagination-interface";
import { alertSelector, fetchForAlertsPage } from "../../../store/alerts/alertSlice";
import { AlertCard } from "../../shared/AlertCard/AlertCard";
import ContentCard from "../../shared/ContentCard";
import { InputForSearch } from "../../shared/InputForSearch/InputForSearch";
import Loader from "../../shared/Loader";
import { PageWrapper } from "../../shared/PageWrapper/PageWrapper";
import { Pagination } from "../../shared/Pagination/Pagination";
import { PerPageDropdown } from "../../shared/PerPageDropdown/PerPageDropdown";
import styles from "./AlertsOverviewTab.module.scss";

interface AlertsOverviewTabProps {
}

const AlertsOverviewTab: React.FunctionComponent<AlertsOverviewTabProps> = () => {
  const dispatch = useDispatch();

  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } = usePagination();
  const { handleSearchChange, search } = useTableSearch();
  const { isLoading, alertDataPaginated } = useSelector(alertSelector);

  useEffect(() => {
    if (!isLoading) {
      dispatch({
        ...fetchForAlertsPage({
          requestType: "GET",
          request: `/alert?page=${page}&limit=${rowsPerPage}${search !== "" ? `&search=${search}` : ""}`
        })
      });
    }
  }, [isLoading, search, rowsPerPage, page]);

  return (
    <PageWrapper>
      {
        isNil(alertDataPaginated?.data) ?
          <Loader /> :
          <>
            <Grid>

              <Grid.Row>
                <Grid.Column width={16}>
                  <div className={styles.headerWrapper}>
                    <h3 className={styles.header}>Alerts</h3>
                    <InputForSearch value={search} handleChange={handleSearchChange}
                                    placeholder={translate(`alertsOverviewTab.filterBy`)} />
                    <PerPageDropdown value={rowsPerPage} options={initialPaginationState.optionsRowsPerPage}
                                     handleChange={handleChangeRowsPerPage} />
                  </div>
                  <ContentCard>
                    <AlertCard header={true} />
                    {!!alertDataPaginated.data.length && alertDataPaginated.data.map((alert: any, index: number) => {
                      return <AlertCard index={index} alert={alert} />;
                    })}
                    <Pagination page={alertDataPaginated.page} pages={alertDataPaginated.pages}
                                handleChange={handleChangePage} />
                  </ContentCard>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
      }

    </PageWrapper>
  );
};

export default AlertsOverviewTab;
