import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface initialContractsStateInterface {
  data: any,
  contract: any,
  isLoading: boolean,
  error: string,
}

const initialState: initialContractsStateInterface = {
  data: null,
  contract: null,
  isLoading: false,
  error: ""
};

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    fetchContracts: (state, action: any) => {

      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && !!action.response) {
        state.data = action.response;
        state.isLoading = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchContract: (state, action: any) => {
      if (action.state === "finished") {
        state.isLoading = false;
        state.error = "";
      }

      if (action.state === "finished" && !!action.response) {
        state.contract = action.response;
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    setContract: (state, action: any) => {
      state.contract = action.payload;
    },
    addContract: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        const index: number = state.data.findIndex((el: any) => el.id === action.response.id)
        if(index) {
          state.data[index] = action.response;
        } else {
          state.data.push(action.response)
        }

        state.error = "";
        state.isLoading = false;
      }
      if (action.state === "error") {
        state.isLoading = false;
        state.error = action.response?.error;
      }
    },
    editContract: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        const index: number = state.data.findIndex((el: any) => el.id === action.response.id)
        state.data[index] = action.response;
        state.isLoading = false;
      }
    },
    deleteContract: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && action.response) {
        state.isLoading = false;
        state.error = "";
      }
    },
    finishLoading(state) {
      state.isLoading = false;
    },
    clearContract: (state) => {
      state.contract = null;
      state.error = "";
      state.isLoading = false;
    },
    clearContractStore: () => {
      return initialState;
    }
  }
});

export const {
  fetchContracts,
  fetchContract,
  addContract,
  editContract,
  deleteContract,
  finishLoading,
  clearContract,
  setContract,
  clearContractStore
} = contractSlice.actions;
export const contractSelector = (state: RootState) => state.contract;
export default contractSlice.reducer;
