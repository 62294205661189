import React from 'react';
import { Redirect } from 'react-router-dom';
import AlertsOverviewTab from './components/tabs/AlertsOverviewTab/AlertsOverviewTab';
import { UserRole } from './enums/users/users-enums';

// Layout Types
import { AuthLayout, DefaultLayout } from './layouts';
import ForgotPassword from './pages/auth/ForgotPassword';

// Route Views
import Login from './pages/auth/Login/index';
import SetPassword from './pages/auth/register/setPassword';
import { ResetPassword } from './pages/auth/ResetPassword';
import Companies from './pages/Companies/index';
import ContractValues from './pages/ContractValues';
import Dashboard from './pages/Dashboard/Dashboard';
import Locations from './pages/Locations/index';
import Profile from './pages/Profile';
import SingleLocation from './pages/SingleLocation/SingleLocation';
import Users from './pages/Users/index';

const routes: any[] = [
	{
		path: '/',
		exact: true,
		layout: DefaultLayout,
		component: () => <Redirect to="/login" />,
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		path: '/login',
		layout: AuthLayout,
		component: Login,
	},
	{
		path: '/forgot-password',
		layout: AuthLayout,
		component: ForgotPassword,
	},
	{
		path: '/user/reset-password/:token',
		layout: AuthLayout,
		component: ResetPassword,
	},
	{
		path: '/user/confirm',
		layout: AuthLayout,
		component: SetPassword,
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		path: '/dashboard',
		exact: true,
		layout: DefaultLayout,
		component: Dashboard,
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		path: '/location/:id/:menuItem',
		exact: true,
		layout: DefaultLayout,
		component: SingleLocation,
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		path: '/alerts',
		layout: DefaultLayout,
		component: AlertsOverviewTab,
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		path: '/settings/users',
		layout: DefaultLayout,
		component: Users,
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		path: '/settings/companies',
		layout: DefaultLayout,
		component: Companies,
		allowedRoles: [UserRole.SUPER],
	},
	{
		path: '/settings/locations',
		layout: DefaultLayout,
		component: Locations,
		allowedRoles: [UserRole.SUPER],
	},
	{
		path: '/settings/contractValues',
		layout: DefaultLayout,
		component: ContractValues,
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN],
	},
	{
		path: '/my-profile',
		layout: DefaultLayout,
		component: Profile,
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
];

export default routes;
