import React, {useState} from "react";
import {initialPaginationState} from "../interfaces/pagination-interface";

export const usePagination = () => {
    const [pagination, setPagination] =  useState(initialPaginationState);



    const handleChangePage = (page: number) => {
        setPagination({
            ...pagination,
            page: page
        })
    };


    const handleChangeRowsPerPage = (value: number | string): void => {
        setPagination({
            ...pagination,
            rowsPerPage: +value
        })
    };


    const {page, rowsPerPage} = pagination;

    return {pagination, setPagination, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage};
}
