export interface ITimeFrame {
  name: string;
  value: TimeFrame;
};

export enum TimeFrame {
  LAST_24_HOURS = "LAST_24_HOURS",
  YESTERDAY = "YESTERDAY",
  PREV_WEEK = "PREV_WEEK",
  LAST_30_DAYS = "LAST_30_DAYS",
  LAST_6_MONTHS = "LAST_6_MONTHS",
  LAST_YEAR = "LAST_YEAR",
  CUSTOM = "CUSTOM"
};

export const TIME_FRAME_LIST: ITimeFrame[] = [
  {
    name: "Last 24h",
    value: TimeFrame.LAST_24_HOURS
  },
  {
    name: "Yesterday",
    value: TimeFrame.YESTERDAY
  },
  {
    name: "Last week",
    value: TimeFrame.PREV_WEEK
  },
  {
    name: "Last 30 days",
    value: TimeFrame.LAST_30_DAYS
  },
  {
    name: "Last 6 months",
    value: TimeFrame.LAST_6_MONTHS
  },
  {
    name: "Last year",
    value: TimeFrame.LAST_YEAR
  },
  {
    name: "Custom",
    value: TimeFrame.CUSTOM
  }
];

export const SHORT_TIME_FRAME_LIST: ITimeFrame[] = [
  {
    name: "Last 24h",
    value: TimeFrame.LAST_24_HOURS
  },
  {
    name: "Yesterday",
    value: TimeFrame.YESTERDAY
  },
  {
    name: "Last week",
    value: TimeFrame.PREV_WEEK
  },
  {
    name: "Last 30 days",
    value: TimeFrame.LAST_30_DAYS
  }
];

export interface TimeOptions {
  value: TimeFrame;
  SeriesOne: string;
  SeriesTwo: string;
  SeriesAverage: string;
}

export const timeOptions: TimeOptions[] = [
  {
    value: TimeFrame.LAST_24_HOURS,
    SeriesOne: "Today",
    SeriesTwo: "Yesterday",
    SeriesAverage: "Average of hourly values of last 7 days"
  },
  {
    value: TimeFrame.YESTERDAY,
    SeriesOne: "Yesterday",
    SeriesTwo: "Previous Yesterday",
    SeriesAverage: "Average of hourly values of last 7 days"
  },
  {
    value: TimeFrame.PREV_WEEK,
    SeriesOne: "Last 7 days",
    SeriesTwo: "Previous 7 days",
    SeriesAverage: "Average of daily values of last 28 days"
  },
  {
    value: TimeFrame.LAST_30_DAYS,
    SeriesOne: "Last 28 days",
    SeriesTwo: "Previous 28 days",
    SeriesAverage: "Average of daily values of last 56 days"
  }
];
