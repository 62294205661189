import React, {
	Component,
	FunctionComponent,
	useContext,
	useState,
	CSSProperties,
} from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { THEMES } from '../../../enums/theme.enum';
import { clearKpiStore } from '../../../store/kpis/kpiSlice';
import { clearUserStore } from '../../../store/users/userSlice';
import { clearCompanyStore } from '../../../store/companies/companySlice';
import { clearLocationStore } from '../../../store/locations/locationSlice';
import { clearContractStore } from '../../../store/contracts/contractSlice';
import { clearAlertStore } from '../../../store/alerts/alertSlice';
import { clearNotificationStore } from '../../../store/notifications/notificationSlice';
import { clearLoginStore } from '../../../pages/auth/Login/loginSlice';
import {
	setTheme,
	windowDataSelector,
} from '../../../store/window/windowDataSlice';
import {
	IMenuItem,
	MAIN_MENU_ITEMS,
	SUB_MENU_ITEMS,
} from '../../../libs/menu-items';
import styles from './CustomNavigation.module.scss';
import { translate } from '../../../i18n/i18n';

interface CustomNavigationProps {
	children: Component;
}

const CustomNavigation: FunctionComponent<CustomNavigationProps> = ({
	children,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { user } = useContext(UserContext);
	const { isDesktop, theme } = useSelector(windowDataSelector);
	const [showNavigation, setShowNavigation] = useState<boolean>(false);

	const onHamburgerClick = (): void => {
		setShowNavigation((prevState: boolean) => !prevState);
	};

	const logout = (): void => {
		dispatch(clearKpiStore());
		dispatch(clearUserStore());
		dispatch(clearCompanyStore());
		dispatch(clearLocationStore());
		dispatch(clearContractStore());
		dispatch(clearAlertStore());
		dispatch(clearNotificationStore());
		dispatch(clearLoginStore());
		localStorage.clear();
		history.push('/login');
	};

	const goToProfile = () => history.push('/my-profile');

	const onMenuItemClick = (itemName: string): void => {
		if (itemName === 'logout') {
			logout();
		}
		setShowNavigation(false);
	};

	const changeTheme = (): void => {
		if (theme === THEMES.LIGHT) {
			dispatch(setTheme(THEMES.DARK));
			localStorage.setItem('theme', THEMES.DARK);
		} else {
			dispatch(setTheme(THEMES.LIGHT));
			localStorage.setItem('theme', THEMES.LIGHT);
		}
	};

	return (
		<>
			<div
				className={`${styles.navigation} ${
					showNavigation ? styles.expand : ''
				}`}
			>
				<div
					className={styles.toggleButtonContainer}
					onClick={onHamburgerClick}
				>
					<div className={styles.toggleIcon} />
				</div>

				<div className={styles.headerWrapper}>
					<div className={styles.logo} onClick={() => history.push("/dashboard")}/>

					<div className={styles.menuIcon}>
						{!showNavigation && (
							<MenuIcon
								onClick={onHamburgerClick}
								fontSize="large"
							/>
						)}
						{showNavigation && (
							<ClearIcon
								onClick={onHamburgerClick}
								fontSize="large"
							/>
						)}
					</div>
				</div>

				<div
					className={`${styles.navWrapper} ${
						showNavigation ? styles.show : ''
					}`}
				>
					<div
						className={`${styles.menuItem} ${styles.logoutMenuItem}`}
					>
						<a onClick={logout}>
							<div
								className={styles.menuItemIcon}
								style={
									{
										'--background-image-url':
											"url('/icons/logout.svg')",
									} as CSSProperties
								}
							/>
							<div className={styles.menuLink}>Logout</div>
						</a>
					</div>
					<div
						className={styles.avatarContainer}
						onClick={goToProfile}
					>
						<div className={styles.avatarWrapper}>
							<Avatar className={styles.avatar}>
								{user?.firstName?.substr(0, 1)}
								{user?.lastName?.substr(0, 1)}
							</Avatar>
							<div className={styles.avatarText}>
								{translate('editProfile.myProfile')}
							</div>
						</div>
						<div />
					</div>

					<div className={styles.mainMenuItems}>
						{MAIN_MENU_ITEMS.map((el: IMenuItem, index: number) => {
							const style = {
								'--background-image-url': `url(${
									history.location.pathname === el.path
										? el.iconPathSelected
										: el.iconPath
								})`,
							} as CSSProperties;

							if (el.allowedRoles.includes(user?.role)) {
								return (
									<div
										key={index}
										className={styles.menuItem}
									>
										<NavLink
											to={el.path}
											onClick={() =>
												onMenuItemClick(el.label)
											}
											activeClassName={styles.selected}
										>
											<div
												className={styles.menuItemIcon}
												style={style}
											/>
											<div className={styles.menuLink}>
												{el.label}
											</div>
										</NavLink>
									</div>
								);
							} else {
								return null;
							}
						})}
					</div>

					<div className={styles.divider} />

					<div className={styles.subMenuItems}>
						{SUB_MENU_ITEMS.map((el: IMenuItem, index: number) => {
							const style = {
								'--background-image-url': `url(${
									history.location.pathname === el.path
										? el.iconPathSelected
										: el.iconPath
								})`,
							} as React.CSSProperties;

							if (el.allowedRoles.includes(user?.role)) {
								return (
									<div
										key={index}
										className={`${styles.menuItem} ${
											(el.label === 'logout' ||
												el.label === 'my profile') &&
											isDesktop
												? styles.hide
												: ''
										}`}
									>
										<NavLink
											to={el.path}
											onClick={() =>
												onMenuItemClick(el.label)
											}
											activeClassName={styles.selected}
										>
											<div
												className={styles.menuItemIcon}
												style={style}
											/>
											<div className={styles.menuLink}>
												{el.label}
											</div>
										</NavLink>
									</div>
								);
							} else {
								return null;
							}
						})}
					</div>

					<div className={styles.themeButtonContainer}>
						<div
							className={styles.themeButton}
							onClick={changeTheme}
						/>
					</div>
				</div>
			</div>

			<div className={styles.main}>{children}</div>
		</>
	);
};

export default CustomNavigation;
