import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDesktop, windowDataSelector } from "../store/window/windowDataSlice";

export const useWindowSize = () => {

  const [classes, setClasses] = useState<string>("");
  const dispatch = useDispatch();
  const { isDesktop } = useSelector(windowDataSelector);
  useEffect(() => {

    function handleResize() {

      const getClassNames = (windowWidth: number): string => {
        const desktopWidth: number = 1024;
        const smallMobileWidth: number = 350;
        let innerIsDesktop = false;
        let screensize = "isDesktop";
        switch (true) {
          case windowWidth >= desktopWidth:
            innerIsDesktop = true;
            break;
          case windowWidth <= smallMobileWidth:
            innerIsDesktop = false;
            screensize = "isSmallMobile";
            break;
          default:
            innerIsDesktop = false;
            screensize = "isMobile";
        }
        if (innerIsDesktop !== isDesktop) {
          dispatch(setIsDesktop(innerIsDesktop));
        }
        return screensize;
      };

      const newTag = getClassNames(window.innerWidth);
      if (newTag !== classes) {
        // console.log(window.innerWidth, classes, newTag)
        setClasses(newTag);
      }
      return false;
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return classes;
};
