import { isNil } from "lodash";
import { StorageType } from "../enums/kpi.enum";
import { StorageOverviewItem } from "../interfaces/kpi.interface";

export const getStorageAllValue = (storageOverview: StorageOverviewItem[]): number => {
  const storageAll: StorageOverviewItem | undefined = storageOverview.find((item: StorageOverviewItem) => item.type === StorageType.STORAGE_ALL);

  if (!isNil(storageAll)) {
    return Number(storageAll.ofValue) === Number(storageAll.value) ? 100 :
           Math.floor((Number(storageAll.ofValue) - (Number(storageAll.value))) / Number(storageAll.ofValue) * 100);
  } else {
    return 0;
  }
};

export const getAllStorages = (storageOverview: StorageOverviewItem[]): StorageOverviewItem[] => {
  const storageABC: StorageOverviewItem[] = storageOverview.filter((item: StorageOverviewItem) => item.type === StorageType.STORAGE_ABC);
  const storageZone: StorageOverviewItem[] = storageOverview.filter((item: StorageOverviewItem) => item.type === StorageType.STORAGE_ZONE);
  return [...storageABC, ...storageZone];
};

export const getStorageValue = (storage: StorageOverviewItem): number => {
  return Number(storage.ofValue) === Number(storage.value) ? 100 :
         Math.floor((Number(storage.ofValue) - (Number(storage.value))) / Number(storage.ofValue) * 100);
};
