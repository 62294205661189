import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import loginReducer from "../pages/auth/Login/loginSlice";
import registerReducer from "../pages/auth/register/registerSlice";
import userReducer from "../store/users/userSlice";
import companyReducer from "../store/companies/companySlice";
import contractReducer from "../store/contracts/contractSlice";
import locationReducer from "../store/locations/locationSlice";
import kpiReducer from "../store/kpis/kpiSlice";
import notificationReducer from "../store/notifications/notificationSlice";
import windowDataReducer from "../store/window/windowDataSlice";
import alertReducer from "../store/alerts/alertSlice";
import requestMiddleware from "../libs/index";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    register: registerReducer,
    user: userReducer,
    company: companyReducer,
    location: locationReducer,
    kpi: kpiReducer,
    contract: contractReducer,
    notification: notificationReducer,
    windowData: windowDataReducer,
    alert: alertReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(requestMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
