import React, { FunctionComponent } from "react";
import { LocationType } from "../../../enums/locations-enums";
import { translate } from "../../../i18n/i18n";
import {
  BaseCard,
  BaseCardAttribute,
  BaseCardAttributeHeader,
  BaseCardEdit,
  BaseCardHeader,
  BaseCardValue
} from "../BaseCard/BaseCard";

import styles from "./LocationTableCard.module.scss";

interface LocationTableCardProps {
  location?: any,
  index?: number,
  header?: boolean,
  handleEdit?: any
}

export const LocationTableCard: FunctionComponent<LocationTableCardProps> = ({
                                                                               location,
                                                                               index,
                                                                               header,
                                                                               handleEdit
                                                                             }) => {

  const edit = (location: LocationType) => {
    handleEdit(location);
  };

  return <BaseCard index={index} header={header} columns={7}>
    {header && !index ? (
      <>
        <BaseCardHeader>{translate(`LocationTableCard.name`)}</BaseCardHeader>
        <BaseCardValue>{translate(`LocationTableCard.company`)}</BaseCardValue>
        <BaseCardAttributeHeader>{translate(`LocationTableCard.apiConstant`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`LocationTableCard.address`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`LocationTableCard.shift`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`LocationTableCard.shiftOn`)}</BaseCardAttributeHeader>
        <BaseCardAttributeHeader>{translate(`LocationTableCard.edit`)}</BaseCardAttributeHeader>

      </>
    ) : <>
      <BaseCardHeader>{location.name}</BaseCardHeader>
      <BaseCardValue>{location.company.name}</BaseCardValue>
      <BaseCardAttribute title={translate(`LocationTableCard.apiConstant`)}>{location.apiConstant}</BaseCardAttribute>
      <BaseCardAttribute
        title={translate(`LocationTableCard.address`)}>{location.street} {location.streetNo}<br />{location.zip}, {location.city}
      </BaseCardAttribute>
      <BaseCardAttribute
        title={translate(`LocationTableCard.shift`)}>{location.shiftStart} - {location.shiftEnd}</BaseCardAttribute>
      <BaseCardAttribute title={translate(`LocationTableCard.shiftOn`)}>
        <div className={`${styles.weekday} ${location.monday && styles.active}`}>{translate(`monday`)}</div>
        <div className={`${styles.weekday} ${location.tuesday && styles.active}`}>{translate(`tuesday`)}</div>
        <div className={`${styles.weekday} ${location.wednesday && styles.active}`}>{translate(`wednesday`)}</div>
        <div className={`${styles.weekday} ${location.thursday && styles.active}`}>{translate(`thursday`)}</div>
        <div className={`${styles.weekday} ${location.friday && styles.active}`}>{translate(`friday`)}</div>
        <div className={`${styles.weekday} ${location.saturday && styles.active}`}>{translate(`saturday`)}</div>
        <div className={`${styles.weekday} ${location.sunday && styles.active}`}>{translate(`sunday`)}</div>
      </BaseCardAttribute>
      <BaseCardEdit edit={() => edit(location)} data={location}>{translate(`LocationTableCard.edit`)}</BaseCardEdit>
    </>}
  </BaseCard>;
};