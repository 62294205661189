import { isEmpty } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "semantic-ui-react";
import useUserContext from "../../../contexts/UserContext/UserContext";
import { ContractType } from "../../../enums/contracts-enums";
import { KpiReference } from "../../../enums/kpi.enum";
import { SeverityReference } from "../../../enums/severityReference";
import { TimeReference } from "../../../enums/timeReference";
import { translate } from "../../../i18n/i18n";
import { companySelector, fetchCompanies } from "../../../store/companies/companySlice";
import { addContract, deleteContract } from "../../../store/contracts/contractSlice";
import { fetchLocations, locationSelector } from "../../../store/locations/locationSlice";
import { Checkbox } from "../../shared/Checkbox/Checkbox";
import { Dropdown } from "../../shared/Dropdown/Dropdown";
import { Input } from "../../shared/Input/Input";
import { ModalButtonWrapper } from "../../shared/ModalButtonWrapper/ModalButtonWrapper";
import { ModalCancelButton } from "../../shared/ModalCancelButton/ModalCancelButton";
import { ModalCreateButton } from "../../shared/ModalConfirmButton/ModalCreateButton";
import { ModalWrapper } from "../../shared/ModalWrapper/ModalWrapper";
import AddLocationModal from "../AddLocationModal/AddLocationModal";
import BaseModal, { validateEmail, validateNotEmpty } from "../BaseModal/BaseModal";
import ConfirmModal, { useConfirmModal } from "../ConfirmModal/ConfirmModal";

interface AddLocationModalProps {
  initialContract?: ContractType;
  closeModal: () => void
}

export interface AddLocationInterface {
  id?: number;
  apiConstant?: string;
  name?: string;
  street?: string;
  streetNo?: string;
  zip?: string;
  city?: string;
  country?: string;
  taxId?: string;
  shiftStart?: string;
  shiftEnd?: string;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  company?: number;
}

export const AddContractModal: FunctionComponent<AddLocationModalProps> = ({ initialContract, closeModal }) => {

  const { confirm, closeConfirm, getConfirmation } = useConfirmModal();
  const { user: currentUser } = useUserContext();
  const [contract, setContract] = useState<ContractType | undefined>(initialContract);
  const { error: apiError } = useSelector(locationSelector);
  const [error, setError] = useState<string | null>();
  const { data: companies } = useSelector(companySelector);
  const { data: locations } = useSelector(locationSelector);
  const dispatch = useDispatch();

  function getArrayFromEnum(currentEnum: typeof KpiReference | typeof TimeReference | typeof SeverityReference) {
    return Object.values(currentEnum)?.map((option: string, index: number) => {
      return {
        id: index,
        name: option
      };
    });
  }

  const kpis = getArrayFromEnum(KpiReference);
  const times = getArrayFromEnum(TimeReference);
  const severities = getArrayFromEnum(SeverityReference);
  console.log(severities);
  useEffect(() => {
    if (isEmpty(companies)) {
      dispatch({
        ...fetchCompanies({
          requestType: "GET",
          request: "/company"
        })
      });
    }
    if (isEmpty(locations)) {
      dispatch({
        ...fetchLocations({
          requestType: "GET",
          request: "/location"
        })
      });
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<any>) => {
    setContract({
      ...contract,
      [e.target.name]: e.target.value
    });
  };

  const validate = (contract: any) => {

    if (!contract) {
      setError(`AddContractModal.error.emptyForm`);
      return false;
    }

    if (!validateNotEmpty(contract.type)) {
      setError(`AddContractModal.error.type`);
      return false;
    }

    if (!contract?.company?.id) {
      setError(`AddContractModal.error.company`);
      return false;
    }

    if (!validateNotEmpty(contract.timeReference)) {
      setError(`AddContractModal.error.timeReference`);
      return false;
    }

    if (!validateNotEmpty(contract.severityReference)) {
      setError(`AddContractModal.error.severityReference`);
      return false;
    }

    if (!validateNotEmpty(contract.min) && !validateNotEmpty(contract.max)) {
      setError(`AddContractModal.error.minOrMax`);
      return false;
    }

    if (!contract.notification && !contract.alert && !contract.goal) {
      setError(`AddContractModal.error.notificationAlertGoal`);
      return false;
    }

    if (!validateNotEmpty(contract.locations)) {
      setError(`AddContractModal.error.locations`);
      return false;
    }

    return true;
  };

  const handleLocationChangeCheckbox = (e: React.ChangeEvent<any>) => {
    const locationId = +e.target.value;
    let clocations = contract?.locations?.map((l: any) => l.id) || [];
    if (e.target.checked && !clocations.includes(locationId)) {
      clocations.push(locationId);
    }

    if (!e.target.checked && clocations.includes(locationId)) {
      clocations.splice(clocations.indexOf(locationId), 1);
    }

    const newLocations = locations.data.filter((l: any) => clocations.includes(l.id));
    setContract({
      ...contract,
      locations: newLocations
    });
  };

  const handleChangeCheckbox = (e: React.ChangeEvent<any>) => {
    setContract({
      ...contract,
      [e.target.name]: e.target.checked
    });
  };

  const onSubmit = (): void => {
    if (initialContract && validate(contract)) {
      dispatch({
        ...addContract({
          requestType: "PATCH",
          body: contract,
          request: `/contract-values/${initialContract.id}`
        })
      });
      closeModal();
    } else if (validate(contract)) {
      dispatch({
        ...addContract({
          requestType: "POST",
          body: contract,
          request: `/contract-values`
        })
      });
      closeModal();
    }
  };

  const onDelete = (): void => {
    if (initialContract) {
      dispatch({
        ...deleteContract({
          requestType: "DELETE",
          request: `/contract-values/${initialContract.id}`
        })
      });
      closeModal();
      closeConfirm();
    }
  };

  const handleCompanyChange = (companyId: number) => {
    setContract({
      ...contract,
      company: companies.data.find((company: any) => company.id === companyId)
    });
  };

  const handleEnumArrayChange = (options: any[], id: number, type: string) => {

    const newOption = options.find((kpi: any) => kpi.id === id)?.name;
    setContract({
      ...contract,
      [type]: newOption
    });
  };


  const handleTypeChange = (id: number) => {
    handleEnumArrayChange(kpis, id, "type");
  };

  const handleTimeFrameChange = (id: number) => {
    handleEnumArrayChange(times, id, "timeReference");
  };

  const handleSeverityFrameChange = (id: number) => {
    handleEnumArrayChange(severities, id, "severityReference");
  };

  const getIdByNameAndType = (name: string | undefined, options: any[]) => {
    return options.find(kpi => kpi.name === name)?.id;

  };

  return <BaseModal closeModal={closeModal}
                    title={initialContract ? translate(`AddContractModal.edit`) : translate(`AddContractModal.create`)}>
    <ModalWrapper>
      <Dropdown handleChange={handleTypeChange}
                valueText={contract?.type}
                label={translate(`ContractCard.type`)}
                value={kpis.find(kpi => kpi.name === contract?.type?.toString())?.id}
                options={kpis} />
      {companies?.data && <Dropdown handleChange={handleCompanyChange}
                                    valueText={contract?.company?.name}
                                    label={translate(`ContractCard.company`)}
                                    value={companies.data.find((company: any) => company.name === contract?.company?.name)?.id}
                                    options={companies.data} />}
      <Dropdown handleChange={handleTimeFrameChange}
                valueText={contract?.timeReference}
                label={translate(`ContractCard.time`)}
                value={getIdByNameAndType(contract?.timeReference?.toString(), times)}
                options={times} />
      <Dropdown handleChange={handleSeverityFrameChange}
                valueText={contract?.severityReference}
                label={translate(`ContractCard.severity`)}
                value={getIdByNameAndType(contract?.severityReference?.toString(), severities)}
                options={severities} />
      <Input type={"number"} value={contract?.min && +contract.min} name={"min"} label={translate(`ContractCard.min`)}
             handleChange={handleChange} />
      <Input type={"number"} value={contract?.max && +contract.max} name={"max"} label={translate(`ContractCard.max`)}
             handleChange={handleChange} />
    </ModalWrapper>
    <h3>{translate(`ContractCard.sendAlertsOrNotifications`)}</h3>
    <ModalWrapper>

      <Checkbox value={1}
                name={"notification"}
                label={translate(`ContractCard.notification`)}
                checked={contract?.notification || false}
                handleChange={handleChangeCheckbox} />
      <Checkbox value={1}
                name={"alert"}
                label={translate(`ContractCard.alert`)}
                checked={contract?.alert || false}
                handleChange={handleChangeCheckbox} />

    </ModalWrapper>
    <h3>{translate(`ContractCard.markAsGoal`)}</h3>
    <ModalWrapper>

    <Checkbox value={1}
              name={"goal"}
              label={translate(`ContractCard.goal`)}
              checked={contract?.goal || false}
              handleChange={handleChangeCheckbox} />
    </ModalWrapper>
    <h3>{translate(`ContractCard.locations`)}</h3>
    <ModalWrapper>

      {locations?.data?.map((location: any, index: number) => <Checkbox key={index} value={location.id}
                                                                        name={"locations[]"}
                                                                        label={location.name}
                                                                        checked={contract?.locations?.map((l: any) => l.id)?.includes(location.id) || false}
                                                                        handleChange={handleLocationChangeCheckbox} />)}

    </ModalWrapper>


    {error && (<Message error content={translate(error)} />)}
    <ModalButtonWrapper>
      {initialContract &&
      <ModalCancelButton getConfirmation={getConfirmation}>{translate(`AddContractModal.delete`)}</ModalCancelButton>}
      <ModalCreateButton
        onSubmit={onSubmit}>{initialContract ? translate(`AddContractModal.edit`) : translate(`AddContractModal.create`)}</ModalCreateButton>
    </ModalButtonWrapper>
    {initialContract && confirm && <ConfirmModal closeModal={closeConfirm} title={``} onConfirm={onDelete} />}
  </BaseModal>;
};

export default AddLocationModal;
