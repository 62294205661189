import { Container, CssBaseline } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import axios from "../../../app/axios";
import logo from "../../../assets/images/logo-ldpi.png";
import { translate } from "../../../i18n/i18n";
import { ResetPasswordUrlParams } from "../../../interfaces/url-params.interface";
import { useStyles } from "../styles";

export const ResetPassword: FunctionComponent<{}> = () => {
  const classes = useStyles();
  const { token } = useParams<ResetPasswordUrlParams>();
  const [password, setPassword] = useState<string>("");
  const [passwordCompare, setPasswordCompare] = useState<string>("");
  const [hasSent, setHasSent] = React.useState(false);
  console.log(token);
  const onSubmit = async () => {
    setHasSent(true);
    try {
      await axios.post("/user/confirm", { token, password, c_password: passwordCompare });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img className={classes.Logo} src={logo} />
          <div style={{ width: "100%" }}>
            {hasSent ? (
              <label htmlFor="email" className={classes.Label}>
                Please go back to the login and login with your new password
              </label>
            ) : (
              <>
                <label
                  htmlFor="password"
                  className={classes.Label}
                >
                  Please set your new password
                </label>
                <input
                  placeholder={translate(
                    `authentication.register.password`
                  )}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => setPassword(e.target.value)}
                  className={classes.Input}
                  type="password"
                  name="password"
                />
                <input
                  placeholder={translate(
                    `authentication.register.passwordCompare`
                  )}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => setPasswordCompare(e.target.value)}
                  className={classes.Input}
                  type="password"
                  name="passwordCompare"
                />

                <button
                  disabled={
                    !password ||
                    !passwordCompare ||
                    password !== passwordCompare
                  }
                  onClick={onSubmit}
                  className={classes.SubmitButton}
                >
                  Reset Password
                </button>
              </>
            )}
          </div>
        </div>
        <div className={classes.ForgotPasswordWrapper}>
          <Link className={classes.ForgotPasswordLink} to="/login">
            Back to Login
          </Link>
        </div>
      </Container>
    </div>
  );
};