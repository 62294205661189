import { format, intervalToDuration } from "date-fns";
import { isNil } from "lodash";

export enum Fluctuations {
  INCREASE,
  DECREASE
}

export interface TimeDifference {
  percentage: string;
  fluctuation: Fluctuations;
};

export const calculateAvgDiff = (avgTime: number, avgTimePrevious: number): TimeDifference | null => {
  if (avgTime > 0 && avgTimePrevious > 0) {
    const percentage: number = avgTime > avgTimePrevious ?
                               Math.abs(Math.floor((avgTime - avgTimePrevious) / avgTimePrevious * 100)) :
                               Math.abs(Math.floor((avgTimePrevious - avgTime) / avgTime * 100));

    return {
      percentage: `${percentage}%`,
      fluctuation: avgTime > avgTimePrevious ? Fluctuations.INCREASE : Fluctuations.DECREASE
    };
  } else {
    return null;
  }
};

export const getAvgTime = (time: number): string => {
  if (!isNil(time)) {
    const timeObject: any = intervalToDuration({ start: 0, end: time * 1000 });
    const hours: string = timeObject.hours > 0 ? `${timeObject.hours < 10 ? `0${timeObject.hours}` : timeObject.hours}:` : "";
    const minutes: string = timeObject.minutes > 0 ? `${timeObject.minutes < 10 ? `0${timeObject.minutes}` : timeObject.minutes}:` : "00:";
    const seconds: string = timeObject.seconds > 0 ? `${timeObject.seconds < 10 ? `0${timeObject.seconds}` : timeObject.seconds}` : "00";

    return `${hours}${minutes}${seconds}`;
  } else {
    return "00:00";
  }
};