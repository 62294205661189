import React from 'react';
import { useSelector } from 'react-redux';
import { windowDataSelector } from '../../../store/window/windowDataSlice';
import { useStyles } from './styles';

interface ContentCardProps {
	title?: string;
	headerComponent?: JSX.Element;
	small?: boolean;
	bright?: boolean;
}

const ContentCard: React.FunctionComponent<ContentCardProps> = ({
	title,
	headerComponent,
	small,
	bright,
	children,
}) => {
	const { theme } = useSelector(windowDataSelector);
	const classes = useStyles(theme)();

	return (
		<div className={`${classes.Card} ${bright && classes.CardBright}`}>
			{title || headerComponent ? (
				<div
					className={`${classes.CardHeader} ${small &&
						classes.CardSmall}`}
				>
					<h3 className={classes.CardHeadline}>{title}</h3>
					<div>{headerComponent}</div>
				</div>
			) : null}

			<div>{children}</div>
		</div>
	);
};

export default ContentCard;
