import { useHistory, useLocation } from "react-router";
import { getPlatforms } from "@ionic/react";
import { SwipeableHandlers, useSwipeable } from "react-swipeable";
import { onSwipe, SwipeDirection } from "../helpers/swipeHelper";

export const useSwipe = (): SwipeableHandlers | null => {
  const history = useHistory();
  const location = useLocation();
  const handlers: SwipeableHandlers = useSwipeable({
    onSwipedLeft: () => history.push(onSwipe(location.pathname, SwipeDirection.LEFT)),
    onSwipedRight: () => history.push(onSwipe(location.pathname, SwipeDirection.RIGHT)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  });

  if (!getPlatforms().includes("desktop") || getPlatforms().includes("android") || getPlatforms().includes("ios")) {
    return handlers;
  } else {
    return null;
  }
};
