import React, { FunctionComponent } from "react";
import { useHistory, useParams } from "react-router";
import { SwipeableHandlers } from "react-swipeable";
import { LocationMenuItems } from "../../../enums/users/locationMenuItems-enum";
import { UrlParams } from "../../../interfaces/url-params.interface";
import { useSwipe } from "../../../hooks/swipeHook";
import styles from "./LocationMenu.module.scss";

interface LocationMenuProps {
  alerts: any;
}

const LocationMenu: FunctionComponent<LocationMenuProps> = ({ alerts }) => {
  const history = useHistory();
  const { id, menuItem } = useParams<UrlParams>();
  const handlers: SwipeableHandlers | null = useSwipe();

  const onMenuItemClick = (menuItem: string): void => {
    history.push(`/location/${id}/${menuItem}`);
  };

  const hasItemAlert = (item: string): boolean => {
    if (alerts && !!alerts?.total && item !== LocationMenuItems.OVERVIEW) {
      return alerts?.data.filter( (alert:any) => alert.process === item.toUpperCase()).length > 0;
    }

    return false;
  };

  return (
    <div className={styles.locationMenu} {...handlers}>
      {
        Object.values(LocationMenuItems).map((item: string, index: number) => (
          <div key={index}
               className={`${styles.menuItemContainer} ${item === menuItem ? styles.selected : ""}`}
               onClick={() => onMenuItemClick(item.toLowerCase())}>
            <span className={`${styles.menuItem} ${hasItemAlert(item) ? styles.hasAlerts : ""}`}>
              {item}
            </span>
          </div>
        ))
      }
    </div>
  );
};


export default LocationMenu;