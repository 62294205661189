import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { windowDataSelector } from "../../../store/window/windowDataSlice";
import { translateWithVariable } from "../../../i18n/i18n";
import { THEMES } from "../../../enums/theme.enum";
import threeDotsLight from "../../../assets/icons/three-dots-light.svg";
import threeDotsDark from "../../../assets/icons/three-dots-dark.svg";
import styles from "./AlertListByProcessMore.module.scss";


interface AlertListByProcessMoreProps {
  amount: number;
}

export const AlertListByProcessMore: FunctionComponent<AlertListByProcessMoreProps> = ({ amount }) => {
  const { theme } = useSelector(windowDataSelector);
  const history = useHistory();

  return (
    <button onClick={() => { history.push(`/alerts`) }} className={styles.moreButton}>
      <img alt={"threeDots"} src={`${theme === THEMES.LIGHT ? threeDotsLight : threeDotsDark}`} className={styles.icon} />
      
      <div className={styles.innerWrapper}>
        {translateWithVariable(`AlertListByProcessMore.viewMore`, { amount: amount })}
      </div>
    </button>
  );
};