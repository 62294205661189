import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface bulkErrorInterface {
  type: string,
  message: string
}

interface initialStateInterface {
  data: any,
  location: any,
  isLoading: boolean,
  error: string,
}

const initialState: initialStateInterface = {
  data: null,
  location: null,
  isLoading: false,
  error: ""
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    fetchLocations: (state, action: any) => {
      if (action.state === "finished" && !!action.response) {
        state.data = action.response;
        state.isLoading = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchLocation: (state, action: any) => {
      if (action.state === "finished" && !!action.response) {
        state.location = action.response;
        state.isLoading = false;
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    setLocation: (state, action: any) => {
      state.location = action.payload;
    },
    addLocation: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        state.data = [...state.data, action.response];
        state.error = "";
        state.isLoading = false;
      }
      if (action.state === "error") {
        state.isLoading = false;
        state.error = action.response?.error;
      }
    },
    editLocation: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished") {
        const updatedLocationIndex: number = state.data.findIndex((el: any) => el.id === action.response.id)
        state.data[updatedLocationIndex] = action.response;
        state.isLoading = false;

      }
    },
    deleteLocation: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && action.response) {
        state.isLoading = false;
        state.error = "";
      }
    },
    finishLoading(state) {
      state.isLoading = false;
    },
    clearLocation: (state) => {
      state.location = null;
      state.error = "";
      state.isLoading = false;
    },
    clearLocationStore: () => {
      return initialState;
    }
  }
});

export const {
  fetchLocations,
  fetchLocation,
  editLocation,
  addLocation,
  clearLocation,
  deleteLocation,
  finishLoading,
  setLocation,
  clearLocationStore
} = locationSlice.actions;
export const locationSelector = (state: RootState) => state.location;
export default locationSlice.reducer;