export enum companyFunctions {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE"
}

export enum deleteTypes {
  DELETE = "DELETE",
  REMOVE = "REMOVE"
}

export type CompanyType = {
  id?: number;
  apiConstant?: string;
  locations?: number[];
  name?: string;
  street?: string;
  streetNo?: string;
  zip?: string;
  city?: string;
  country?: string;
  taxId?: string;
}