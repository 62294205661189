import axios, {AxiosRequestConfig} from "axios";

const fetchClient = () => {
    const defaultOptions: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const jwt = localStorage.getItem('jwt');
        config.headers.Authorization =  jwt ? `Bearer ${jwt}` : '';
        return config;
    });

    return instance;
};

export default fetchClient();
