import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { isNil } from "lodash";
import { scale } from "chroma-js";
import { SwipeableHandlers } from "react-swipeable";
import { Box, CircularProgress } from "@material-ui/core";
import http from "../../../app/axios";
import { useSwipe } from "../../../hooks/swipeHook";
import { Kpis } from "../../../enums/kpi.enum";
import { UrlParams } from "../../../interfaces/url-params.interface";
import { calculateAvgDiff, Fluctuations, getAvgTime, TimeDifference } from "../../../helpers/avgTimesHelper";
import styles from "./OverviewTabContainer.module.scss";

interface KpiValues {
  goalAmount: number | null;
  mainKpiAmount: number;
  secondaryKpiAmount: number;
  firstAvgTime: {
    avg: number;
    previousAvg: number;
  };
  secondAvgTime: {
    avg: number;
    previousAvg: number;
  };
  thirdAvgTime: {
    avg: number;
    previousAvg: number;
  };
}

interface OverviewTabContainerProps {
  type: Kpis;
}

const OverviewTabContainer: FunctionComponent<OverviewTabContainerProps> = ({ type }) => {
  const handlers: SwipeableHandlers | null = useSwipe();
  const { id: locationId } = useParams<UrlParams>();
  const [kpiValues, setKpiValues] = useState<KpiValues>();
  const firstAvgTimeDiff: TimeDifference | null = calculateAvgDiff(Number(kpiValues?.firstAvgTime.avg), Number(kpiValues?.firstAvgTime.previousAvg));
  const secondAvgTimeDiff: TimeDifference | null = calculateAvgDiff(Number(kpiValues?.secondAvgTime.avg), Number(kpiValues?.secondAvgTime.previousAvg));
  const thirdAvgTimeDiff: TimeDifference | null = calculateAvgDiff(Number(kpiValues?.thirdAvgTime.avg), Number(kpiValues?.thirdAvgTime.previousAvg));

  useEffect(() => {
    const getKpiValues = async () => {
      const contractValue = await http.get(`/contract-values?type=${type}&timeReference=DAY`).then((res: any) => res.data);
      const kpiOverview = await http.get(`kpi/kpiOverview?location=${locationId}&type=${type}`).then((res: any) => res.data);

      setKpiValues({
        goalAmount: contractValue !== "" ? Number(contractValue.min) : null,
        mainKpiAmount: Number(kpiOverview.mainKpiValue),
        secondaryKpiAmount: Number(kpiOverview.secondaryKpiValue),
        firstAvgTime: {
          avg: kpiOverview.firstAvgTime.avg,
          previousAvg: kpiOverview.firstAvgTime.previousAvg
        },
        secondAvgTime: {
          avg: kpiOverview.secondAvgTime.avg,
          previousAvg: kpiOverview.secondAvgTime.previousAvg
        },
        thirdAvgTime: {
          avg: kpiOverview.thirdAvgTime.avg,
          previousAvg: kpiOverview.thirdAvgTime.previousAvg
        }
      });
    };

    getKpiValues();
  }, [locationId]);

  const getBarColor = (value: string): string => {
    const getColor: any = scale(["#507ee3", "#2fc07d"]).domain([0, 100]);

    return getColor(value).hex();
  };

  const getBarStyles = (kpiValues: KpiValues): CSSProperties => {
    if (!isNil(kpiValues.goalAmount)) {
      const percentage: string = (kpiValues.mainKpiAmount / kpiValues.goalAmount * 100) <= 100 ?
        (kpiValues.mainKpiAmount / kpiValues.goalAmount * 100).toFixed() : "100";

      return {
        "--bar-width": `${percentage}%`,
        "--bar-color": getBarColor((kpiValues.mainKpiAmount / kpiValues.goalAmount * 100).toFixed())
      } as CSSProperties;
    } else {
      return {} as CSSProperties;
    }
  };

  return (
    <div className={styles.overviewTabContainer} {...handlers}>
      <div className={styles.header}>
        {type === Kpis.INBOUND_AMOUNT ? "Inbound Amount" : "Outbound Amount"}
      </div>
      {
        isNil(kpiValues) ?
          <Box className={styles.loader}>
            <CircularProgress />
          </Box> :
          <>
            {
              !isNil(kpiValues.goalAmount) &&
              <div className={styles.goal} style={getBarStyles(kpiValues)}>
                <div className={styles.infoContainer}>
                  <div className={styles.info}>
                    <div className={styles.goalCompletion}>{kpiValues.mainKpiAmount}/{kpiValues.goalAmount}</div>
                    <div className={styles.secondaryKpi}>
                      {kpiValues.secondaryKpiAmount} {type === Kpis.INBOUND_AMOUNT ? "Pallets" : "Parcels"}
                    </div>
                  </div>

                  <div className={styles.percentage}>
                    {(kpiValues.mainKpiAmount / kpiValues.goalAmount * 100).toFixed()}%
                  </div>
                </div>


                <div className={styles.bar} />
              </div>
            }

            <div className={styles.avgTimesContainer}>
              <div className={styles.avgTime}>
                <div className={styles.title}>
                  {type === Kpis.INBOUND_AMOUNT ? "Inbound time" : "Outbound time"}
                </div>

                <div className={styles.timeContainer}>
                  <div className={styles.time}>{getAvgTime(kpiValues.firstAvgTime.avg)}</div>

                  {
                    !isNil(firstAvgTimeDiff) &&
                    <div className={styles.percentage}>
                      <div className={`${styles.arrow} ${firstAvgTimeDiff.fluctuation === Fluctuations.DECREASE ? styles.arrowUp : styles.arrowDown}`} />
                      <span>
                        {firstAvgTimeDiff.percentage}
                      </span>
                    </div>
                  }
                </div>
              </div>

              <div className={styles.avgTime}>
                <div className={styles.title}>
                  {type === Kpis.INBOUND_AMOUNT ? "Putaway time" : "Pack time"}
                </div>

                <div className={styles.timeContainer}>
                  <div className={styles.time}>{getAvgTime(kpiValues.secondAvgTime.avg)}</div>

                  {
                    !isNil(secondAvgTimeDiff) &&
                    <div className={styles.percentage}>
                      <div className={`${styles.arrow} ${secondAvgTimeDiff.fluctuation === Fluctuations.DECREASE ? styles.arrowUp : styles.arrowDown}`} />
                      <span>
                        {secondAvgTimeDiff.percentage}
                      </span>
                    </div>
                  }
                </div>
              </div>

              <div className={styles.avgTime}>
                <div className={styles.title}>
                  {type === Kpis.INBOUND_AMOUNT ? "Unload time" : "Pick time"}
                </div>

                <div className={styles.timeContainer}>
                  <div className={styles.time}>{getAvgTime(kpiValues.thirdAvgTime.avg)}</div>

                  {
                    !isNil(thirdAvgTimeDiff) &&
                    <div className={styles.percentage}>
                      <div className={`${styles.arrow} ${thirdAvgTimeDiff.fluctuation === Fluctuations.DECREASE ? styles.arrowUp : styles.arrowDown}`} />
                      <span>
                        {thirdAvgTimeDiff.percentage}
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </>
      }
    </div>
  );
};

export default OverviewTabContainer;