import React from 'react';
import { translate } from '../../../i18n/i18n';
import styles from '../../../pages/Profile/Profile.module.scss';

const ProfilePasswordTab: React.FunctionComponent<{
	handleChange: Function;
	values: {
		oldPassword: string;
		newPassword: string;
		newPasswordConfirm: string;
	};
	passwordError: null | 1 | 2 | 3;
}> = ({ handleChange, values, passwordError }) => {
	return (
		<div className={styles.passwordTab}>
			<h3 className={styles.tabHeadline}>
				{translate('editProfile.changePasswordTab')}
			</h3>
			<div className={styles.inputFieldWrapper}>
				<label htmlFor="oldPassword" className={styles.inputLabel}>
					{translate('editProfile.oldPassword')}
				</label>
				<input
					onChange={e => handleChange('oldPassword', e)}
					name="oldPassword"
					value={values.oldPassword}
					type="password"
					className={`${styles.inputField} ${
						passwordError === 1 ? styles.inputFieldError : ''
					}`}
				/>
				<label htmlFor="newPassword" className={styles.inputLabel}>
					{translate('editProfile.newPassword')}
				</label>
				<input
					onChange={e => handleChange('newPassword', e)}
					name="newPassword"
					value={values.newPassword}
					type="password"
					className={`${styles.inputField} ${
						passwordError === 2 ? styles.inputFieldError : ''
					}`}
				/>
				<label
					htmlFor="newPasswordConfirm"
					className={styles.inputLabel}
				>
					{translate('editProfile.newPasswordConfirm')}
				</label>
				<input
					onChange={e => handleChange('newPasswordConfirm', e)}
					name="newPasswordConfirm"
					type="password"
					value={values.newPasswordConfirm}
					className={`${styles.inputField} ${
						passwordError === 2 ? styles.inputFieldError : ''
					}`}
				/>
			</div>
		</div>
	);
};

export default ProfilePasswordTab;
