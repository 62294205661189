export const color = {
  background: "#fbfcfe",
  fontBold: "#343c6a",
  fontLight: "#7199d7",
  menuSelected: "#eef5ff",
  iconSelected: "#386aff",
  lightGreen: "#e8fcfa",
  green: "#16dacc",
  lightIconGreen: "#dcfaf8",
  iconGreen: "#16dacc",
  lightBlue: "#e7ecff",
  blue: "#386aff",
  lineBlue: "#3366ff",
  lineRed: "#fe83a2",
  lineGreen: "#16dacc",
  lightYellow: "#fff6d9",
  yellow: "#ffba38",
  borderGrey: "#e7f0f7",
  dark: "#17181D",
  card: "#2a2e31",
  primary: "#2d9ac2", // blue
  lightPrimary: "#2d9ac2",
  white: "#ffffff",
  darkBackground: "#363740",
  textGrey: "#9C9C9C",
  textDark: "#252733",
  grey: "#9B9EA8",
  bgGrey: "#22252D",
  chartBlue: "#6CB9FF",
  link: "#1485EE",
  red: "#fb423d",
  published: "#D0E9FF",
  not_published: "#FFDC5F",
  errorRed: "#EB5757",
  lightGray: "#9FA2B4",
  darkGray: "#4B506D",
  success: "#27AE60"
};

export const textSizes = {
  headline: 24,
  title: 26,
  h3: 18,
  bodyLarge: 18,
  bodyNormal: 16,
  bodySmall: 14,
  notice: 12
};

export const lineHeightSizes = {
  headline: "32px",
  searchinfo: "38px"
};

export const borderRadius = {
  large: "16px",
  medium: "8px",
  small: "6px"
};

export const margins = {
  buttons: "12px"
};

export const SettingsH3 = {
  fontSize: textSizes.bodyLarge,
  fontWeight: 600,
  color: "#000",
  marginBottom: 12
};

export const SettingsP = {
  fontSize: textSizes.bodyNormal,
  fontWeight: 400,
  color: color.textGrey,
  marginBottom: 30
};

export const SettingsDivider = {
  marginTop: 40
};
