import React, { FunctionComponent } from "react";
import closeXIcon from "../../../assets/icons/close-x.svg";
import { AlertListByProcess } from "../AlertListByProcess/AlertListByProcess";
import styles from "./AlertWrapper.module.scss";

export const AlertWrapper: FunctionComponent<{ showAlerts: boolean, setShowAlerts: (param: boolean) => void, alertsData: any, show: boolean }> = ({
                                                                                                                                                    show,
                                                                                                                                                    children,
                                                                                                                                                    alertsData,
                                                                                                                                                    showAlerts,
                                                                                                                                                    setShowAlerts
                                                                                                                                                  }) => {
  return <div className={showAlerts && show && alertsData?.total > 0 ? styles.wrapper : styles.noWrapper }>
    {children}
    {alertsData && !!alertsData?.total && show && showAlerts && <div className={styles.onlyDesktop}>
      <button className={styles.closeButton} onClick={() => {
        setShowAlerts(false);
      }}>
        <img src={closeXIcon} />
      </button>
      <AlertListByProcess alertsData={alertsData} />
    </div>}
    </div>;
};