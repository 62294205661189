export enum locationFunctions {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE"
}

export enum deleteTypes {
  DELETE = "DELETE",
  REMOVE = "REMOVE"
}

export type LocationType = {
  id?: number;
  apiConstant?: string;
  company?: any;
  name?: string;
  street?: string;
  streetNo?: string;
  zip?: string;
  city?: string;
  country?: string;
  taxId?: string;
  shiftStart?: string;
  shiftEnd?: string;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  users?: any[];
  contractValues?: any[];
}