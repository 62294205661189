import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "semantic-ui-react";
import { isEmpty, isNil } from "lodash";
import { LocationType } from "../../../enums/locations-enums";
import { translate } from "../../../i18n/i18n";
import { companySelector, fetchCompanies } from "../../../store/companies/companySlice";
import { addLocation, deleteLocation, locationSelector } from "../../../store/locations/locationSlice";
import { Checkbox } from "../../shared/Checkbox/Checkbox";
import { Dropdown } from "../../shared/Dropdown/Dropdown";
import { Input } from "../../shared/Input/Input";
import { ModalButtonWrapper } from "../../shared/ModalButtonWrapper/ModalButtonWrapper";
import { ModalCancelButton } from "../../shared/ModalCancelButton/ModalCancelButton";
import { ModalCreateButton } from "../../shared/ModalConfirmButton/ModalCreateButton";
import { ModalWrapper } from "../../shared/ModalWrapper/ModalWrapper";
import BaseModal, { validateNotEmpty } from "../BaseModal/BaseModal";
import ConfirmModal, { useConfirmModal } from "../ConfirmModal/ConfirmModal";
import styles from "./AddLocationModal.module.scss";

interface AddLocationModalProps {
  initialLocation?: LocationType;
  closeModal: () => void
}

export interface AddLocationInterface {
  id?: number;
  apiConstant?: string;
  name?: string;
  street?: string;
  streetNo?: string;
  zip?: string;
  city?: string;
  country?: string;
  taxId?: string;
  shiftStart?: string;
  shiftEnd?: string;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  company?: number;
}

const AddLocationModal: FunctionComponent<AddLocationModalProps> = ({ initialLocation, closeModal }) => {

  const { confirm, closeConfirm, getConfirmation } = useConfirmModal();
  const [location, setLocation] = useState<LocationType | undefined>(initialLocation || {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  });
  const { error: apiError } = useSelector(locationSelector);
  const [error, setError] = useState<string | null>();
  const { data: companies } = useSelector(companySelector);
  const [canDelete, setCanDelete] = useState<boolean>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(companies)) {
      dispatch({
        ...fetchCompanies({
          requestType: "GET",
          request: "/company"
        })
      });
    }

    if (!isNil(initialLocation)) {
      setCanDelete(isEmpty(initialLocation.contractValues) && isEmpty(initialLocation.users));
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<any>) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value
    });
  };

  const validate = (location: any) => {

    if (!location) {
      setError(`AddLocationModal.error.emptyForm`);
      return false;
    }

    if (!validateNotEmpty(location.name)) {
      setError(`AddLocationModal.error.name`);
      return false;
    }

    if (!validateNotEmpty(location.apiConstant)) {
      setError(`AddLocationModal.error.apiConstant`);
      return false;
    }

    if (!location?.company?.id) {
      setError(`AddLocationModal.error.company`);
      return false;
    }

    if (!validateNotEmpty(location.street)) {
      setError(`AddLocationModal.error.street`);
      return false;
    }

    if (!validateNotEmpty(location.streetNo)) {
      setError(`AddLocationModal.error.streetNo`);
      return false;
    }

    if (!validateNotEmpty(location.zip)) {
      setError(`AddLocationModal.error.zip`);
      return false;
    }

    if (!validateNotEmpty(location.city)) {
      setError(`AddLocationModal.error.city`);
      return false;
    }

    if (!validateNotEmpty(location.country)) {
      setError(`AddLocationModal.error.country`);
      return false;
    }

    if (!validateNotEmpty(location.shiftStart)) {
      setError(`AddLocationModal.error.shiftStart`);
      return false;
    }

    if (!validateNotEmpty(location.shiftEnd)) {
      setError(`AddLocationModal.error.shiftEnd`);
      return false;
    }

    if (!location.monday &&
      !location.tuesday &&
      !location.wednesday &&
      !location.thursday &&
      !location.friday &&
      !location.saturday &&
      !location.sunday) {
      setError(`AddLocationModal.error.weekday`);
      return false;
    }

    return true;
  };

  const handleChangeCheckbox = (e: React.ChangeEvent<any>) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.checked
    });
  };

  const onSubmit = (): void => {
    if (initialLocation && validate(location)) {
      dispatch({
        ...addLocation({
          requestType: "PATCH",
          body: location,
          request: `/location/${initialLocation.id}`
        })
      });
      closeModal();
    } else if (validate(location)) {
      dispatch({
        ...addLocation({
          requestType: "POST",
          body: location,
          request: `/location`
        })
      });
      closeModal();
    }
  };

  const onDelete = (): void => {
    if (initialLocation) {
      dispatch({
        ...deleteLocation({
          requestType: "DELETE",
          request: `/location/${initialLocation.id}`
        })
      });
      closeModal();
      closeConfirm();
    }
  };

  const handleCompanyChange = (companyId: number) => {
    setLocation({
      ...location,
      company: companies.data.find((company: any) => company.id === companyId)
    });
  };

  return <BaseModal closeModal={closeModal}
                    title={initialLocation ? translate(`AddLocationModal.edit`) : translate(`AddLocationModal.create`)}>
    <ModalWrapper>
      <Input value={location?.name} name={"name"} label={translate(`LocationTableCard.name`)}
             handleChange={handleChange} />
      <Input value={location?.apiConstant} name={"apiConstant"} label={translate(`LocationTableCard.apiConstant`)}
             handleChange={handleChange} />
      <Dropdown handleChange={handleCompanyChange}
                valueText={location?.company?.name && `${location?.company?.name} selected`}
                label={translate(`LocationTableCard.company`)} value={location?.company?.id}
                options={companies?.data} />
      <Input value={location?.street} name={"street"} label={translate(`LocationTableCard.street`)}
             handleChange={handleChange} />
      <Input value={location?.streetNo} name={"streetNo"} label={translate(`LocationTableCard.streetNo`)}
             handleChange={handleChange} />
      <Input value={location?.zip} name={"zip"} label={translate(`LocationTableCard.zip`)}
             handleChange={handleChange} />
      <Input value={location?.city} name={"city"} label={translate(`LocationTableCard.city`)}
             handleChange={handleChange} />
      <Input value={location?.country} name={"country"} label={translate(`LocationTableCard.country`)}
             handleChange={handleChange} />
      <Input value={location?.shiftStart} name={"shiftStart"} label={translate(`LocationTableCard.shiftStart`)}
             handleChange={handleChange} />
      <Input value={location?.shiftEnd} name={"shiftEnd"} label={translate(`LocationTableCard.shiftEnd`)}
             handleChange={handleChange} />
    </ModalWrapper>
    <div className={styles.weekdays}>
      <Checkbox value={1}
                name={"monday"}
                label={translate(`monday`)}
                checked={location?.monday || false}
                handleChange={handleChangeCheckbox} />

      <Checkbox value={1}
                name={"tuesday"}
                label={translate(`tuesday`)}
                checked={location?.tuesday || false}
                handleChange={handleChangeCheckbox} />

      <Checkbox value={1}
                name={"wednesday"}
                label={translate(`wednesday`)}
                checked={location?.wednesday || false}
                handleChange={handleChangeCheckbox} />

      <Checkbox value={1}
                name={"thursday"}
                label={translate(`thursday`)}
                checked={location?.thursday || false}
                handleChange={handleChangeCheckbox} />

      <Checkbox value={1}
                name={"friday"}
                label={translate(`friday`)}
                checked={location?.friday || false}
                handleChange={handleChangeCheckbox} />

      <Checkbox value={1}
                name={"saturday"}
                label={translate(`saturday`)}
                checked={location?.saturday || false}
                handleChange={handleChangeCheckbox} />

      <Checkbox value={1}
                name={"sunday"}
                label={translate(`sunday`)}
                checked={location?.sunday || false}
                handleChange={handleChangeCheckbox} />
    </div>


    {error && (<Message error content={translate(error)} />)}
    <ModalButtonWrapper>
      {
        canDelete &&
        <ModalCancelButton getConfirmation={getConfirmation}>{translate(`AddLocationModal.delete`)}</ModalCancelButton>
      }

      <ModalCreateButton onSubmit={onSubmit}>
        {initialLocation ? translate(`AddLocationModal.edit`) : translate(`AddLocationModal.create`)}
      </ModalCreateButton>
    </ModalButtonWrapper>

    {initialLocation && confirm && <ConfirmModal closeModal={closeConfirm} title={``} onConfirm={onDelete} />}
  </BaseModal>;
};

export default AddLocationModal;
