import React, { FunctionComponent, useState } from "react";
import { isEqual, isNil } from "lodash";
import { COMPARISON_OPTIONS, IComparisonOption } from "../../../libs/comparison.constants";
import styles from "./ComparisonDropdown.module.scss";

interface ComparisonDropdownProps {
  selectedComparisonOption: (selectedComparison: IComparisonOption) => void;
  comparisonOption: IComparisonOption;
}

const ComparisonDropdown: FunctionComponent<ComparisonDropdownProps> = ({ comparisonOption, selectedComparisonOption }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onComparisonOptionSelected = (comparisonOption: IComparisonOption): void => {
    selectedComparisonOption(comparisonOption);
    setIsOpen((prevState: boolean) => !prevState);
  };

  return (
    <>
      <div className={`${styles.dropdownContainer} ${isOpen ? styles.opened : ""}`}>
        <div className={`${styles.selectedComparisonOption} ${comparisonOption.value === "PLEASE_SELECT" ? styles.default : ""}`}
             onClick={() => setIsOpen((prevState: boolean) => !prevState)}>
          {comparisonOption.name}
        </div>

        <div className={styles.comparisonOptionsContainer}>
          <div className={styles.comparisonOptionsList}>
            {
              COMPARISON_OPTIONS.map((comparisonOptionItem: IComparisonOption, index: number) => (
                <div key={index}
                     className={`${styles.comparisonOption} ${isEqual(comparisonOption, comparisonOptionItem) ? styles.selected : ""}`}
                     onClick={() => onComparisonOptionSelected(comparisonOptionItem)}>
                  {comparisonOptionItem.name}
                </div>
              ))
            }
          </div>
        </div>
      </div>

      {isOpen && <div className={styles.overlay} onClick={() => setIsOpen(false)} />}
    </>
  );
};

export default ComparisonDropdown;
