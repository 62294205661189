import { createSlice } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { RootState } from '../../app/store';
import { UserType } from '../../enums/users/users-enums';
import { UsersErrors } from '../../errors-enums';
import { translate, translateWithVariable } from '../../i18n/i18n';

export interface bulkErrorInterface {
	type: string;
	message: string;
}

interface initialUsersStateInterface {
	data: any;
	myUser: any;
	selectedUser: {
		id: number | null;
		avatar: string;
		firstName: string;
		lastName: string;
		groups: any;
		email: string;
		role: string;
	};
	selectedMember: {
		id?: number | undefined;
		avatar: string;
		firstName: string;
		lastName: string;
		role: number | undefined;
		email: string;
	};
	isEditing: boolean;
	isLoading: boolean;
	error: string;
	bulkError: bulkErrorInterface | null;
}

const initialState: initialUsersStateInterface = {
	data: null,
	selectedUser: {
		id: null,
		avatar: '',
		firstName: '',
		lastName: '',
		email: '',
		groups: [] as any,
		role: '',
	},
	selectedMember: {
		avatar: '',
		firstName: '',
		lastName: '',
		email: '',
		role: 1,
	},
	myUser: {},
	isEditing: false,
	isLoading: false,
	error: '',
	bulkError: {
		type: '',
		message: '',
	},
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		fetchUsers: (state, action: any) => {
			if (action.state === 'finished' && !!action.response) {
				state.data = action.response;
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			} else {
				state.isLoading = false;
			}
		},
		fetchSelf: (state, action: any) => {
			state.isLoading = true;
			if (action.state === 'finished') {
				state.myUser = action.response;
				state.isLoading = false;
			}
		},
		fetchUser: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}

			if (action.state === 'finished') {
				state.isLoading = false;
				state.error = '';
			}

			if (action.state === 'finished' && !!action.response.success[0]) {
				state.selectedUser = { ...action.response.success[0] };
				var groups = state.selectedUser.groups.map(function(
					group: any,
				) {
					return group.id;
				});
				state.selectedUser.groups = groups;
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
		resetError: state => {
			state.error = '';
		},
		fetchMember: (state, action: any) => {
			state.isLoading = true;
			if (action.state === 'finished' && !!action.response.success[0]) {
				state.selectedMember = { ...action.response.success[0] };
				state.selectedMember.role = {
					...action.response.success[0],
				}.roles[0].id;
				state.isLoading = false;
				state.error = '';
			} else if (action.state === 'error') {
				state.isLoading = false;
				state.error = 'Request error';
			}
		},
		setSelectedUser: (state, action: any) => {
			if (isNil(action.payload)) {
				state.selectedUser = initialState.selectedUser;
				state.isEditing = false;
				state.error = '';
			} else {
				state.selectedUser = {
					id: action.payload.id,
					avatar: '',
					firstName: action.payload.firstName,
					lastName: action.payload.lastName,
					email: action.payload.email,
					groups: null,
					role: action.payload.role,
				};
			}
		},
		addUser: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
				state.isEditing = true;
			}
			if (action.state === 'finished') {
				state.error = '';
				state.isLoading = false;
				state.isEditing = false;
			}
			if (action.state === 'error') {
				state.isLoading = false;
				if (
					action?.response?.statusCode === 500 &&
					action?.response?.message === 'User Duplicate'
				) {
					state.error = translate(
						`users.users.create.error.alreadyTaken`,
					);
				} else if (
					action.response?.error ===
					'The email has already been taken in another institution.'
				) {
					state.error = translate(
						`users.users.create.error.alreadyTakenOtherInstitution`,
					);
				} else {
					state.error = action.response?.message;
				}
			}
		},
		editUser: (state, action: any) => {
			if (action.state === 'finished') {
				const updatedUserIndex: number = state.data.findIndex(
					(el: any) => el.id === action.response.id,
				);
				state.data[updatedUserIndex] = action.response;
				state.isLoading = false;
			}
		},
		editMyUser: (state, action: any) => {
			if (action.state === 'send') {
				state.error = '';
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				state.myUser = action.response;
				state.isLoading = false;
			}
			if (action.state === 'error') {
				if (action.response.message === 'User Duplicate') {
					state.error = translate('editProfile.alreadyExists');
				}
				state.isLoading = false;
			}
		},
		uploadImage: (state, action: any) => {
			state.selectedUser.avatar = action.payload;
		},
		deleteUser: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}

			if (action.state === 'finished') {
				state.isLoading = false;
				state.error = '';
			}
		},
		bulkEdit: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}

			if (action.state === 'finished' && action.response.success) {
				state.isLoading = false;
				state.error = '';
			}
		},
		bulkCreate: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}
			if (action.state === 'finished') {
				const { success } = action.response;

				if (success && success.already_in_db.length > 0) {
					state.bulkError = {
						type: UsersErrors.DUPLICATES,
						message: translateWithVariable(
							`users.users.bulkCreate.step2.error.duplicates`,
							{
								count: success.already_in_db.length,
								imported: success.imported.length,
							},
						),
					};
				} else {
					state.bulkError = null;
				}
			}

			if (action.state === 'error') {
				state.bulkError = {
					type: UsersErrors.MALFORMATTED,
					message: translate(
						`users.users.bulkCreate.step2.error.malformatted`,
					),
				};
			}
		},
		finishLoading(state) {
			state.isLoading = false;
		},
		resetBulk(state) {
			state.bulkError = null;
			state.isLoading = false;
		},
		clearUser: state => {
			state.selectedUser = initialState.selectedUser;
			state.selectedMember = initialState.selectedMember;
			state.error = '';
			state.isLoading = false;
		},
		clearUserStore: () => {
			return initialState;
		},
	},
});

export const {
	fetchSelf,
	fetchUsers,
	fetchUser,
	fetchMember,
	editUser,
	addUser,
	bulkCreate,
	resetBulk,
	clearUser,
	uploadImage,
	deleteUser,
	finishLoading,
	bulkEdit,
	setSelectedUser,
	editMyUser,
	resetError,
	clearUserStore,
} = userSlice.actions;
export const userSelector = (state: RootState) => state.user;
export const userLoadingSelector = (state: RootState) => state.user.isLoading;
export const bulkResponseSelector = (state: RootState) => [
	state.user.bulkError,
	state.user.isLoading,
];
export default userSlice.reducer;
