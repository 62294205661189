import React, { FunctionComponent, ReactNode } from "react";
import { isEmpty } from "lodash";
import { SwipeableHandlers } from "react-swipeable";
import { Box, CircularProgress } from "@material-ui/core";
import { useSwipe } from "../../../../hooks/swipeHook";
import { StorageType } from "../../../../enums/kpi.enum";
import { getBarChartStyle } from "../../../../helpers/locationHelper";
import { IStorageBarChart } from "../../../../interfaces/chart.interface";
import styles from "./BarChart.module.scss";

interface BarChartProps {
  chartData: IStorageBarChart[];
  type: StorageType;
}

const BarChart: FunctionComponent<BarChartProps> = ({ chartData, type }) => {
  const handlers: SwipeableHandlers | null = useSwipe();

  const getChart = (): ReactNode => {
    return chartData.map((storageValue: IStorageBarChart, index: number) => {
      const barValue: number = Number(storageValue.ofValueTotal) === Number(storageValue.valueTotal) ? 100 :
        Math.floor((Number(storageValue.ofValueTotal) - (Number(storageValue.valueTotal))) / Number(storageValue.ofValueTotal) * 100);

      return (
        <div className={styles.barContainer} key={index}>
          <div className={styles.barName}>{type === StorageType.STORAGE_ABC ? "storage" : "zone"} {storageValue.category}</div>

          <div className={styles.bar} style={getBarChartStyle(barValue)}>
            <div className={styles.color} />
            <div className={styles.percentage}>{barValue}%</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.barChartContainer} {...handlers}>
      <div className={styles.header}>Storage by {type === StorageType.STORAGE_ABC ? "ABC" : "Zone"}</div>
      {
        isEmpty(chartData) ?
          <Box className={styles.loader}>
            <CircularProgress />
          </Box> :
          getChart()
      }
    </div>
  );
};

export default BarChart;