import { isNil } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { contractFunctions, ContractType } from "../../../enums/contracts-enums";
import { usePagination } from "../../../helpers/usePagination";
import { useTableSearch } from "../../../helpers/useTableSearch/useTableSearch";
import { translate } from "../../../i18n/i18n";
import { initialPaginationState } from "../../../interfaces/pagination-interface";
import { contractSelector, fetchContracts } from "../../../store/contracts/contractSlice";
import { setSelectedUser } from "../../../store/users/userSlice";
import { AddContractModal } from "../../modals/AddContractModal/AddContractModal";

import ContentCard from "../../shared/ContentCard";
import { ContractCard } from "../../shared/ContractCard/ContractCard";
import { CreateNewButton } from "../../shared/CreateNewButton/CreateNewButton";
import { InputForSearch } from "../../shared/InputForSearch/InputForSearch";
import Loader from "../../shared/Loader";
import { PageWrapper } from "../../shared/PageWrapper/PageWrapper";
import { Pagination } from "../../shared/Pagination/Pagination";
import { PerPageDropdown } from "../../shared/PerPageDropdown/PerPageDropdown";
import styles from "./ContractOverviewTab.module.scss";


interface ContractOverviewTabProps {
}

const ContractOverviewTab: FunctionComponent<ContractOverviewTabProps> = () => {
  const dispatch = useDispatch();
  const [contract, setContract] = useState<ContractType | null>(null);
  const [showModal, setShowModal] = useState<contractFunctions | null>(null);
  const { handleSearchChange, search } = useTableSearch();
  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } = usePagination();
  const { isLoading, error, data: contractDataPaginated } = useSelector(contractSelector);

  useEffect(() => {
    if (!isLoading) {
      dispatch({
        ...fetchContracts({
          requestType: "GET",
          request: `/contract-values/paginated?page=${page}&limit=${rowsPerPage}${search !== "" ? `&search=${search}` : ""}`
        })
      });
    }
  }, [isLoading, search, page, rowsPerPage]);

  const onEditButtonClick = (item: ContractType) => {
    setContract(item);
    setShowModal(contractFunctions.EDIT);
  };

  const closeModal = (): void => {
    setShowModal(null);
    dispatch(setSelectedUser(null));
  };

  const create = () => {
    setShowModal(contractFunctions.CREATE);
  };

  return (
    <PageWrapper>
      {
        isNil(contractDataPaginated) ?
          <Loader /> :
          <>{isLoading && <Loader />}
            <Grid>

              <Grid.Row>
                <Grid.Column width={16}>
                  <div className={styles.headerWrapper}>
                    <h3 className={styles.header}>{translate(`ContractOverviewTab.pageName`)}</h3>
                    <InputForSearch value={search} handleChange={handleSearchChange}
                                    placeholder={translate(`ContractOverviewTab.filterBy`)} />
                    <PerPageDropdown value={rowsPerPage} options={initialPaginationState.optionsRowsPerPage}
                                     handleChange={handleChangeRowsPerPage} />
                    <CreateNewButton handleClick={create}>{translate(`ContractOverviewTab.create`)}</CreateNewButton>
                  </div>
                  <ContentCard>
                    <ContractCard header={true} />
                    {!!contractDataPaginated.data.length && contractDataPaginated.data.map((contract: any, index: number) => {
                      return <ContractCard index={index} contract={contract} handleEdit={onEditButtonClick} />;
                    })}
                    <Pagination page={contractDataPaginated.page} pages={contractDataPaginated.pages}
                                handleChange={handleChangePage} />
                  </ContentCard>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
      }
      {showModal === contractFunctions.CREATE && <AddContractModal closeModal={closeModal} />}
      {showModal === contractFunctions.EDIT && contract &&
      <AddContractModal initialContract={contract} closeModal={closeModal} />}
    </PageWrapper>
  );
};

export default ContractOverviewTab;