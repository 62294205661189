import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { userFunctions, UserType } from "../../../enums/users/users-enums";
import { usePagination } from "../../../helpers/usePagination";
import { useTableSearch } from "../../../helpers/useTableSearch/useTableSearch";
import { translate } from "../../../i18n/i18n";
import { initialPaginationState } from "../../../interfaces/pagination-interface";
import { fetchUsers, setSelectedUser, userSelector } from "../../../store/users/userSlice";
import AddUserModal from "../../modals/AddUserModal/AddUserModal";
import ContentCard from "../../shared/ContentCard";
import { CreateNewButton } from "../../shared/CreateNewButton/CreateNewButton";
import { InputForSearch } from "../../shared/InputForSearch/InputForSearch";
import Loader from "../../shared/Loader";
import { PageWrapper } from "../../shared/PageWrapper/PageWrapper";
import { Pagination } from "../../shared/Pagination/Pagination";
import { PerPageDropdown } from "../../shared/PerPageDropdown/PerPageDropdown";
import { UserCard } from "../../shared/UserCard/UserCard";
import styles from "./UserOverviewTab.module.scss";

const UserOverviewTab: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const [shownModal, setShownModal] = useState<userFunctions | null>(null);
  const [user, setUser] = useState<UserType | null>(null);
  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } = usePagination();
  const { handleSearchChange, search, setSearch, showSearch, setShowSearch, CurrentlySearching } = useTableSearch();
  const { isLoading, data: userDataPaginated, isEditing } = useSelector(userSelector);

  useEffect(() => {
    if (!isLoading && !isEditing) {
      dispatch({
        ...fetchUsers({
          requestType: "GET",
          request: `/user?page=${page}&limit=${rowsPerPage}${search !== "" ? `&search=${search}` : ""}`
        })
      });
      closeModal();
    }
  }, [page, rowsPerPage, search, isLoading, isEditing]);

  const onEditButtonClick = (item: UserType) => {

    setUser(item);
    setShownModal(userFunctions.EDIT);
  };

  const closeModal = (): void => {
    setShownModal(null);
    dispatch(setSelectedUser(null));
  };

  const create = () => {
    setShownModal(userFunctions.CREATE)
  };

  return (
    <PageWrapper>
      {
        isNil(userDataPaginated?.data) ?
          <Loader /> :
          <>{isLoading && <Loader />}
            <Grid>

              <Grid.Row>
                <Grid.Column width={16}>
                  <div className={styles.headerWrapper}>
                    <h3 className={styles.header}>{translate(`userOverviewTab.pageName`)}</h3>
                    <InputForSearch value={search} handleChange={handleSearchChange}
                      placeholder={translate(`userOverviewTab.filterBy`)} />
                    <PerPageDropdown value={rowsPerPage} options={initialPaginationState.optionsRowsPerPage}
                      handleChange={handleChangeRowsPerPage} />
                    <CreateNewButton handleClick={create}>{translate(`userOverviewTab.create`)}</CreateNewButton>
                  </div>
                  <ContentCard>
                    <UserCard header={true} />
                    {!!userDataPaginated.data.length && userDataPaginated.data.map((user: any, index: number) => {
                      return <UserCard index={index} user={user} handleEdit={onEditButtonClick} />;
                    })}
                    <Pagination page={userDataPaginated.page} pages={userDataPaginated.pages}
                      handleChange={handleChangePage} />
                  </ContentCard>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
      }
      {shownModal === userFunctions.CREATE && <AddUserModal closeModal={closeModal} />}
      {shownModal === userFunctions.EDIT && user && <AddUserModal initialUser={user} closeModal={closeModal} />}
    </PageWrapper>
  );
};

export default UserOverviewTab;
