import React, { FunctionComponent } from "react";
import { translate } from "../../../i18n/i18n";
import BaseModal from "../BaseModal/BaseModal";
import styles from "./StorageOptimizationModal.module.scss";

interface StorageOptimizationModalProps {
  closeModal: () => void;
}

const StorageOptimizationModal: FunctionComponent<StorageOptimizationModalProps> = ({ closeModal }) => {
  return (
    <BaseModal closeModal={closeModal} title={translate("StorageOptimizationModal.title")} style="small">
      <div className={styles.main}>
        {translate("StorageOptimizationModal.content")}
      </div>
    </BaseModal>
  );
};

export default StorageOptimizationModal;