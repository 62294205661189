import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSelector } from "../../pages/auth/Login/loginSlice";
import { fetchDashboard, kpiSelector } from "../../store/kpis/kpiSlice";
import { LocationContext } from "./LocationContext";


type Props = {
  children: React.ReactNode
};

const LocationContextProvider = ({ children }: Props) => {
  const [locations, setLocations] = React.useState<any[]>([]);
  const [location, setLocation] = React.useState<any>(null);
  const { isLoading } = useSelector(loginSelector);
  const { locations: apiLocations, reload } = useSelector(kpiSelector);

  const dispatch = useDispatch();


  React.useEffect(() => {
    if (!location) {
      const stringifiedLocation = localStorage.getItem("location");
      stringifiedLocation && setLocation(JSON.parse(stringifiedLocation));
    }
  }, []);

  React.useEffect(() => {
    if (location) {
      localStorage.setItem("location", JSON.stringify(location));
    }
  }, [location]);

  React.useEffect(() => {
    if (!isLoading) {
      dispatch({
        ...fetchDashboard({
          requestType: "GET",
          request: `/kpi/locations`
        })
      });
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (!isLoading && apiLocations) {
      setLocations(apiLocations);
      localStorage.setItem("locations", JSON.stringify(apiLocations));
    } else if (!isLoading && !apiLocations) {
      const stringifiedLocations = localStorage.getItem("locations");
      stringifiedLocations && setLocations(JSON.parse(stringifiedLocations));
    }
  }, [isLoading, apiLocations]);

  React.useEffect(() => {
    if (locations && !location) {
      const stringifiedLocation = localStorage.getItem("location");
      stringifiedLocation ? setLocation(JSON.parse(stringifiedLocation)) : setLocation(locations[0]);
      ;
    }
  }, [locations, location]);

  return <LocationContext.Provider value={{ locations, location, setLocation }}>{children}</LocationContext.Provider>;
};
export default LocationContextProvider;
