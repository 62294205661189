import React from "react";
import { useSelector } from "react-redux";
import { windowDataSelector } from "../../../store/window/windowDataSlice";
import closeButtonLight from "./../../../assets/icons/close-icon-light.svg";
import closeButtonDark from "./../../../assets/icons/close-icon-dark.svg";
import { THEMES } from "../../../enums/theme.enum";
import styles from "./BaseModal.module.scss";

interface BaseModalProps {
  title: string,
  closeModal: () => void,
  style?: string;
}

export const validateEmail = (mail: string) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
};

export const validateNotEmpty = (value: string) => {
  return value ? !!value.length : false;
};

const BaseModal: React.FunctionComponent<BaseModalProps> = ({ closeModal, title, style, children }) => {
  const { theme } = useSelector(windowDataSelector);

  return (
    <>
      <div className={styles.overlay} />

      <div className={`${styles.modal} ${style === "small" && styles.small} `}>
        <img src={`${theme === THEMES.LIGHT ? closeButtonLight : closeButtonDark}`} className={styles.closeIcon} onClick={closeModal} />
        <h3 className={styles.header}>{title}</h3>
        <div className={styles.wrapper}>
          {children}
        </div>
      </div>
    </>
  );
};

export default BaseModal;
