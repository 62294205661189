import chroma from "chroma-js";
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  format,
  getDay,
  intervalToDuration,
  setDate,
  subDays,
  subMonths,
  subWeeks
} from "date-fns";
import { isNil } from "lodash";
import { TimeFrame } from "../enums/timeFrame.enum";
import { TimeReference } from "../enums/timeReference";
import { ChartContainer, KpiListElement } from "../interfaces/kpi.interface";
import { ComparisonOptions, IComparisonOption } from "../libs/comparison.constants";

const getComparisonsDate = (timeFrame: TimeFrame, comparisonOption: IComparisonOption, date: string, time?: string): string => {
  if (comparisonOption.value === ComparisonOptions.PREVIOUS_TIMEFRAME) {
    switch (timeFrame) {
      case TimeFrame.LAST_24_HOURS:
        return format(addDays(new Date(date), 1), "yyyy/MM/dd");
      case TimeFrame.YESTERDAY:
        return format(addDays(new Date(date), 1), "yyyy/MM/dd");
      case TimeFrame.PREV_WEEK:
        return format(addWeeks(new Date(date), 1), "yyyy/MM/dd");
      case TimeFrame.LAST_30_DAYS:
        return format(addMonths(new Date(date), 1), "yyyy/MM/dd");
      case TimeFrame.LAST_6_MONTHS:
        return format(addMonths(new Date(date), 6), "yyyy/MM/dd");
      case TimeFrame.LAST_YEAR:
        return format(addYears(new Date(date), 1), "yyyy/MM/dd");
      default:
        return "";
    }
  } else {
    switch (timeFrame) {
      case TimeFrame.LAST_24_HOURS:
        if (!isNil(time)) {
          const currentHour: number = Number(format(new Date(), "HH"));
          const dateHour: number = Number(time.split(":")[0]);

          return dateHour >= currentHour ? format(subDays(new Date(), 1), "yyyy/MM/dd") :
            format(new Date(), "yyyy/MM/dd");
        } else {
          return format(new Date(), "yyyy/MM/dd");
        }
      case TimeFrame.YESTERDAY:
        return format(subDays(new Date(), 1), "yyyy/MM/dd");
      case TimeFrame.PREV_WEEK:
        const dayOfWeek: number = getDay(new Date(date)) !== 0 ? getDay(new Date(date)) : 7;
        const currentDayOfWeek: number = getDay(new Date()) !== 0 ? getDay(new Date()) : 7;

        if (dayOfWeek === currentDayOfWeek) {
          return format(subWeeks(new Date(), 1), "yyyy/MM/dd");
        } else if (dayOfWeek > currentDayOfWeek) {
          const diff: number = dayOfWeek - currentDayOfWeek;
          const newDate: Date = addDays(new Date(), diff);

          return format(subWeeks(newDate, 1), "yyyy/MM/dd");
        } else {
          const diff: number = currentDayOfWeek - dayOfWeek;
          const newDate: Date = subDays(new Date(), diff);

          return format(newDate, "yyyy/MM/dd");
        }
      case TimeFrame.LAST_30_DAYS:
        const dayOfMonth: number = Number(format(new Date(date), "dd"));
        const today: number = Number(format(new Date(), "dd"));

        return dayOfMonth >= today ? format(subMonths(setDate(new Date(), dayOfMonth), 1), "yyyy/MM/dd") :
          format(setDate(new Date(), dayOfMonth), "yyyy/MM/dd")
      case TimeFrame.LAST_6_MONTHS:
        return format(addMonths(new Date(date), 6), "yyyy/MM/dd");
      case TimeFrame.LAST_YEAR:
        return format(addYears(new Date(date), 1), "yyyy/MM/dd");
      default:
        return "";
    }
  }
};

const chartFunc = (currentRec: any, isStorage: boolean, selectedTimeFrame?: TimeFrame, selectedComparisonOption?: IComparisonOption) => {
  let date: string = "";

  if (!isNil(selectedTimeFrame) && !isNil(selectedComparisonOption)) {
    date = getComparisonsDate(selectedTimeFrame, selectedComparisonOption, currentRec.date, currentRec.time);
  } else {
    date = format(new Date(currentRec.date), "yyyy/MM/dd");
  }

  return {
    x: currentRec.timeReference === TimeReference.DAY ? date : format(new Date(date + " " + currentRec.time), "yyyy/MM/dd HH:mm:ss"),
    y: isStorage ? currentRec.value : Math.floor(currentRec.value)
  };
};

export const getChartSeriesData = (
  chartData: ChartContainer,
  isStorage: boolean,
  selectedTimeFrame?: TimeFrame,
  selectedComparisonOption?: IComparisonOption,
): any => {
  const data: any = [];
  console.log(selectedComparisonOption);
  for (const key in chartData) {
    data.push({
      data: [...chartData[key].map((el: any) => chartFunc(el, isStorage, selectedTimeFrame, selectedComparisonOption))],
      type: data.length > 0 || isStorage ? "line" : "column",
      name: selectedComparisonOption?.value && selectedComparisonOption?.value !== ComparisonOptions.PLEASE_SELECT ? "0" : ""
    });
  }

  return data;
};

export const getChartSeriesForecastData = (chartData: ChartContainer): any => {
  let data: number = 0;

  for (const key in chartData) {
    const currentAmount = chartData[key].filter((el: any) => el.forecast).length;
    data = currentAmount > data ? currentAmount : data;
  }

  return data;
};

export const getChartSeriesColors = (selectedKpis: KpiListElement[], comparisons?: any): string[] => {
  if (comparisons) {
    const mainColors: string[] = selectedKpis.map((kpi: KpiListElement) => kpi.color);
    const comparisonsColors: string[] = mainColors.map((color: string) => {
      const rgbColor: number[] = chroma(color).rgb();
      return chroma([...rgbColor, 0.3]).hex();
    });

    return [...mainColors, ...comparisonsColors];
  } else {
    return selectedKpis.map((kpi: KpiListElement) => kpi.color);
  }
};

export const getYAxisOptions = (chartData: ChartContainer, comparisons?: any): any => {
  const numberOfYAxis: number = comparisons ? Object.keys(chartData).length * 2 : Object.keys(chartData).length;

  return Array(numberOfYAxis).fill(0).map((el: string, index: number) => ({
    show: false,
    seriesName: `${index}`
  }));
};

export const getChartTooltip = (selectedTimeFrame: TimeFrame, selectedKpis: string[]): any => {
  return {
    enabled: true,
    shared: true,
    intersect: false,
    marker: {
      show: true,
    },
    onDatasetHover: {
      highlightDataSeries: true,
    },
    custom: (params: any) => {
      const { series, seriesIndex, dataPointIndex, w } = params;
      const isHourReference: boolean = selectedTimeFrame === TimeFrame.LAST_24_HOURS || selectedTimeFrame === TimeFrame.YESTERDAY;
      const colors: string[] = w.globals.colors;
      const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
      const hoverIndexes = w.globals.seriesX.map((seriesX: any) => {
        return seriesX.findIndex((xData: any) => xData === hoverXaxis);
      });

      if (!hoverXaxis) {
        return `<div></div>`;
      }

      const date: string = hoverXaxis ? format(new Date(hoverXaxis), isHourReference ? "MMMM dd, HH:mm" : "MMMM dd") : "";
      let hoverList = "";

      hoverIndexes.forEach((hoverIndex: any, seriesEachIndex: any) => {
        const isTimeValue: boolean = selectedKpis[seriesEachIndex].includes("_TIME");
        let time: string = "";

        if (isTimeValue && !isNil(series[seriesEachIndex][hoverIndex])) {
          const timeObject: any = intervalToDuration({ start: 0, end: Math.abs(series[seriesEachIndex][hoverIndex]) * 1000 });
          const days: string = timeObject.days > 0 ? timeObject.days : "";
          const hours: string = timeObject.hours > 0 ? `${timeObject.hours < 10 ? `0${timeObject.hours}` : timeObject.hours}:` : "";
          const minutes: string = timeObject.minutes > 0 ? `${timeObject.minutes < 10 ? `0${timeObject.minutes}` : timeObject.minutes}:` : "00:";
          const seconds: string = timeObject.seconds > 0 ? `${timeObject.seconds < 10 ? `0${timeObject.seconds}` : timeObject.seconds}` : "00";
          time = series[seriesEachIndex][hoverIndex] >= 0 ? `${hours}${minutes}${seconds}` : `-${hours}${minutes}${seconds}`;
          if(days) {
            time = `${days} days ${time}`;
          }
        }
        if (hoverIndex >= 0) {
          hoverList += `<div class="tooltip-value">
                            <span class="tooltip-value-color" style="background-color: ${colors[seriesEachIndex]}"} /></span>
                            ${isTimeValue ? time : series[seriesEachIndex][hoverIndex]}
                        </div>`;
        }
      });

      return `<div class="chart-tooltip"> ${date} ${hoverList} </div>`;
    }
  };
};