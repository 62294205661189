import { isNil } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { locationFunctions, LocationType } from "../../../enums/locations-enums";
import { UserType } from "../../../enums/users/users-enums";
import { usePagination } from "../../../helpers/usePagination";
import { useTableSearch } from "../../../helpers/useTableSearch/useTableSearch";
import { translate } from "../../../i18n/i18n";
import { initialPaginationState } from "../../../interfaces/pagination-interface";
import { fetchLocations, locationSelector } from "../../../store/locations/locationSlice";
import { setSelectedUser } from "../../../store/users/userSlice";
import AddLocationModal from "../../modals/AddLocationModal/AddLocationModal";
import ContentCard from "../../shared/ContentCard";
import { CreateNewButton } from "../../shared/CreateNewButton/CreateNewButton";
import { InputForSearch } from "../../shared/InputForSearch/InputForSearch";
import Loader from "../../shared/Loader";
import { LocationTableCard } from "../../shared/LocationTableCard/LocationTableCard";
import { PageWrapper } from "../../shared/PageWrapper/PageWrapper";
import { Pagination } from "../../shared/Pagination/Pagination";
import { PerPageDropdown } from "../../shared/PerPageDropdown/PerPageDropdown";
import styles from "./LocationOverviewTab.module.scss";

interface LocationOverviewTabProps {
}

const LocationOverviewTab: FunctionComponent<LocationOverviewTabProps> = () => {
  const dispatch = useDispatch();
  const [shownModal, setShownModal] = useState<locationFunctions | null>(null);
  const [location, setLocation] = useState<LocationType | null>(null);
  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } = usePagination();
  const { handleSearchChange, search } = useTableSearch();
  const { isLoading, error, data: locationDataPaginated } = useSelector(locationSelector);

  useEffect(() => {
    if (!isLoading) {
      dispatch({
        ...fetchLocations({
          requestType: "GET",
          request: `/location?page=${page}&limit=${rowsPerPage}${search !== "" ? `&search=${search}` : ""}`
        })
      });
    }

  }, [isLoading, page, rowsPerPage, search]);

  const onEditButtonClick = (item: LocationType) => {
    setLocation(item);
    setShownModal(locationFunctions.EDIT);
  };

  const closeModal = (): void => {
    setShownModal(null);
    dispatch(setSelectedUser(null));
  };

  const create = () => {
    setShownModal(locationFunctions.CREATE);
  };
  return (
    <PageWrapper>
      {
        isNil(locationDataPaginated) ?
          <Loader /> :
          <>{isLoading && <Loader />}
            <Grid>

              <Grid.Row>
                <Grid.Column width={16}>
                  <div className={styles.headerWrapper}>
                    <h3 className={styles.header}>{translate(`LocationOverviewTab.pageName`)}</h3>
                    <InputForSearch value={search} handleChange={handleSearchChange}
                                    placeholder={translate(`LocationOverviewTab.filterBy`)} />
                    <PerPageDropdown value={rowsPerPage} options={initialPaginationState.optionsRowsPerPage}
                                     handleChange={handleChangeRowsPerPage} />
                    <CreateNewButton handleClick={create}>{translate(`LocationOverviewTab.create`)}</CreateNewButton>
                  </div>
                  <ContentCard>
                    <LocationTableCard header={true} />
                    {!!locationDataPaginated.data.length && locationDataPaginated.data.map((location: any, index: number) => {
                      return <LocationTableCard index={index} location={location} handleEdit={onEditButtonClick} />;
                    })}
                    <Pagination page={locationDataPaginated.page} pages={locationDataPaginated.pages}
                                handleChange={handleChangePage} />
                  </ContentCard>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
      }
      {shownModal === locationFunctions.CREATE && <AddLocationModal closeModal={closeModal} />}
      {shownModal === locationFunctions.EDIT && location &&
      <AddLocationModal initialLocation={location} closeModal={closeModal} />}
    </PageWrapper>
  );
};

export default LocationOverviewTab;

