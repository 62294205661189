import React, { FunctionComponent, useState } from "react";
import { translate } from "../../../i18n/i18n";

import styles from "./Dropdown.module.scss";

interface DropdownProps {
  value: number | undefined,
  options: any[],
  valueText?: string,
  label?: string,
  handleChange: (value: number) => void
}

export const Dropdown: FunctionComponent<DropdownProps> = ({ value, handleChange, options, valueText, label }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onElementClick = (value: number): void => {
    handleChange(value);
    setIsOpen((prevState: boolean) => !prevState);
  };

  return <div>
    {label && <div className={styles.label}>{label}</div>}
    <div className={`${styles.dropdown} ${isOpen ? styles.opened : ""}`}>
      <div className={styles.selected} onClick={() => setIsOpen((prevState: boolean) => !prevState)}>
        {(value !== null && options && !!options.length && options?.find( (option:any) => option.id === value )?.name) || translate(`dropdown.pleaseSelect`)}
        <div className={styles.arrow} />
      </div>
      <div className={styles.list}>
        {
          options && !!options.length && options.map((obj: any, index: number) => {
            return (
              <div key={index} className={styles.value} onClick={() => onElementClick(obj.id)}>
                <div className={styles.valueContainer}>
                  <div className={styles.valueName}>{obj.name}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  </div>;
};