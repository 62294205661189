import React, { FunctionComponent } from "react";

import styles from "./CreateNewButton.module.scss";

interface CreateNewButtonProps {
  handleClick?: any
}

export const CreateNewButton: FunctionComponent<CreateNewButtonProps> = ({ handleClick, children }) => {
  return <button onClick={handleClick} className={styles.button}>{children}</button>;
};
