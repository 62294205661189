import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProcessesEnums } from "../enums/processes-enums";
import { alertSelector, fetchAlerts, fetchAlertsAllProcesses, fetchAlertsOnTabs } from "../store/alerts/alertSlice";


export const useAlerts = (locationId:string, processPrep?:ProcessesEnums) => {

  const { data: alertsData, isLoading, allAlerts } = useSelector(alertSelector);
  const [process, setProcess] = useState<ProcessesEnums | null>(processPrep || null);
  const dispatch = useDispatch();

  const [showAlerts, setShowAlerts] = useState<boolean>(true);
  useEffect(() => {
    if (locationId && process && !isLoading) {
      dispatch({
        ...fetchAlertsOnTabs(
          {
            requestType: "GET",
            request: `/alert?location=${locationId}&process=${process}&confirmed=0&limit=3&page=1`
          }
        )
      });
    }
  }, [locationId, process, isLoading]);

  useEffect(() => {
    if (locationId && !isLoading) {
      dispatch({
        ...fetchAlertsAllProcesses(
          {
            requestType: "GET",
            request: `/alert?location=${locationId}&confirmed=0&limit=100&page=1`
          }
        )
      });
    }
  }, [locationId, isLoading]);

  return {
    alertsData,
    setProcess,
    showAlerts,
    setShowAlerts,
    allAlerts
  }
}