import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import { notificationSelector, removeNotification } from "../../../store/notifications/notificationSlice";
import { useStyles } from "./styles";

interface CustomNotificationProps { }

const CustomNotification: FunctionComponent<CustomNotificationProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data } = useSelector(notificationSelector);

  const onClose = (): void => {
    dispatch(removeNotification());
  };

  return (
    <>
      {
        data && data[0] &&
        <Alert className={classes.Notification} severity={data[0].type} onClose={onClose}>
          <AlertTitle className={classes.AlertTitle}>{data[0].title}</AlertTitle>
          {data[0].body}
        </Alert>
      }
    </>
  );
};

export default CustomNotification;