import React, { FunctionComponent } from "react";
import { SwipeableHandlers } from "react-swipeable";
import { KpiType } from "../../../enums/kpi.enum";
import { useSwipe } from "../../../hooks/swipeHook";
import KpiContainer from "../../shared/KpiContainer/KpiContainer";
import StorageContainer from "../../shared/StorageContainer/StorageContainer";
import styles from "./LocationTabsOverview.module.scss";

interface LocationTabsOverviewProps {
  locationId: string;
}

const LocationTabsOverview: FunctionComponent<LocationTabsOverviewProps> = ({ locationId }) => {
  const handlers: SwipeableHandlers | null = useSwipe();

  return (
    <div className={styles.overviewTab}>
      <div className={styles.chart}>
        <KpiContainer location={locationId} kpiType={KpiType.OUTBOUND} />
      </div>

      <div className={styles.storage} {...handlers}>
        <StorageContainer location={locationId} />
      </div>

      <div className={styles.chart}>
        <KpiContainer location={locationId} kpiType={KpiType.INBOUND} />
      </div>
    </div>
  );
};

export default LocationTabsOverview;