import React, { FunctionComponent } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { scale } from "chroma-js";
import { isNil } from "lodash";
import { Box, CircularProgress } from "@material-ui/core";
import { THEMES } from "../../../enums/theme.enum";
import { windowDataSelector } from "../../../store/window/windowDataSlice";
import { getAlertsCount, getStorageValueStyle } from "../../../helpers/locationHelper";
import { calculateAvgDiff, Fluctuations, getAvgTime, TimeDifference } from "../../../helpers/avgTimesHelper";
import styles from "./LocationOverview.module.scss";

interface LocationOverviewProps {
  location: any;
}

const LocationOverview: FunctionComponent<LocationOverviewProps> = ({ location }) => {
  const history = useHistory();
  const { isDesktop, theme } = useSelector(windowDataSelector);
  const { storageAll, storageAllPrevious, employees, avgInboundTime, avgInboundTimePrevious, avgOutboundTime, avgOutboundTimePrevious } = location;
  const storageValue: number = isNil(storageAll) ? 0 : Number(storageAll.ofValue) === Number(storageAll.value) ? 100 :
                               Math.floor((Number(storageAll.ofValue) - (Number(storageAll.value))) / Number(storageAll.ofValue) * 100);
  const employeesValue: number = !isNil(employees) ? employees : 0;
  const avgInboundTimeValue: string = getAvgTime(avgInboundTime);
  const avgOutboundTimeValue: string = getAvgTime(avgOutboundTime);
  const radialBarOptions: any = {
    chart: {
      fontFamily: "Nunito, sans-serif"
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: `${isDesktop ? "60%" : "55%"}`,
          background: "transparent"
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: `${isDesktop ? 18 : 14}`,
            color: theme === THEMES.LIGHT ? "#626262" : "#A9A9A9",
            fontSize: "1rem",
            fontWeight: "bold"
          },
          value: {
            color: theme === THEMES.LIGHT ? "#626262" : "#fff",
            fontSize: `${isDesktop ? "2.8rem" : "2.4rem"}`,
            fontWeight: "bold",
            offsetY: `${isDesktop ? -14 : -18}`
          }
        },
        track: {
          background: "transparent"
        }
      }
    },
    fill: {
      colors: [(options: any) => {
        const { value } = options;
        const getColor: any = scale(["#507ee3", "#2fc07d"]).domain([0, 100]);

        return getColor(value).hex();
      }]
    },
    stroke: {
      lineCap: "round"
    },
    labels: ["OF GOAL"]
  };
  const inboundCalcDiff: TimeDifference | null = calculateAvgDiff(Number(avgInboundTime), Number(avgInboundTimePrevious));
  const outboundCalcDiff: TimeDifference | null = calculateAvgDiff(Number(avgOutboundTime), Number(avgOutboundTimePrevious));

  return (
    <div className={styles.locationWrapper} onClick={() => history.push(`location/${location.id}/overview`)}>
      <div className={styles.locationHeader}>
        <div className={styles.locationName}>{location.name}</div>
        {
          getAlertsCount(location) > 0 &&
          <div className={styles.locationAlerts}>{getAlertsCount(location)}</div>
        }
      </div>

      <div className={styles.locationMain}>
        <div className={styles.chartContainer}>
          <div className={styles.chartTitle}>outbound</div>

          {
            isNil(location.goals) || isNil(location.goals.outbound) ?
              <Box className={styles.loader}>
                <CircularProgress />
              </Box> :
              <Chart className={styles.backgroundCircle}
                     options={radialBarOptions}
                     type="radialBar"
                     series={[(location.goals.outbound.totalAmount / location.goals.outbound.goalAmount * 100).toFixed()]}
                     height={`${isDesktop ? "83%" : "80%"}`} />
          }

          <div className={styles.timeContainer}>
            <div className={styles.title}>avg time</div>

            <div className={styles.time}>
              <div className={styles.title}>{avgOutboundTimeValue}</div>

              {
                outboundCalcDiff &&
                <div className={styles.percentage}>
                  <div className={`${styles.arrows} ${outboundCalcDiff.fluctuation === Fluctuations.DECREASE ? styles.arrowUp : styles.arrowDown}`} />

                  <span>
                    {outboundCalcDiff.percentage}
                  </span>
                </div>
              }
            </div>
          </div>
        </div>

        <div className={styles.chartContainer}>
          <div className={styles.chartTitle}>inbound</div>

          {
            isNil(location.goals) || isNil(location.goals.inbound) ?
              <Box className={styles.loader}>
                <CircularProgress />
              </Box> :
              <Chart className={styles.backgroundCircle}
                     options={radialBarOptions}
                     type="radialBar"
                     series={[(location.goals.inbound.totalAmount / location.goals.inbound.goalAmount * 100).toFixed()]}
                     height={`${isDesktop ? "83%" : "80%"}`} />
          }

          <div className={styles.timeContainer}>
            <div className={styles.title}>avg time</div>

            <div className={styles.time}>
              <div className={styles.title}>{avgInboundTimeValue}</div>

              {
                inboundCalcDiff &&
                <div className={styles.percentage}>
                  <div className={`${styles.arrows} ${inboundCalcDiff.fluctuation === Fluctuations.DECREASE ? styles.arrowUp : styles.arrowDown}`} />

                  <span>
                    {inboundCalcDiff.percentage}
                  </span>
                </div>
              }
            </div>
          </div>
        </div>

        <div className={styles.storageContainer}>
          <div className={styles.chartTitle}>storage</div>

          <div className={styles.storageChartContainer}>
            <div className={styles.lastDayBar} style={getStorageValueStyle(storageValue)} />

            <div className={styles.barsContainer}>
              <div className={styles.barsTitle}>
                <div className={styles.title}>{storageValue}%</div>
                <div className={styles.text}>FILLED</div>
              </div>

              <div className={styles.barsChart}>
                {
                  storageAllPrevious.map((day: { value: string; ofValue: string; }, index: number) => {
                    const barValue: number = Number(day.ofValue) === Number(day.value) ? 100 :
                                             Math.floor((Number(day.ofValue) - (Number(day.value))) / Number(day.ofValue) * 100);
                    return <div key={index} className={styles.singleBar} style={getStorageValueStyle(barValue)} />;
                  })
                }
              </div>
            </div>
          </div>

          <div className={styles.employeesContainer}>
            <div className={styles.title}>employees</div>

            <div className={styles.count}>{employeesValue}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationOverview;