import React, { ChangeEvent, ChangeEventHandler, FunctionComponent } from "react";

import styles from "./Input.module.scss";

interface InputForSearchProps {
  value: string | number | undefined,
  name: string,
  type?: string,
  label?: string,
  placeholder?: string,
  handleChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown;}>) => void
}

export const Input: FunctionComponent<InputForSearchProps> = ({ type, value, handleChange, placeholder, name, label }) => {

  return <div className={styles.row}><div className={styles.label}>{label}</div><input type={type || 'text'} className={styles.input} placeholder={placeholder} value={value} onChange={handleChange} name={name} /></div>;
};