import React, { ChangeEvent, FunctionComponent } from "react";

import styles from "./Checkbox.module.scss";

interface CheckboxProps {
  value: string | number | undefined,
  name: string,
  label?: string,
  placeholder?: string,
  handleChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void,
  index?: number,
  checked: boolean
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
                                                             value,
                                                             handleChange,
                                                             placeholder,
                                                             name,
                                                             label,
                                                             index,
                                                             checked
                                                           }) => {
  return <div key={index || 0}><label className={styles.label}>
    <input type="checkbox" className={styles.input}
           placeholder={placeholder} value={value}
           onChange={handleChange}
           name={name}
           checked={checked} /> {label}
  </label>
  </div>;
};