import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface bulkErrorInterface {
  type: string,
  message: string
}

interface initialUsersStateInterface {
  data: any,
  company: any,
  isLoading: boolean,
  error: string,
}

const initialState: initialUsersStateInterface = {
  data: null,
  company: null,
  isLoading: false,
  error: ""
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    fetchCompanies: (state, action: any) => {
      if (action.state === "finished" && !!action.response) {
        state.data = action.response;
        state.isLoading = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchSelf: (state, action: any) => {
      state.isLoading = true;
      if (action.state === "finished") {
        state.data = action.response;
        state.isLoading = false;
      }
    },
    fetchCompany: (state, action: any) => {
      if (action.state === "finished") {
        state.isLoading = false;
        state.error = "";
      }

      if (action.state === "finished" && !!action.response) {
        state.company = action.response;
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    setCompany: (state, action: any) => {
      state.company = action.payload;
    },
    addCompany: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        state.error = "";
        state.isLoading = false;
      }
      if (action.state === "error") {
        state.isLoading = false;
        state.error = action.response?.error;
      }
    },
    editCompany: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        state.isLoading = false;
      }
    },
    deleteCompany: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && action.response) {
        state.isLoading = false;
        state.error = "";
      }
    },
    finishLoading(state) {
      state.isLoading = false;
    },

    clearCompany: (state) => {
      state.company = null;
      state.error = "";
      state.isLoading = false;
    },
    clearCompanyStore: () => {
      return initialState;
    }
  }
});

export const {
  fetchSelf,
  fetchCompanies,
  fetchCompany,
  editCompany,
  addCompany,
  clearCompany,
  deleteCompany,
  setCompany,
  clearCompanyStore
} = companySlice.actions;
export const companySelector = (state: RootState) => state.company;
export default companySlice.reducer;
