import React, { ChangeEvent, FunctionComponent } from 'react';

import styles from './InputForSearch.module.scss';

interface InputForSearchProps {
	value: string | number;
	placeholder?: string;
	handleChange: (
		event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
	) => void;
}

export const InputForSearch: FunctionComponent<InputForSearchProps> = ({
	value,
	handleChange,
	placeholder,
}) => {
	return (
		<form className={styles['input-wrapper']} autoComplete="off">
			<input
				className={styles.input}
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
			/>
		</form>
	);
};
