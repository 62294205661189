import React, { FunctionComponent } from "react";

import styles from "./Pagination.module.scss";

interface PerPageDropdownProps {
  page: number,
  pages: number,
  handleChange: (value: number) => void
}

export const Pagination: FunctionComponent<PerPageDropdownProps> = ({ page, pages, handleChange }) => {

  const hasNextPage = page < pages;
  const hasPrevPage = page > 1;

  return <>
    <div className={styles.pagination}>
      <span className={`${styles.prevButton} ${!hasPrevPage && styles.disabled}`}
            onClick={() => hasPrevPage && handleChange(page - 1)}><div className={styles.arrow} /></span>
      <span className={styles.page}>{page}</span>
      <span className={`${styles.nextButton} ${!hasNextPage && styles.disabled}`}
            onClick={() => hasNextPage && handleChange(page + 1)}><div className={styles.arrow} /></span>
    </div>
  </>;
};