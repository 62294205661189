import { isNil } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Chart from "react-apexcharts";
import { Box, CircularProgress } from "@material-ui/core";
import { KpiListElement } from "../../../../interfaces/kpi.interface";
import { IDates } from "../../../../interfaces/calendar.interface";
import { TimeFrame } from "../../../../enums/timeFrame.enum";
import { THEMES } from "../../../../enums/theme.enum";
import { clearKpisInfo, fetchOverview, kpiSelector } from "../../../../store/kpis/kpiSlice";
import { windowDataSelector } from "../../../../store/window/windowDataSlice";
import {
  getChartSeriesColors,
  getChartSeriesData,
  getChartSeriesForecastData,
  getChartTooltip
} from "../../../../helpers/chartHelper";
import styles from "./StorageChart.module.scss";

interface StorageChartProps {
  locationId: string;
  selectedTimeFrame: TimeFrame;
  storageType: KpiListElement;
  storageCategory: KpiListElement;
  dates: IDates;
}

const StorageChart: FunctionComponent<StorageChartProps> = ({
  locationId,
  selectedTimeFrame,
  storageType,
  storageCategory,
  dates
}) => {
  const dispatch = useDispatch();
  const { selectedTabKpisInfo } = useSelector(kpiSelector);
  const { isDesktop, theme } = useSelector(windowDataSelector);
  const [series, setSeries] = useState<any>();
  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      redrawOnParentResize: true,
      toolbar: false,
      type: "line"
    },
    forecastDataPoints: {
      count: 0
    },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      },
    },
    stroke: {
      curve: "smooth",
      width: 3
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: theme === THEMES.LIGHT ? "#F4F7F9" : "#11243B",
        height: -320,
        offsetX: 0,
        offsetY: 0
      },
      axisBorder: {
        show: true,
        color: theme === THEMES.LIGHT ? "#F4F7F9" : "#11243B",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false,
      inverseOrder: false
    },
    fill: {
      opacity: 1
    }
  });

  useEffect(() => {
    return () => {
      dispatch(clearKpisInfo());
    };
  }, []);

  useEffect(() => {
    dispatch({
      ...fetchOverview(
        {
          requestType: "GET",
          request: `kpi/storageType?location=${locationId}&type=${storageType.value}${storageCategory.value !== "CLEAR" ? `&category=${storageCategory.value}` : ""}&timeFrame=${selectedTimeFrame}${!isNil(dates.from) ? `&from=${dates.from}&to=${dates.to}` : ""}`
        }
      )
    });
  }, [locationId, storageType, storageCategory, selectedTimeFrame]);

  useEffect(() => {
    if (!isNil(selectedTabKpisInfo)) {

      const newForecastPoints = getChartSeriesForecastData(selectedTabKpisInfo.STORAGE);
      setChartOptions({
        ...chartOptions,
        forecastDataPoints: {
          count: newForecastPoints
        }
      });

      setSeries(getChartSeriesData(selectedTabKpisInfo.STORAGE, true));
      setChartOptions((prevState: any) => ({
        ...prevState,
        colors: getChartSeriesColors(Number(storageCategory.value) ? [storageCategory, storageType] : [storageType, storageCategory])
      }));
    }

  }, [selectedTabKpisInfo]);

  useEffect(() => {
    if (!isNil(selectedTabKpisInfo) && !isNil(selectedTabKpisInfo.STORAGE)) {
      setChartOptions((prevState: any) => ({
        ...prevState,
        legend: {
          ...prevState.legend,
          inverseOrder: !prevState.legend.inverseOrder
        },
        tooltip: getChartTooltip(selectedTimeFrame, [...Object.keys(selectedTabKpisInfo.STORAGE)])
      }));
    }

    if (isBigInterval()) {
      setChartOptions((prevState: any) => ({
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          labels: {
            show: true,
            formatter: (val: any) => {
              return format(val, "MMM dd");
            }
          }
        }
      }));
    } else {
      setChartOptions((prevState: any) => ({
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          labels: {
            show: false
          }
        }
      }));
    }
  }, [selectedTimeFrame, selectedTabKpisInfo]);

  const isBigInterval = (): boolean => {
    return selectedTimeFrame === TimeFrame.LAST_6_MONTHS || selectedTimeFrame === TimeFrame.LAST_YEAR;
  };

  return (
    <>
      {
        !isNil(series) ?
          <div className={`${styles.storageChart} ${isBigInterval() ? styles.xaxisLabels : ""}`}>
            <Chart type="line" options={chartOptions} series={series} height={isDesktop ? 350 : 160} />
          </div> :
          <Box className={styles.loader}>
            <CircularProgress />
          </Box>
      }
    </>
  );
};

export default StorageChart;