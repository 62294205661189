export default {
	translation: {
		alerts: `Alerts`,
		AlertListByProcessMore: {
			viewMore: `Weitere {{amount}} Warnungen anzeigen`,
		},
		dropdown: {
			pleaseSelect: 'Bitte wählen...',
		},
		AddCompanyModal: {
			error: {
				emptyForm: `Bitte füllen Sie das Formular aus`,
				name: `Bitte geben Sie den Firmennamen ein`,
				apiConstant: `Bitte geben Sie die Konstante zur API befüllung ein`,
				street: `Bitte geben Sie die Straße der Firma ein`,
				streetNo: `Bitte geben Sie die Hausnummer der Firma ein`,
				zip: `Bitte geben Sie die Postleitzahl der Firma ein`,
				city: `Bitte geben Sie die Stadt der Firma ein`,
				country: `Bitte geben Sie das Land der Firma ein`,
			},
		},
		AddLocationModal: {
			edit: 'Standort bearbeiten',
			delete: 'Standort entfernen',
			create: 'Neuer Standort',
			error: {
				emptyForm: `Bitte füllen Sie das Formular aus`,
				name: `Bitte geben Sie den Standortnamen ein`,
				company: `Bitte wählen Sie eine Firma aus`,
				apiConstant: `Bitte geben Sie die Konstante zur API befüllung ein`,
				street: `Bitte geben Sie die Straße des Standorts ein`,
				streetNo: `Bitte geben Sie die Hausnummer des Standorts ein`,
				zip: `Bitte geben Sie die Postleitzahl des Standorts ein`,
				city: `Bitte geben Sie die Stadt des Standorts ein`,
				country: `Bitte geben Sie das Land des Standorts ein`,
				shiftStart: `Bitte gebe den Schichtanfang an`,
				shiftEnd: `Bitte gebe das Schichtende an`,
				weekday: `Bitte wähle mindestens einen Wochentag aus`,
			},
		},
		AddContractModal: {
			create: 'Grenzwert erstellen',
			edit: 'Grenzwert bearbeiten',
			delete: 'Grenzwert entfernen',
			error: {
				emptyForm: `Bitte füllen Sie das Formular aus`,
				type: `Bitte wählen Sie eine KPI aus`,
				company: `Bitte wählen Sie eine Firma aus`,
				timeReference: `Bitte wählen Sie einen Zeitraum aus`,
				severityReference: `Bitte wählen Sie einen Schweregrad `,
				minOrMax: `Bitte geben Sie entweder einen minimal oder maximal Wert ein`,
				locations: `Bitte wählen Sie mindestens einen Standort aus`,
				notificationAlertGoal: `Bitte definiere ob dieser Grenzwert, ein Alarm, eine Benachrichtigung oder ein Ziel darstellt`,
			},
		},
		ContractOverviewTab: {
			pageName: 'Grenzwert',
			filterBy: 'Nach KPI...',
			create: 'Neuer Grenzwert',
		},
		ContractCard: {
			type: 'KPI',
			company: 'Firma',
			time: 'Zeitraum',
			severity: 'Level',
			min: 'Min',
			max: 'Max',
			notification: 'Ben.',
			alert: 'Warnung',
			goal: 'Ziel',
			edit: 'bearbeiten',
			yes: 'ja',
			no: 'nein',
			locations: 'Standorte',
			markAsGoal: 'Als Ziel festlegen',
			sendAlertsOrNotifications:
				'Benachrichtigungen und Warnungen senden',
		},

		LocationTableCard: {
			name: 'Name',
			company: 'Firma',
			apiConstant: 'API Konstante',
			address: 'Adresse',
			street: 'Straße',
			streetNo: 'Hausnummer',
			zip: 'PLZ',
			city: 'Stadt',
			country: 'Land',
			shift: 'Schicht',
			shiftOn: 'Wochentage',
			edit: 'Bearbeiten',
			shiftStart: 'Schichtanfang',
			shiftEnd: 'Schichtende',
		},
		LocationOverviewTab: {
			pageName: 'Standorte',
			filterBy: 'Nach Standortname...',
			create: 'Neuer Standort',
		},
		CompanyOverviewTab: {
			pageName: 'Firmen',
			filterBy: 'Nach Firmenname...',
			create: 'Neue Firma erstellen',
		},
		ConfirmModal: {
			areYouSure: 'Sind Sie sicher?',
			confirm: 'Bestätigen',
			cancel: 'Abbrechen',
		},
		CompanyCard: {
			name: 'Name',
			apiConstant: 'API Konstante',
			street: 'Straße',
			streetNo: 'Hausnummer',
			zip: 'PLZ',
			city: 'Stadt',
			country: 'Land',
			taxId: 'VAT',
			locations: 'Standort',
			edit: 'Firma bearbeiten',
			delete: 'Firma entfernen',
			create: 'Neue Firma erstellen',
		},
		AddUserModal: {
			error: {
				firstNameEmpty: `Bitte füllen Sie das Feld Vorname`,
				lastNameEmpty: `Bitte füllen Sie das Feld Nachname`,
				incorrectEmail: `Die hinterlegte E-Mail Adresse ist nicht korrekt`,
				emptyForm: `Bitte füllen Sie das Formular aus`,
				roleEmpty: `Bitte weisen Sie dem Nutzer eine Rolle zu`,
				locationEmpty: `Bitte wählen Sie mindestens einen Standort aus`,
			},
			label: {
				firstName: 'Vorname',
				lastName: 'Nachname',
				email: 'E-Mail',
				role: 'Rolle',
				locations: 'Standort',
				sendAlert: 'Warnungen senden',
				company: "Firma"
			},
			delete: 'Nutzer entfernen',
		},
		userOverviewTab: {
			pageName: 'Nutzer',
			name: 'Name',
			email: 'E-Mail',
			company: 'Firma',
			role: 'Rolle',
			edit: 'Nutzer bearbeiten',
			create: 'Neuen Nutzer erstellen',
			filterBy: 'Suchen nach Name oder E-mail',
			locations: 'Standorte',
		},
		alertsOverviewTab: {
			pageName: 'Alerts',
			title: 'Titel',
			value: 'Wert',
			timestamp: 'Erstellt',
			process: 'Prozess',
			markAsResolve: 'Bestätigen',
			confirmed: 'Bestätigt',
			no: 'Nein',
			filterBy: 'Suchen nach Titel oder Beschreibung',
		},
		purchase: 'smartOmeter freischalten',
		contactSupport: 'Support kontaktieren',
		callBack: 'Rückruf anfordern',
		withExampleData: 'Mit Beispieldaten starten',
		withoutExampleData: 'Ohne Beispieldaten starten',
		removeExampleData: 'Beispieldaten entfernen',
		notRemoveExampleData: 'Beispieldaten übernehmen',
		editProfile: {
			myProfile: 'Mein Profil',
			profileInfoTab: 'Persönliche Informationen',
			changePasswordTab: 'Passwort ändern',
			firstNamePlaceholder: 'Alexander',
			secondNamePlaceholder: 'Alexander',
			firstName: 'Vorname',
			lastName: 'Nachname',
			email: 'E-Mail Adresse',
			oldPassword: 'Altes Passwort',
			newPassword: 'Neues Passwort',
			newPasswordConfirm: 'Neues Passwort wiederholen',
			cancel: 'Abbrechen',
			save: 'Änderungen speichern',
			delete: 'Account löschen',
			alreadyExists:
				'Diese E-Mail ist bereits einem Benutzerkonto zugeordnet.',
			passwordError: {
				1: 'Bitte geben Sie Ihr aktuelles Passwort ein.',
				2: 'Die neuen Passwörter stimmen nicht überein.',
				3: 'Passwort wurde nicht geändert: Das aktuelle Passwort ist nicht korrekt.',
			},
		},
		delete: 'Nutzer löschen',
		DELETE: 'Auswahl entfernen',
		ASSIGN_COURSE: 'Kurs zuweisen',
		ASSIGN_GROUP: 'Gruppe zuweisen',
		authentication: {
			header: 'Welcome to PreScriVa',
			subhead: '',
			name: 'Your name',
			email: 'Email',
			password: 'Password',
			repeatPassword: 'Repeat Password',
			forgottenPassword: 'Passwort vergessen?',
			alreadyHaveAccount: 'Ich bin schon registriert.',
			stillDontHaveAccount: 'Ich habe noch kein Konto.',
			logIn: 'Anmelden',
			registerRedirect: 'Registrieren',
			registerTitle: 'Registrierung',
			register: {
				header: 'Registrierung',
				cta: 'Registrieren',
				firstname: 'Vorname',
				lastname: 'Nachname',
				email: 'E-Mail',
				institution: 'Institution',
				password: 'Password',
				passwordCompare: 'Repeat Password',
				errors: {
					firstname: 'Firstname',
					lastname: 'Lastname',
					email: 'E-Mail',
					institution: 'Institution',
					password: 'Password',
					passwordCompare: 'Repeat Password',
				},
			},
		},
		hello: 'Hello',
		headerTitle: 'SmartoMeter',
		firstName: 'First Name',
		name: 'Name',
		myProfile: 'My Profile',
		addStudent: 'Add Student',
		//navbar
		overview: 'Overview',
		qualifications: 'Qualifications',
		student: 'Student',
		autorentool: 'Authoring tool',
		loginHeader: 'Login',
		password: 'Password',
		forgetPassword: 'Passwort vergessen?',
		login: 'Login',
		resetPassword: 'Reset Password',
		reset: 'Reset',
		goToLogin: 'Go to Login',
		logout: 'Logout',
		formError: 'Form Error',
		lastName: 'Surname',
		email: 'E-Mail',
		close: 'Close',
		save: 'Speichern',
		cancel: 'Abbrechen',
		users: {
			assignments: {
				headline: 'Kurszuweisungsübersicht',
				createNewButton: 'Neue Kurszuweisung',
			},
			headline: 'Nutzer',
			users: {
				edit: {
					headline: 'Nutzer bearbeiten',
				},
				headline: 'Nutzerübersicht',
				createNewButton: 'Neuer Nutzer',
				importButton: 'Importieren',
				assignments: {
					ALL: 'Alle',
					WITH_ASSIGNMENT: 'Nur mit Zuweisung',
					WITHOUT_ASSIGNMENT: 'Nur ohne Zuweisung',
				},
				create: {
					error: {
						alreadyTaken: 'Dieser Nutzer existiert bereits',
						alreadyTakenOtherInstitution:
							'Dieser Nutzer existiert bereits in einer anderen Institution',
					},
				},
				bulkCreate: {
					step1: {
						headline: 'Importiere alle Teilnehmer',
						placeholder: 'CSV-Datei auswählen',
						or: 'oder',
						exampleDownload: 'CSV Vorlage herunterladen',
						startImportButtonText: 'Datei importieren',
					},
					step2: {
						importing: 'Datei wird importiert',
						success: {
							message: 'Datei wurde importiert',
						},
						error: {
							headline:
								'Die Datei konnte nicht importiert werden',
							duplicates:
								'{{count}} Nutzer konnte(n) nicht erstellt werden, da sie bereits vorhanden sind. {{imported}} wurde(n) erfolgreich erstellt.',
							malformatted:
								'Das Format der Datei ist nicht korrekt. Bitte nutzen Sie die Vorlage. Sollte der Fehler weiterhin auftreten, wenden Sie sich bitte an den Support',
							error:
								'Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support',
							tryAgain: 'Erneut versuchen',
							generalMessage:
								'Die Datei konnte nicht vollständig importiert werden',
							absoluteMessage:
								'Die Datei konnte nicht importiert werden',
						},
					},
				},
			},
			groups: {
				headline: 'Gruppenübersicht',
				createNewButton: 'Neue Gruppe',
				detail: {
					deadline: {
						ALL: 'Alle',
						MISSED: 'Frist überschritten',
						NOT_MISSED: 'Frist nicht überschritten',
					},
				},
			},
		},
		courses: {
			headline: 'Kurse',
			header: {
				courses: 'Kurse',
				bookedCourses: 'APPucations Kurse',
				shop: 'Shop',
			},
			filter: {
				active: 'Aktiv',
				archived: 'Archiviert',
				all: 'Alle',
			},
		},
		settings: {
			headline: 'Einstellungen',
			header: {
				general: 'Allgemein',
				presentation: 'Darstellung',
				team: 'Team',
				certificate: 'Zertifikate',
				invoice: 'Rechnungen',
			},
			team: {
				members: {
					headline: 'Teammitglieder',
					create: {
						headline: 'Neues Teammitglied',
					},
				},
				groups: {
					headline: 'Gruppen',
				},
				roles: {
					headline: 'Rollen',
					deleteMessage:
						'Soll die Rolle {{name}} wirklich gelöscht werden?',
					createNewButton: 'Neue Rolle',
					modal: {
						headline: 'Definiere eine neue Rolle',
						namePlaceholder: 'Neue Rolle eingeben',
					},
					PERMISSION: {
						FE_SOM_DASHBOARD: 'Zugriff auf das Dashboard',
						FE_SOM_USERS: 'Zugriff auf die Nutzerverwaltung',
						FE_SOM_COURSES:
							'Zugriff auf die Kursverwaltung und das Autorentool',
						FE_SOM_SETTINGS: 'Zugriff auf die Einstellungen',
						NOT_EDITABLE: '(nicht editierbar)',
					},
				},
			},
			general: {
				domain: 'domain',
				name: 'Firmenname',
				street: 'Straße',
				number: 'Nr.',
				post_code: 'PLZ',
				city: 'Ort',
				country: 'Land',
				vat_id: 'Ust-ID',
				invoice_street: 'Straße',
				invoice_number: 'Nr.',
				invoice_post_code: 'PLZ',
				invoice_city: 'Ort',
				first_name: 'Vorname',
				last_name: 'Name',
				email: 'E-Mail',
				phone: 'Telefonnummer',
			},
			contractValues: {
				headline: 'Vertragswerte',
				pageTitle: 'Vertragswerte',
				createNewButton: 'Neuen Vertragswert',
				company: 'Firma',
				locations: 'Standort',
				kpi: 'KPI',
				min: 'Min',
				max: 'Max',
				severity: 'Priorität',
				timeReference: 'Zeitraum',
				notification: 'Benachrichtigung',
				alert: 'Alarm',
				modalTitle: 'Erstelle Vertragswert',
				submit: 'Speichern',
				noData: 'Keine Vertragswerte',
				deleteContractTitle: 'Lösche Vertragswert',
				deleteContractBody:
					'Sind Sie sicher, dass Sie diesen Vertragswert löschen möchten?',
			},
		},
		perPage: 'pro Seite',
		from: 'von',
		moreThan: 'mehr als',
		StorageOptimizationModal: {
			title: "Storage optimization",
			content: "To implement the storage optimization, please reach out to your sales contact."
		},
		noInternetConnetion: 'No internet connection'
	},
};
