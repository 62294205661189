import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface RegisterState {
  isSuccess: boolean;
  isSuccessEmail: boolean;
  errorMessage: string;
  shouldRedirect: boolean;
}

const initialState: RegisterState = {
  isSuccess: false,
  isSuccessEmail: false,
  errorMessage: "",
  shouldRedirect: false
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    register: (state, action: any) => {
      state.errorMessage = "";
      if (action.state === "finished") {
        state.isSuccessEmail = true;
      } else if (action.state === "error") {
        state.errorMessage = "This Email is already taken";
      }
    },
    clearState: state => {
      state.isSuccess = false;
      return state;
    },
    setPassword: (state, action: any) => {
      if (action.state === "finished") {

        localStorage.setItem("jwt", action.response.accessToken);

        state.isSuccess = true;
      }
    }
  }
});

export const { register, clearState, setPassword } = registerSlice.actions;

export const registerSelector = (state: RootState) => state.register;

export default registerSlice.reducer;
