import React, { CSSProperties, FunctionComponent, useState } from "react";
import { SwipeableHandlers } from "react-swipeable";
import { useSwipe } from "../../../hooks/swipeHook";
import { DropdownPosition } from "../../../enums/chart.enum";
import { ChartOptions } from "../../../interfaces/chart.interface";
import { KpiListElement } from "../../../interfaces/kpi.interface";
import styles from "./ChartDropdown.module.scss";

interface ChartDropdownProps {
  chartOptions: ChartOptions;
  position: DropdownPosition;
  getSelectedKpi: (kpi: KpiListElement) => void;
}

const ChartDropdown: FunctionComponent<ChartDropdownProps> = ({ chartOptions, position, getSelectedKpi }) => {
  const handlers: SwipeableHandlers | null = useSwipe();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selected: any = position === DropdownPosition.FIRST ? chartOptions[DropdownPosition.FIRST].selected : chartOptions[DropdownPosition.SECOND].selected;
  const selectedStyle = { "--bg-color": selected.color } as CSSProperties;


  const onElementClick = (kpi: KpiListElement): void => {
    getSelectedKpi({ ...kpi, dropdownPosition: position });
    setIsOpen((prevState: boolean) => !prevState);
  };

  return (
    <>
      <div className={`${styles.dropdownContainer} ${isOpen ? styles.opened : ""}`} {...handlers}>
        <div className={styles.selectedKpi} onClick={() => setIsOpen((prevState: boolean) => !prevState)}>
          <div className={styles.kpiContainer}>
            <div className={`${styles.kpiColor} ${selected.name === "Select metric" ? styles.default : ""}`} style={selectedStyle} />
            <div className={styles.kpiName}>{selected.name}</div>
          </div>

          <div className={styles.arrow} />
        </div>

        <div className={styles.kpiList}>
          {
            chartOptions[position].list.map((kpi: KpiListElement, index: number) => {
              const style = { "--bg-color": kpi.color } as CSSProperties;

              return (
                <div key={index} className={styles.dropdownKpi} onClick={() => onElementClick(kpi)}>
                  <div className={styles.kpiContainer}>
                    <div className={`${styles.kpiColor} ${kpi.name === "Select metric" ? styles.default : ""}`} style={style} />
                    <div className={styles.kpiName}>{kpi.name}</div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>

      {isOpen && <div className={styles.overlay} onClick={() => setIsOpen(false)} />}
    </>
  );
};

export default ChartDropdown;