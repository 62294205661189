import { KpiType } from "../enums/kpi.enum";

export const OUTBOUND = {
  mainKpi: {
    name: "Delivery amount",
    value: "DELIVERY_AMOUNT",
    type: KpiType.OUTBOUND,
    color: "#2fc07d"
  },
  list: [
    {
      name: "Outbound time",
      value: "OUTBOUND_TIME",
      type: KpiType.OUTBOUND,
      color: "#e79f34"
    },
    {
      name: "Employees in outbound",
      value: "EMPLOYEES_IN_OUTBOUND",
      type: KpiType.OUTBOUND,
      color: "#eb5757"
    },
    {
      name: "Pick time",
      value: "PICK_TIME",
      type: KpiType.OUTBOUND,
      color: "#9E5AC3"
    },
    {
      name: "Pack time",
      value: "PACK_TIME",
      type: KpiType.OUTBOUND,
      color: "#3fa2ab"
    },
    {
      name: "Load time",
      value: "LOAD_TIME",
      type: KpiType.OUTBOUND,
      color: "#507ee3"
    },
    {
      name: "Outbound chill time",
      value: "OUTBOUND_CHILL_TIME",
      type: KpiType.OUTBOUND,
      color: "#58CCFD"
    },
    {
      name: "Parcel amount",
      value: "PARCEL_AMOUNT",
      type: KpiType.OUTBOUND,
      color: "#E377AB"
    },
    {
      name: "Employees in packing",
      value: "EMPLOYEES_IN_PACKING",
      type: KpiType.OUTBOUND,
      color: "#B7C84F"
    },
    {
      name: "Employees in picking",
      value: "EMPLOYEES_IN_PICKING",
      type: KpiType.OUTBOUND,
      color: "#13808F"
    }
  ]
};

export const PARCEL_AMOUNT = {
  mainKpi: {
    name: "Parcel amount",
    value: "PARCEL_AMOUNT",
    type: KpiType.OUTBOUND,
    color: "#E377AB"
  },
  list: [
    {
      name: "Outbound time",
      value: "OUTBOUND_TIME",
      type: KpiType.OUTBOUND,
      color: "#e79f34"
    },
    {
      name: "Employees in outbound",
      value: "EMPLOYEES_IN_OUTBOUND",
      type: KpiType.OUTBOUND,
      color: "#eb5757"
    },
    {
      name: "Pick time",
      value: "PICK_TIME",
      type: KpiType.OUTBOUND,
      color: "#9E5AC3"
    },
    {
      name: "Pack time",
      value: "PACK_TIME",
      type: KpiType.OUTBOUND,
      color: "#3fa2ab"
    },
    {
      name: "Load time",
      value: "LOAD_TIME",
      type: KpiType.OUTBOUND,
      color: "#507ee3"
    },
    {
      name: "Outbound chill time",
      value: "OUTBOUND_CHILL_TIME",
      type: KpiType.OUTBOUND,
      color: "#58CCFD"
    },
    {
      name: "Delivery amount",
      value: "DELIVERY_AMOUNT",
      type: KpiType.OUTBOUND,
      color: "#2fc07d"
    },
    {
      name: "Employees in packing",
      value: "EMPLOYEES_IN_PACKING",
      type: KpiType.OUTBOUND,
      color: "#B7C84F"
    },
    {
      name: "Employees in picking",
      value: "EMPLOYEES_IN_PICKING",
      type: KpiType.OUTBOUND,
      color: "#13808F"
    }
  ]
};

export const INBOUND = {
  mainKpi: {
    name: "Inbound amount",
    value: "INBOUND_AMOUNT",
    type: KpiType.INBOUND,
    color: "#E377AB"
  },
  list: [
    {
      name: "Inbound time",
      value: "INBOUND_TIME",
      type: KpiType.INBOUND,
      color: "#e79f34"
    },
    {
      name: "Employees in inbound",
      value: "EMPLOYEES_IN_INBOUND",
      type: KpiType.INBOUND,
      color: "#B7C84F"
    },
    {
      name: "Putaway time",
      value: "PUTAWAY_TIME",
      type: KpiType.INBOUND,
      color: "#9E5AC3"
    },
    {
      name: "Unload time",
      value: "UNLOAD_TIME",
      type: KpiType.INBOUND,
      color: "#507ee3"
    },
    {
      name: "Inbound clarification time",
      value: "INBOUND_CLARIFICATION_TIME",
      type: KpiType.INBOUND,
      color: "#58CCFD"
    },
    {
      name: "Pallet amount",
      value: "PALLET_AMOUNT",
      type: KpiType.INBOUND,
      color: "#13808F"
    }
  ]
};

export const PALLET_AMOUNT = {
  mainKpi: {
    name: "Pallet amount",
    value: "PALLET_AMOUNT",
    type: KpiType.INBOUND,
    color: "#13808F"
  },
  list: [
    {
      name: "Inbound amount",
      value: "INBOUND_AMOUNT",
      type: KpiType.INBOUND,
      color: "#E377AB"
    },
    {
      name: "Inbound time",
      value: "INBOUND_TIME",
      type: KpiType.INBOUND,
      color: "#e79f34"
    },
    {
      name: "Employees in inbound",
      value: "EMPLOYEES_IN_INBOUND",
      type: KpiType.INBOUND,
      color: "#B7C84F"
    },
    {
      name: "Putaway time",
      value: "PUTAWAY_TIME",
      type: KpiType.INBOUND,
      color: "#9E5AC3"
    },
    {
      name: "Unload time",
      value: "UNLOAD_TIME",
      type: KpiType.INBOUND,
      color: "#507ee3"
    },
    {
      name: "Inbound clarification time",
      value: "INBOUND_CLARIFICATION_TIME",
      type: KpiType.INBOUND,
      color: "#58CCFD"
    }
  ]
};

export const INBOUND_TIME = {
  mainKpi: {
    name: "Inbound time",
    value: "INBOUND_TIME",
    type: KpiType.INBOUND,
    color: "#e79f34"
  },
  list: [
    {
      name: "Inbound amount",
      value: "INBOUND_AMOUNT",
      type: KpiType.INBOUND,
      color: "#E377AB"
    },
    {
      name: "Pallet amount",
      value: "PALLET_AMOUNT",
      type: KpiType.INBOUND,
      color: "#13808F"
    },
    {
      name: "Employees in inbound",
      value: "EMPLOYEES_IN_INBOUND",
      type: KpiType.INBOUND,
      color: "#B7C84F"
    },
    {
      name: "Putaway time",
      value: "PUTAWAY_TIME",
      type: KpiType.INBOUND,
      color: "#9E5AC3"
    },
    {
      name: "Unload time",
      value: "UNLOAD_TIME",
      type: KpiType.INBOUND,
      color: "#507ee3"
    },
    {
      name: "Inbound clarification time",
      value: "INBOUND_CLARIFICATION_TIME",
      type: KpiType.INBOUND,
      color: "#58CCFD"
    }
  ]
};

export const UNLOAD_TIME = {
  mainKpi: {
    name: "Unload time",
    value: "UNLOAD_TIME",
    type: KpiType.INBOUND,
    color: "#507ee3"
  },
  list: [
    {
      name: "Inbound amount",
      value: "INBOUND_AMOUNT",
      type: KpiType.INBOUND,
      color: "#E377AB"
    },
    {
      name: "Pallet amount",
      value: "PALLET_AMOUNT",
      type: KpiType.INBOUND,
      color: "#13808F"
    },
    {
      name: "Employees in inbound",
      value: "EMPLOYEES_IN_INBOUND",
      type: KpiType.INBOUND,
      color: "#B7C84F"
    },
    {
      name: "Putaway time",
      value: "PUTAWAY_TIME",
      type: KpiType.INBOUND,
      color: "#9E5AC3"
    },
    {
      name: "Inbound time",
      value: "INBOUND_TIME",
      type: KpiType.INBOUND,
      color: "#e79f34"
    },
    {
      name: "Inbound clarification time",
      value: "INBOUND_CLARIFICATION_TIME",
      type: KpiType.INBOUND,
      color: "#58CCFD"
    }
  ]
};

export const CLARIFICATION_TIME = {
  mainKpi: {
    name: "Inbound clarification time",
    value: "INBOUND_CLARIFICATION_TIME",
    type: KpiType.INBOUND,
    color: "#58CCFD"
  },
  list: [
    {
      name: "Inbound amount",
      value: "INBOUND_AMOUNT",
      type: KpiType.INBOUND,
      color: "#E377AB"
    },
    {
      name: "Pallet amount",
      value: "PALLET_AMOUNT",
      type: KpiType.INBOUND,
      color: "#13808F"
    },
    {
      name: "Employees in inbound",
      value: "EMPLOYEES_IN_INBOUND",
      type: KpiType.INBOUND,
      color: "#B7C84F"
    },
    {
      name: "Putaway time",
      value: "PUTAWAY_TIME",
      type: KpiType.INBOUND,
      color: "#9E5AC3"
    },
    {
      name: "Inbound time",
      value: "INBOUND_TIME",
      type: KpiType.INBOUND,
      color: "#e79f34"
    },
    {
      name: "Unload time",
      value: "UNLOAD_TIME",
      type: KpiType.INBOUND,
      color: "#507ee3"
    }
  ]
};

export const TIMES = {
  inbound: {
    mainKpi: {
      name: "Inbound time",
      value: "INBOUND_TIME",
      type: KpiType.INBOUND,
      color: "#e79f34"
    },
    list: [
      {
        name: "Inbound amount",
        value: "INBOUND_AMOUNT",
        type: KpiType.INBOUND,
        color: "#E377AB"
      },
      {
        name: "Pallet amount",
        value: "PALLET_AMOUNT",
        type: KpiType.INBOUND,
        color: "#13808F"
      },
      {
        name: "Employees in inbound",
        value: "EMPLOYEES_IN_INBOUND",
        type: KpiType.INBOUND,
        color: "#B7C84F"
      },
      {
        name: "Putaway time",
        value: "PUTAWAY_TIME",
        type: KpiType.INBOUND,
        color: "#9E5AC3"
      },
      {
        name: "Unload time",
        value: "UNLOAD_TIME",
        type: KpiType.INBOUND,
        color: "#507ee3"
      },
      {
        name: "Inbound clarification time",
        value: "INBOUND_CLARIFICATION_TIME",
        type: KpiType.INBOUND,
        color: "#58CCFD"
      }
    ]
  },
  unload: {
    mainKpi: {
      name: "Unload time",
      value: "UNLOAD_TIME",
      type: KpiType.INBOUND,
      color: "#507ee3"
    },
    list: [
      {
        name: "Inbound amount",
        value: "INBOUND_AMOUNT",
        type: KpiType.INBOUND,
        color: "#E377AB"
      },
      {
        name: "Pallet amount",
        value: "PALLET_AMOUNT",
        type: KpiType.INBOUND,
        color: "#13808F"
      },
      {
        name: "Employees in inbound",
        value: "EMPLOYEES_IN_INBOUND",
        type: KpiType.INBOUND,
        color: "#B7C84F"
      },
      {
        name: "Putaway time",
        value: "PUTAWAY_TIME",
        type: KpiType.INBOUND,
        color: "#9E5AC3"
      },
      {
        name: "Inbound time",
        value: "INBOUND_TIME",
        type: KpiType.INBOUND,
        color: "#e79f34"
      },
      {
        name: "Inbound clarification time",
        value: "INBOUND_CLARIFICATION_TIME",
        type: KpiType.INBOUND,
        color: "#58CCFD"
      }
    ]
  },
  clarification: {
    mainKpi: {
      name: "Inbound clarification time",
      value: "INBOUND_CLARIFICATION_TIME",
      type: KpiType.INBOUND,
      color: "#58CCFD"
    },
    list: [
      {
        name: "Inbound amount",
        value: "INBOUND_AMOUNT",
        type: KpiType.INBOUND,
        color: "#E377AB"
      },
      {
        name: "Pallet amount",
        value: "PALLET_AMOUNT",
        type: KpiType.INBOUND,
        color: "#13808F"
      },
      {
        name: "Employees in inbound",
        value: "EMPLOYEES_IN_INBOUND",
        type: KpiType.INBOUND,
        color: "#B7C84F"
      },
      {
        name: "Putaway time",
        value: "PUTAWAY_TIME",
        type: KpiType.INBOUND,
        color: "#9E5AC3"
      },
      {
        name: "Inbound time",
        value: "INBOUND_TIME",
        type: KpiType.INBOUND,
        color: "#e79f34"
      },
      {
        name: "Unload time",
        value: "UNLOAD_TIME",
        type: KpiType.INBOUND,
        color: "#507ee3"
      }
    ]
  },
  outbound: {
    mainKpi: {
      name: "Outbound time",
      value: "OUTBOUND_TIME",
      type: KpiType.OUTBOUND,
      color: "#e79f34"
    },
    list: [
      {
        name: "Delivery amount",
        value: "DELIVERY_AMOUNT",
        type: KpiType.OUTBOUND,
        color: "#2fc07d"
      }
      ,
      {
        name: "Employees in outbound",
        value: "EMPLOYEES_IN_OUTBOUND",
        type: KpiType.OUTBOUND,
        color: "#eb5757"
      },
      {
        name: "Pick time",
        value: "PICK_TIME",
        type: KpiType.OUTBOUND,
        color: "#9E5AC3"
      },
      {
        name: "Pack time",
        value: "PACK_TIME",
        type: KpiType.OUTBOUND,
        color: "#3fa2ab"
      },
      {
        name: "Load time",
        value: "LOAD_TIME",
        type: KpiType.OUTBOUND,
        color: "#507ee3"
      },
      {
        name: "Outbound chill time",
        value: "OUTBOUND_CHILL_TIME",
        type: KpiType.OUTBOUND,
        color: "#58CCFD"
      },
      {
        name: "Parcel amount",
        value: "PARCEL_AMOUNT",
        type: KpiType.OUTBOUND,
        color: "#E377AB"
      },
      {
        name: "Employees in packing",
        value: "EMPLOYEES_IN_PACKING",
        type: KpiType.OUTBOUND,
        color: "#B7C84F"
      },
      {
        name: "Employees in picking",
        value: "EMPLOYEES_IN_PICKING",
        type: KpiType.OUTBOUND,
        color: "#13808F"
      }
    ]
  },
  pack: {
    mainKpi: {
      name: "Pack time",
      value: "PACK_TIME",
      type: KpiType.OUTBOUND,
      color: "#3fa2ab"
    },
    list: [
      {
        name: "Delivery amount",
        value: "DELIVERY_AMOUNT",
        type: KpiType.OUTBOUND,
        color: "#2fc07d"
      }
      ,
      {
        name: "Employees in outbound",
        value: "EMPLOYEES_IN_OUTBOUND",
        type: KpiType.OUTBOUND,
        color: "#eb5757"
      },
      {
        name: "Pick time",
        value: "PICK_TIME",
        type: KpiType.OUTBOUND,
        color: "#9E5AC3"
      },
      {
        name: "Outbound time",
        value: "OUTBOUND_TIME",
        type: KpiType.OUTBOUND,
        color: "#e79f34"
      },
      {
        name: "Load time",
        value: "LOAD_TIME",
        type: KpiType.OUTBOUND,
        color: "#507ee3"
      },
      {
        name: "Outbound chill time",
        value: "OUTBOUND_CHILL_TIME",
        type: KpiType.OUTBOUND,
        color: "#58CCFD"
      },
      {
        name: "Parcel amount",
        value: "PARCEL_AMOUNT",
        type: KpiType.OUTBOUND,
        color: "#E377AB"
      },
      {
        name: "Employees in packing",
        value: "EMPLOYEES_IN_PACKING",
        type: KpiType.OUTBOUND,
        color: "#B7C84F"
      },
      {
        name: "Employees in picking",
        value: "EMPLOYEES_IN_PICKING",
        type: KpiType.OUTBOUND,
        color: "#13808F"
      }
    ]
  },
  pick: {
    mainKpi: {
      name: "Pick time",
      value: "PICK_TIME",
      type: KpiType.OUTBOUND,
      color: "#9E5AC3"
    },
    list: [
      {
        name: "Delivery amount",
        value: "DELIVERY_AMOUNT",
        type: KpiType.OUTBOUND,
        color: "#2fc07d"
      }
      ,
      {
        name: "Employees in outbound",
        value: "EMPLOYEES_IN_OUTBOUND",
        type: KpiType.OUTBOUND,
        color: "#eb5757"
      },
      {
        name: "Pack time",
        value: "PACK_TIME",
        type: KpiType.OUTBOUND,
        color: "#3fa2ab"
      },
      {
        name: "Outbound time",
        value: "OUTBOUND_TIME",
        type: KpiType.OUTBOUND,
        color: "#e79f34"
      },
      {
        name: "Load time",
        value: "LOAD_TIME",
        type: KpiType.OUTBOUND,
        color: "#507ee3"
      },
      {
        name: "Outbound chill time",
        value: "OUTBOUND_CHILL_TIME",
        type: KpiType.OUTBOUND,
        color: "#58CCFD"
      },
      {
        name: "Parcel amount",
        value: "PARCEL_AMOUNT",
        type: KpiType.OUTBOUND,
        color: "#E377AB"
      },
      {
        name: "Employees in packing",
        value: "EMPLOYEES_IN_PACKING",
        type: KpiType.OUTBOUND,
        color: "#B7C84F"
      },
      {
        name: "Employees in picking",
        value: "EMPLOYEES_IN_PICKING",
        type: KpiType.OUTBOUND,
        color: "#13808F"
      }
    ]
  }
};

export const CHART_TIMES = {
  INBOUND: ["inbound", "unload", "clarification"],
  OUTBOUND: ["outbound", "pack", "pick"]
};

export const STORAGE_TYPES = [
  {
    name: "Storage all",
    value: "STORAGE_ALL",
    color: "#315e26",
    type: null
  },
  {
    name: "Storage ABC",
    value: "STORAGE_ABC",
    color: "#7a9f79",
    type: null
  },
  {
    name: "Storage zone",
    value: "STORAGE_ZONE",
    color: "#b1dd9e",
    type: null
  }
];