import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../app/axios';
import { Container, CssBaseline } from '@material-ui/core';
import logo from '../../../assets/images/logo-ldpi.png';
import { translate } from '../../../i18n/i18n';
import { useStyles } from '../styles';

const ForgotPassword = () => {
	const classes = useStyles();
	const [email, setEmail] = useState<string>('');
	const [hasSent, setHasSent] = React.useState(false);

	const onSubmit = async () => {
		setHasSent(true);
		try {
			await axios.post('/user/reset-password', { email });
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<img className={classes.Logo} src={logo} />
					<div style={{ width: '100%' }}>
						{hasSent ? (
							<label htmlFor="email" className={classes.Label}>
								Please check your e-mail for instructions how to
								reset your password.
							</label>
						) : (
							<>
								<label
									htmlFor="email"
									className={classes.Label}
								>
									Enter your e-mail address to request an
									e-mail with a link to reset your password.
								</label>
								<input
									placeholder={translate(
										`authentication.register.email`,
									)}
									onChange={(
										e: React.ChangeEvent<HTMLInputElement>,
									) => setEmail(e.target.value)}
									className={classes.Input}
									name="email"
								/>

								<button
									disabled={
										!email ||
										!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
											email,
										)
									}
									onClick={onSubmit}
									className={classes.SubmitButton}
								>
									Reset Password
								</button>
							</>
						)}
					</div>
				</div>
				<div className={classes.ForgotPasswordWrapper}>
					<Link className={classes.ForgotPasswordLink} to="/login">
						Back to Login
					</Link>
				</div>
			</Container>
		</div>
	);
};

export default ForgotPassword;
