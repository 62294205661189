import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface initialNotificationsStateInterface {
  data: any[];
  isLoading: boolean;
  error: string;
  token: string;
}

const initialState: initialNotificationsStateInterface = {
  data: [],
  token: "",
  isLoading: false,
  error: ""
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action: any) => {
      state.data.push(action.payload);
    },
    removeNotification: (state) => {
      state.data.splice(0, 1);
    },
    saveNotificationToken: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        localStorage.setItem("notificationToken", action.payload.body.token);
      }
      if (action.state === "error") {
        state.isLoading = false;
        state.error = action.response?.error;
      }
    },
    addToken(state, action: any) {
      state.token = action.payload;
    },
    clearNotificationStore: () => {
      return initialState;
    }
  }
});

export const {
  addNotification,
  removeNotification,
  saveNotificationToken,
  addToken,
  clearNotificationStore
} = notificationSlice.actions;
export const notificationSelector = (state: RootState) => state.notification;
export default notificationSlice.reducer;
