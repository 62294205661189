import { KpiReference, KpiType } from "./kpi.enum";
import { SeverityReference } from "./severityReference";
import { TimeReference } from "./timeReference";

export enum contractFunctions {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE"
}

export enum deleteTypes {
  DELETE = "DELETE",
  REMOVE = "REMOVE"
}

export type ContractType = {
  id?: number,
  type?: KpiReference,
  timeReference?: TimeReference,
  severityReference?: SeverityReference,
  min?: number,
  max?: number,
  notification?: boolean,
  alert?: boolean,
  company?: any,
  locations?: any[],
  goal?: boolean
}