import React from 'react';
import { translate } from '../../../i18n/i18n';
import styles from '../../../pages/Profile/Profile.module.scss';

const ProfileInformationTab: React.FunctionComponent<{
	handleChange: Function;
	values: {
		firstName: string;
		lastName: string;
		email: string;
	};
}> = ({ handleChange, values }) => {
	return values.firstName !== undefined ? (
		<div>
			<h3 className={styles.tabHeadline}>
				{translate('editProfile.profileInfoTab')}
			</h3>
			<div className={styles.inputFieldWrapper}>
				<label htmlFor="firstName" className={styles.inputLabel}>
					{translate('editProfile.firstName')}
				</label>
				<input
					onChange={e => handleChange('firstName', e)}
					name="firstName"
					value={values.firstName}
					className={styles.inputField}
				/>
				<label htmlFor="lastName" className={styles.inputLabel}>
					{translate('editProfile.lastName')}
				</label>
				<input
					onChange={e => handleChange('lastName', e)}
					name="lastName"
					value={values.lastName}
					className={styles.inputField}
				/>
				<label htmlFor="email" className={styles.inputLabel}>
					{translate('editProfile.email')}
				</label>
				<input
					onChange={e => handleChange('email', e)}
					name="email"
					type="email"
					value={values.email}
					className={styles.inputField}
				/>
			</div>
		</div>
	) : null;
};

export default ProfileInformationTab;
