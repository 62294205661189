import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { THEMES } from "../../enums/theme.enum";

interface initialWindowDataStateInterface {
  isDesktop: boolean;
  theme: THEMES;
  classes: string;
}

const initialState: initialWindowDataStateInterface = {
  isDesktop: true,
  theme: THEMES.LIGHT,
  classes: ""
};

export const windowDataSlice = createSlice({
  name: "windowData",
  initialState,
  reducers: {
    setIsDesktop: (state, action: any) => ({
      ...state,
      isDesktop: action.payload
    }),
    setClasses: (state, action: any) => ({
      ...state,
      classes: action.payload
    }),
    setTheme: (state, action: any) => ({
      ...state,
      theme: action.payload
    }),
  }
});

export const {
  setIsDesktop,
  setTheme,
  setClasses
} = windowDataSlice.actions;
export const windowDataSelector = (state: RootState) => state.windowData;
export default windowDataSlice.reducer;
