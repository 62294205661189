import React, { FunctionComponent } from "react";

import styles from "./BaseCard.module.scss";


export const BaseCardHeader: FunctionComponent<{}> = ({ children }) => {
  return <div className={styles.header}>{children}</div>;
};
export const BaseCardValue: FunctionComponent<{}> = ({ children }) => {
  return <div className={styles.value}>{children}</div>;
};
export const BaseCardAttributeHeader: FunctionComponent<{}> = ({ children }) => {
  return <div className={styles.attribute}>
    <span className={styles.attributeName}>{children}</span>
  </div>;
};
export const BaseCardAttribute: FunctionComponent<{ title: string }> = ({ title, children }) => {
  return <div className={styles.attribute}>
    <span className={styles.attributeName}>{title}</span>
    <span className={styles.attributeValue}>{children}</span>
  </div>;
};

export const BaseCardEdit: FunctionComponent<{ edit: (data: any) => void, data: any }> = ({
                                                                                            children,
                                                                                            data,
                                                                                            edit
                                                                                          }) => {
  return <button className={styles.confirmButton} onClick={() => {
    edit(data);
  }
  }>{children}</button>;
};


interface BaseCardProps {
  index?: number,
  header?: boolean,
  columns?: number
}

export const BaseCard: FunctionComponent<BaseCardProps> = ({ columns, index, header, children }) => {

  return <div key={index}>
    {header && !index ? (
      <div className={`${styles.wrapper} ${columns && styles[`columns-${columns.toString()}`]} ${styles.header}`}>
        {children}
      </div>) : (<div key={index}
                      className={`${styles.wrapper} ${columns && styles[`columns-${columns.toString()}`]} ${styles.body}`}>
      {children}
    </div>)}
  </div>;

};