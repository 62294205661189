import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);
export const validationSchema = yup.object().shape({
	password: yup
		.string()
		.password()
		.minLowercase(
			1,
			'Password must contain at least 1 number, 1 lowercase and 1 uppercase letter.',
		)
		.minUppercase(
			1,
			'Password must contain at least 1 number, 1 lowercase and 1 uppercase letter.',
		)
		.minSymbols(
			0,
			'Password must contain at least 1 number, 1 lowercase and 1 uppercase letter.',
		)
		.minNumbers(
			1,
			'Password must contain at least 1 number, 1 lowercase and 1 uppercase letter.',
		) // raise the lowercase requirement to 8
		.min(8, 'Password must contain at least 8 characters.'),
	passwordConfirm: yup
		.string()
		.label('Password Confirm')
		.required('Please confirm your password.')
		.oneOf([yup.ref('password')], 'Password do not match.'),
});
