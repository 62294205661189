import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
  Notification: {
    position: "fixed",
    top: "1%",
    right: "5%",
    maxWidth: "45rem",
    fontSize: "1.2rem",
    zIndex: 9999
  },
  AlertTitle: {
    fontSize: "1.6rem"
  }
}));
