import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isEqual, isNil } from "lodash";
import { SwipeableHandlers } from "react-swipeable";
import { Box, CircularProgress } from "@material-ui/core";
import { useSwipe } from "../../../hooks/swipeHook";
import { TimeFrame } from "../../../enums/timeFrame.enum";
import { StorageType } from "../../../enums/kpi.enum";
import { IDates } from "../../../interfaces/calendar.interface";
import { KpiListElement } from "../../../interfaces/kpi.interface";
import { STORAGE_TYPES } from "../../../libs/kpi.constants";
import { getStorageAllValue } from "../../../helpers/storageHelper";
import { clearStorageCategoryList, fetchStorageOverview, kpiSelector } from "../../../store/kpis/kpiSlice";
import CalendarDropdown from "../CalendarDropdown/CalendarDropdown";
import StorageChart from "./StorageChart/StorageChart";
import StorageDropdown from "./StorageDropdown/StorageDropdown";
import StorageOptimizationModal from "../../modals/StorageOptimizationModal/StorageOptimizationModal";
import styles from "./StorageOverviewContainer.module.scss";
interface StorageOverviewContainerProps {
  locationId: string;
}

const StorageOverviewContainer: FunctionComponent<StorageOverviewContainerProps> = ({ locationId }) => {
  const dispatch = useDispatch();
  const handlers: SwipeableHandlers | null = useSwipe();
  const { storageOverview, selectedTabKpisInfo } = useSelector(kpiSelector);
  const [selectedStorageOption, setSelectedStorageOption] = useState<KpiListElement>(STORAGE_TYPES[0]);
  const [storageOptions, setStorageOptions] = useState<KpiListElement[]>(STORAGE_TYPES.filter((el: KpiListElement) => !isEqual(el, selectedStorageOption)));
  const [selectedStorageCategory, setSelectedStorageCategory] = useState<KpiListElement>({
    name: "Select metric",
    value: "CLEAR",
    type: null,
    color: "#fff"
  });
  const [storageCategories, setStorageCategories] = useState<KpiListElement[]>([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>(TimeFrame.LAST_24_HOURS);
  const [dates, setDates] = useState<IDates>({ from: null, to: null });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch({
      ...fetchStorageOverview(
        {
          requestType: "GET",
          request: `/kpi/storageOverview?location=${locationId}`
        }
      )
    });
  }, [locationId]);

  useEffect(() => {
    if (!isNil(selectedTabKpisInfo) && !isNil(selectedTabKpisInfo.STORAGE_CATEGORY_LIST) && isEmpty(storageCategories)) {
      // @ts-ignore
      setStorageCategories(selectedTabKpisInfo.STORAGE_CATEGORY_LIST.map((category: string) => ({
        name: category,
        value: category,
        type: null,
        color: "#eb5757"
      })));
    }
  }, [selectedTabKpisInfo, selectedStorageOption]);

  const getSelectedTimeFrame = (timeFrame: TimeFrame): void => {
    setSelectedTimeFrame(timeFrame);
  };

  const getSelectedStorage = (kpi: KpiListElement): void => {
    dispatch(clearStorageCategoryList());
    setSelectedStorageOption(kpi);
    setStorageOptions(STORAGE_TYPES.filter((el: KpiListElement) => !isEqual(el, kpi)));
    setStorageCategories([]);

    setSelectedStorageCategory({
      name: "Select metric",
      value: "CLEAR",
      type: null,
      color: "#fff"
    });
  };

  const getSelectedCategory = (category: KpiListElement): void => {
    setSelectedStorageCategory(category);

    setStorageCategories(category.value !== "CLEAR" ? [
      {
        name: "Select metric",
        value: "CLEAR",
        type: null,
        color: "#fff"
      },
      // @ts-ignore
      ...selectedTabKpisInfo?.STORAGE_CATEGORY_LIST.map((el: string) => {
        if (el !== category.value) {
          return {
            name: el,
            value: el,
            type: null,
            color: "#eb5757"
          };
        }
      }).filter(Boolean)
    ] :
      // @ts-ignore
      selectedTabKpisInfo?.STORAGE_CATEGORY_LIST.map((el: string) => {
        if (el !== category.value) {
          return {
            name: el,
            value: el,
            type: null,
            color: "#eb5757"
          };
        }
      }).filter(Boolean)
    );
  };

  return (
    <div className={styles.storageOverviewContainer}>
      <div {...handlers}>
        <div className={styles.header} >
          <div className={styles.name}>Storage overview</div>
          <CalendarDropdown getSelectedTimeFrame={getSelectedTimeFrame} getSelectedCustomIntervals={(dates) => setDates(dates)} />
        </div>

        {
          isNil(storageOverview) || isEmpty(storageOverview.storageOverview) ?
            <Box className={styles.loader}>
              <CircularProgress />
            </Box> :
            <div className={styles.overallInfo}>
              <div className={styles.overallPercentageContainer}>
                <div className={styles.overallPercentage}>{getStorageAllValue(storageOverview.storageOverview)}%</div>
                <div className={styles.overallText}>overall storage</div>
              </div>

              {
                !isEmpty(storageOverview.optimization) &&
                <div className={styles.optimizationButton} onClick={() => setShowModal(true)}>
                  <div className={styles.icon} />
                  <div className={styles.text}>
                    {Number(storageOverview.optimization[0].value)}% could be optimized
                  </div>
                  <div className={styles.arrow} />
                </div>
              }
            </div>
        }
      </div>

      <div className={styles.chartContainer}>
        <StorageChart locationId={locationId}
          selectedTimeFrame={selectedTimeFrame}
          storageType={selectedStorageOption}
          storageCategory={selectedStorageCategory}
          dates={dates} />

        <div className={styles.dropdowns}>
          <StorageDropdown chartOptions={storageOptions} selectedOption={selectedStorageOption} getSelectedKpi={getSelectedStorage} />
          <StorageDropdown chartOptions={storageCategories}
            selectedOption={selectedStorageCategory}
            getSelectedKpi={getSelectedCategory}
            disabled={selectedStorageOption.value === StorageType.STORAGE_ALL || isEmpty(storageCategories)} />
        </div>
      </div>

      {showModal && <StorageOptimizationModal closeModal={() => setShowModal(false)} />}
    </div>
  );
};

export default StorageOverviewContainer;