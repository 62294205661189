export default {
	translation: {
		alerts: `Alerts`,
		AlertListByProcessMore: {
			viewMore: `View {{amount}} more alerts`,
		},
		dropdown: {
			pleaseSelect: 'Please select...',
		},
		AddCompanyModal: {
			error: {
				emptyForm: `Please fill out the form`,
				name: `Please enter the company name`,
				apiConstant: `Please enter the API Constant`,
				street: `Please enter the streetname`,
				streetNo: `Please enter the housenumber`,
				zip: `Please enter the zip code`,
				city: `Please enter the city`,
				country: `Please enter the country`,
			},
		},
		AddContractModal: {
			create: 'create limit',
			edit: 'edit limit',
			delete: 'delete limit',
			error: {
				emptyForm: `Please fill out the form`,
				type: `Please select the type of KPI`,
				company: `Please select a company`,
				timeReference: `Please select the timeframe`,
				severityReference: `Please select the severity`,
				minOrMax: `Please enter either a minimal value or a maximum value`,
				locations: `Please select at least one location`,
				notificationAlertGoal: `Please define if this limit is an alert, a notification or a goal`,
			},
		},
		AddLocationModal: {
			edit: 'edit location',
			delete: 'delete location',
			create: 'New location',
			error: {
				emptyForm: `Please fill out the form`,
				company: `Please select a company`,
				name: `Please enter the company name`,
				apiConstant: `Please enter the API Constant`,
				street: `Please enter the streetname`,
				streetNo: `Please enter the housenumber`,
				zip: `Please enter the zip code`,
				city: `Please enter the city`,
				country: `Please enter the country`,
				shiftStart: `Please define the shift start`,
				shiftEnd: `Please define the shift end`,
				weekday: `Please select at least one weekday`,
			},
		},
		StorageOptimizationModal: {
			title: "Storage optimization",
			content: "To implement the storage optimization, please reach out to your sales contact."
		},
		ContractOverviewTab: {
			pageName: 'Limits',
			filterBy: 'Search by kpi...',
			create: 'New limit',
		},
		ContractCard: {
			type: 'KPI',
			company: 'Company',
			time: 'Timeframe',
			severity: 'Level',
			min: 'Min',
			max: 'Max',
			notification: 'Notific.',
			alert: 'alert',
			goal: 'is goal',
			edit: 'edit',
			yes: 'yes',
			no: 'no',
			locations: 'locations',
			markAsGoal: 'mark as goal',
			sendAlertsOrNotifications: 'Send notifications and alerts',
		},
		LocationTableCard: {
			name: 'Name',
			company: 'Company',
			apiConstant: 'API Constant',
			address: 'address',
			street: 'Street',
			streetNo: 'Housenumber',
			zip: 'zip',
			city: 'city',
			country: 'country',
			shift: 'shift',
			shiftOn: 'Weekdays',
			edit: 'edit',
			shiftStart: 'shiftstart',
			shiftEnd: 'shiftend',
		},
		LocationOverviewTab: {
			pageName: 'Locations',
			filterBy: 'Search by location name...',
			create: 'New location',
		},
		CompanyOverviewTab: {
			pageName: 'Companies',
			filterBy: 'Search by company name...',
			create: 'Create new company',
		},
		ConfirmModal: {
			areYouSure: 'Are you sure?',
			confirm: 'Confirm',
			cancel: 'Cancel',
		},
		CompanyCard: {
			name: 'Name',
			apiConstant: 'API Constant',
			street: 'Street',
			streetNo: 'Housenumber',
			zip: 'Zip',
			city: 'City',
			country: 'Country',
			taxId: 'vat',
			locations: 'locations',
			edit: 'edit company',
			delete: 'delete company',
			create: 'Create new company',
		},
		AddUserModal: {
			error: {
				firstNameEmpty: `Please set a firstname`,
				lastNameEmpty: `Please set a lastname`,
				incorrectEmail: `The entered email address is not a valid email address`,
				emptyForm: `Please fill the form`,
				roleEmpty: `Please select a role for this user`,
				locationEmpty: `Please select at least one location`,
				company: "Company"
			},
			label: {
				firstName: 'firstname',
				lastName: 'lastname',
				email: 'E-Mail',
				role: 'Role',
				locations: 'Location',
				sendAlert: 'Send alerts',
			},
			delete: 'Delete user',
		},
		userOverviewTab: {
			pageName: 'User',
			name: 'Name',
			email: 'E-Mail',
			company: 'Company',
			role: 'Role',
			edit: 'Edit user',
			create: 'Create new user',
			filterBy: 'Search for user or email',
			locations: 'Locations',
		},
		alertsOverviewTab: {
			pageName: 'Alerts',
			title: 'Title',
			value: 'Value',
			timestamp: 'Created',
			process: 'Process',
			markAsResolve: 'Confirm',
			confirmed: 'Confirmed',
			no: 'No',
			filterBy: 'Search for title or description',
		},
		delete: 'delete User',
		DELETE: 'Delete Selection',
		ARCHIVE: 'Archive Selection',
		RESTORE: 'Restore Selection',
		ASSIGN_COURSE: 'Assign to course',
		ASSIGN_GROUP: 'Assign to group',
		callBack: 'Request callback',
		withExampleData: 'Start with example data',
		withoutExampleData: 'Start without example data',
		removeExampleData: 'Remove example data',
		notRemoveExampleData: 'Keep example data',
		editProfile: {
			myProfile: 'My Profile',
			profileInfoTab: 'Personal Information',
			changePasswordTab: 'Change Password',
			firstNamePlaceholder: 'Alexander',
			secondNamePlaceholder: 'Alexander',
			firstName: 'First name',
			lastName: 'Last name',
			email: 'E-mail address',
			oldPassword: 'Current password',
			newPassword: 'New password',
			newPasswordConfirm: 'Confirm new password',
			cancel: 'Abbrechen',
			save: 'Save changes',
			delete: 'Delete account',
			alreadyExists: 'E-mail address already exists.',

			passwordError: {
				1: 'Please enter your current password',
				2: "New Passwords don't match.",
				3: 'Password has not been changed: The current password is not valid.',
			},
		},
		authentication: {
			header: 'Welcome to PreScriVa',
			subhead: '',
			name: 'Your name',
			email: 'Email',
			password: 'Password',
			repeatPassword: 'Repeat Password',
			forgottenPassword: 'Forgotten Password?',
			alreadyHaveAccount: 'Already have account?',
			stillDontHaveAccount: 'Still dont have account?',
			logIn: 'Log in',
			register: {
				header: 'Sign Up',
				cta: 'Register',
				firstname: 'Firstname',
				lastname: 'Lastname',
				email: 'E-Mail',
				institution: 'Institution',
				password: 'Password',
				passwordCompare: 'Repeat Password',
				errors: {
					firstname: 'Firstname',
					lastname: 'Lastname',
					email: 'E-Mail',
					institution: 'Institution',
					password: 'Password',
					passwordCompare: 'Repeat Password',
				},
			},
		},
		hello: 'Hello',
		headerTitle: 'SmartoMeter',
		firstName: 'First Name',
		name: 'Name',
		myProfile: 'My Profile',
		addStudent: 'Add Student',
		//navbar
		overview: 'Overview',
		qualifications: 'Qualifications',
		student: 'Student',
		autorentool: 'Authoring tool',
		loginHeader: 'Login',
		password: 'Password',
		forgetPassword: 'Forgot Password?',
		login: 'Login',
		resetPassword: 'Reset Password',
		reset: 'Reset',
		goToLogin: 'Go to Login',
		logout: 'Logout',
		formError: 'Form Error',
		lastName: 'Surname',
		email: 'E-Mail',
		close: 'Close',
		save: 'Save',
		cancel: 'Cancel',
		users: {
			assignments: {
				headline: 'Course assignments overview',
				createNewButton: 'New course assignment',
			},
			headline: 'User',
			users: {
				headline: 'Usersoverview',
				createNewButton: 'New user',
				importButton: 'Bulk Import',
				assignments: {
					ALL: 'All',
					WITH_ASSIGNMENT: 'Only with assignment',
					WITHOUT_ASSIGNMENT: 'Only without assignment',
				},
				edit: {
					headline: 'Edit User',
				},
				create: {
					error: {
						alreadyTaken: 'This user already exists',
						alreadyTakenOtherInstitution:
							'This user already exists in another institution',
					},
				},
				bulkCreate: {
					step1: {
						headline: 'Import all users',
						placeholder: 'select .csv file',
						or: 'or',
						exampleDownload: 'download CSV example file',
						startImportButtonText: 'Import file',
					},
					step2: {
						importing: 'File will be imported',
						success: {
							message: 'File imported',
						},
						error: {
							headline:
								'The file could not be imported successfully',
							duplicates:
								'{{count}} user(s) could not be created, because their email is already in the database. {{imported}} user(s) has been created successfully.',
							malformatted:
								'The format of the uploaded file is incorrect. Please use the template.',
							error:
								'An error occurred. Please contact the support. ',
							tryAgain: 'Try again',
							generalMessage:
								'The file could not be imported completely',
							absoluteMessage: 'The file could not be imported',
						},
					},
				},
			},
			groups: {
				headline: 'Groupsoverview',
				createNewButton: 'New Group',
				detail: {
					deadline: {
						ALL: 'All',
						MISSED: 'Deadline missed',
						NOT_MISSED: 'Deadline not missed',
					},
				},
			},
		},
		settings: {
			headline: 'Settings',
			header: {
				general: 'General',
				presentation: 'Presentation',
				team: 'Team',
				certificate: 'Certificates',
				invoice: 'Invoices',
			},
			team: {
				members: {
					headline: 'Teammembers',
					create: {
						headline: 'New Teammember',
					},
				},
				groups: {
					headline: 'Groups',
				},
				roles: {
					headline: 'Roles',
					deleteMessage:
						'Are you sure, that you want to delete the role {{name}}?',
					createNewButton: 'New Role',
					modal: {
						headline: 'Define a new Role',
						namePlaceholder: 'Neue Rolle eingeben',
					},
					PERMISSION: {
						FE_SOM_DASHBOARD: 'Access to the Dashboard',
						FE_SOM_USERS: 'Access to the Usermanagement',
						FE_SOM_COURSES:
							'Access to the Coursemanagement and Authoring Tool',
						FE_SOM_SETTINGS: 'Access to the Settings',
						NOT_EDITABLE: '(not editable)',
					},
				},
			},
			general: {
				domain: 'domain',
				name: 'Firmenname',
				street: 'Straße',
				number: 'Nr.',
				post_code: 'PLZ',
				city: 'Ort',
				country: 'Land',
				vat_id: 'Ust-ID',
				invoice_street: 'Straße',
				invoice_number: 'Nr.',
				invoice_post_code: 'PLZ',
				invoice_city: 'Ort',
				first_name: 'Vorname',
				last_name: 'Name',
				email: 'E-Mail',
				phone: 'Telefonnummer',
			},
			contractValues: {
				headline: 'Contract Values',
				pageTitle: 'Contracts',
				createNewButton: 'New contract',
				company: 'Company',
				locations: 'Locations',
				kpi: 'KPI',
				min: 'Min',
				max: 'Max',
				severity: 'Severity',
				timeReference: 'Time Reference',
				notification: 'Notification',
				alert: 'Alert',
				goal: 'Goal',
				modalTitle: 'Create contract',
				submit: 'Submit',
				noData: 'No contracts',
				deleteContractTitle: 'Delete contract',
				deleteContractBody:
					'Are you sure that you want to delete this contract?',
				editContractTitle: 'Edit selected contract',
			},
		},
		perPage: 'per Page',
		from: 'of',
		moreThan: 'more than',
		noInternetConnetion: 'No internet connection'
	},
};
