export enum userFunctions {
    CREATE = "CREATE",
    EDIT = "EDIT",
    DELETE = "DELETE"
}

export enum deleteTypes {
    DELETE = "DELETE",
    REMOVE = "REMOVE"
}

export enum UserRole {
    SUPER = "SUPER",
    ADMIN = "ADMIN",
    USER = "USER"
}

export type UserType = {
    sendAlert?: boolean;
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: string;
    locations?: number[],
    company?: {
        id?: number
    }
}