export enum UsersErrors {
  DUPLICATES = "DUPLICATES",
  MALFORMATTED = "MALFORMATTED"
}


export enum UsersBulkExample {
  template = `firstName;lastName;email
Max;Mustermann;max.mustermann@musterfirma.de
Maria;Mustermann;maria.mustermann@musterfirma.de
`,
  fileName = `example.csv`
};
