import * as React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';
import useUserContext from '../contexts/UserContext/UserContext';
import { UserRole } from '../enums/users/users-enums';

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    allowedRoles: UserRole[];
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { user } = useUserContext();
    const { allowedRoles, component: Component, ...rest } = props;

    return (
        <>
            {
                allowedRoles && user && user.role ?
                <Route
                    {...rest}
                    render={(routeProps) => {
                        return allowedRoles.includes(user.role) ?
                            <Component {...routeProps} /> :
                            <Redirect to={{ pathname: '/dashboard', state: { from: routeProps.location } }} />
                    }
                    }
                /> :  <Route
                    {...rest}
                    render={(routeProps) => {
                        return <Component {...routeProps} />
                    }
                    }
                  />
            }
        </>
    );
};

export default PrivateRoute;
