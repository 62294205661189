import { Box, CircularProgress } from "@material-ui/core";
import { isNil } from "lodash";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { SwipeableHandlers } from "react-swipeable";
import { useSwipe } from "../../../hooks/swipeHook";
import { ALERTS_PER_TAB, ProcessesEnums } from "../../../enums/processes-enums";
import { alertSelector } from "../../../store/alerts/alertSlice";
import { AlertListByProcessElement } from "../AlertListByProcessElement/AlertListByProcessElement";
import { AlertListByProcessMore } from "../AlertListByProcessMore/AlertListByProcessMore";
import { AlertListHeader } from "../AlertListHeader/AlertListHeader";
import styles from "./AlertListByProcess.module.scss";

interface AlertListByProcessProps {
  process?: ProcessesEnums | null;
  locationId?: string,
  alertsData?: any;
}

export const AlertListByProcess: FunctionComponent<AlertListByProcessProps> = ({ alertsData, locationId, process }) => {
  const handlers: SwipeableHandlers | null = useSwipe();
  const { isLoading } = useSelector(alertSelector);

  return (
    <>
      {
        !!alertsData?.data.length || isLoading ?
          <div className={styles.alertList} {...handlers}>
            {
              isNil(alertsData?.data) ?
                <Box className={styles.loader}>
                  <CircularProgress />
                </Box> :
                <>
                  <AlertListHeader embedded={true} amount={alertsData?.total} />
                  {
                    alertsData?.data.filter((alert: any, index: number) => index < 3).map((alert: any) => {
                      return <AlertListByProcessElement alert={alert} isLoading={isLoading} />;
                    })
                  }
                  {alertsData?.total > ALERTS_PER_TAB && <AlertListByProcessMore amount={alertsData.total - ALERTS_PER_TAB} />}
                </>
            }
          </div> : <></>
      }
    </>
  );
};