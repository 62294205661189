import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { intersection, isEmpty, isNil } from "lodash";
import { UserContext } from "../../../contexts/UserContext/UserContext";
import { UserRole, UserType } from "../../../enums/users/users-enums";
import { translate } from "../../../i18n/i18n";
import styles from "./UserCard.module.scss";

interface UserCardProps {
  user?: any,
  index?: number,
  header?: boolean,
  handleEdit?: any;
}

export const UserCard: FunctionComponent<UserCardProps> = ({ user, index, header, handleEdit }) => {
  const edit = (user: UserType) => {
    const editUser: UserType = {
      ...user,
      locations: user?.locations?.map((location: any) => location.id)
    };

    handleEdit(editUser);
  };

  return <div key={`user-card-${index}`}>
    {
      header && !index ?
        (
          <div className={`${styles.wrapper} ${styles.header}`}>
            <div className={styles.header}>{translate(`userOverviewTab.name`)}</div>

            <div className={styles.value}>{translate(`userOverviewTab.email`)}</div>

            <div className={styles.attribute}>
              <span className={styles.attributeName}>{translate(`userOverviewTab.company`)}</span>
            </div>

            <div className={styles.attribute}>
              <span className={styles.attributeName}>{translate(`userOverviewTab.locations`)}</span>
            </div>

            <div className={styles.attribute}>
              <span className={styles.attributeName}>{translate(`userOverviewTab.role`)}</span>
            </div>

            <div className={styles.attribute}>
              <span className={styles.attributeName}></span>
            </div>
          </div>
        ) : (
          <div key={index} className={`${styles.wrapper} ${styles.body}`}>
            <div className={styles.header}>{user.firstName} {user.lastName}</div>

            <div className={styles.value}>{user.email}</div>

            <div className={styles.attribute}>
              <span className={styles.attributeName}>{translate(`userOverviewTab.company`)}</span>
              <span className={styles.attributeValue}>{user.company.name}</span>
            </div>

            <div className={styles.attribute}>
              <span className={styles.attributeName}>{translate(`userOverviewTab.locations`)}</span>
              <span className={styles.attributeValue}>{user.locations.map((location: any, index: number) => <span
                className={styles.location} key={index}>{location.name}</span>)}</span>
            </div>

            <div className={styles.attribute}>
              <span className={styles.attributeName}>{translate(`userOverviewTab.role`)}</span>
              <span className={styles.attributeValue}>{user.role}</span>
            </div>

            <button className={styles.confirmButton} onClick={() => edit(user)}>
              {translate(`userOverviewTab.edit`)}
            </button>
          </div>
        )}
  </div>;

};