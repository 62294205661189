import { makeStyles, Theme } from '@material-ui/core/styles';
import { color } from '../../utils/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#fff',
		padding: '40px 50px 50px',
		borderRadius: '6px',
		boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.05)',
	},
	Logo: { marginBottom: 35, height: 70 },
	submit: {
		margin: theme.spacing(3, 0, 2),
		color: '#fff',
		background: color.link,
		height: '50px',
		borderRadius: 12,
		fontSize: 16,
		fontWeight: 400,
	},
	root: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		background: '#d9d9d9',
	},
	link: {
		color: color.link,
	},
	paragraph: {
		color: color.textGrey,
		textAlign: 'center',
		fontSize: '16px',
	},
	title: {
		color: color.textDark,
		marginBottom: 16,
		fontSize: '40px',
	},
	subTitle: {
		color: color.textDark,
		marginBottom: 16,
	},
	ForgotPasswordLink: {
		fontSize: '16px',
		color: '#11243B',
		textDecoration: 'underline',
		'&:hover': {
			color: '#2FC07D',
		},
	},
	Input: {
		margin: '5px 0 20px !important',
		width: '100%',
		height: 40,
		border: '2px solid #CED9E1 !important',
		lineHeight: '36px !important',
		fontSize: '16px !important',
		borderRadius: '4px !important',
		padding: '0 12px !important',

		'&:focus': {
			border: '2px solid #2FC07D !important',
		},
	},
	Label: {
		fontSize: '16px',
		color: '#11243b',
	},
	SubmitButton: {
		backgroundColor: '#2FC07D',
		borderRadius: 4,
		height: 50,
		lineHeight: '50px',
		color: '#fff',
		fontWeight: 700,
		appearance: 'none',
		'-webkit-appearance': 'none',
		width: '100%',
		fontSize: 18,
		cursor: 'pointer',
		border: 'none',
		transition: '.2s',

		'&:hover': {
			backgroundColor: '#507EE3',
		},

		'&:disabled': {
			background: ' #d9d9d9 !important',
			cursor: 'not-allowed',
		},
	},
	ForgotPasswordWrapper: {
		width: '100%',
		textAlign: 'center',
		marginTop: 30,
	},
	ErrorMsg: {
		fontSize: 14,
		marginBottom: 10,
		marginTop: -15,
		textAlign: 'center',
		color: '#EB5757',
	},
}));
