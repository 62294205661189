
export enum ComparisonOptions {
  PLEASE_SELECT = "PLEASE_SELECT",
  AVERAGE = "AVERAGE",
  PREVIOUS_TIMEFRAME = "PREVIOUS_TIMEFRAME"
}
export interface IComparisonOption {
  name: string;
  value: ComparisonOptions;
};

export const COMPARISON_OPTIONS: IComparisonOption[] = [
  {
    name: "Please select",
    value: ComparisonOptions.PLEASE_SELECT
  },
  {
    name: "Average",
    value: ComparisonOptions.AVERAGE
  },
  {
    name: "Previous Timeframe",
    value: ComparisonOptions.PREVIOUS_TIMEFRAME
  }
];