import { makeStyles } from '@material-ui/core/styles';
import { THEMES } from '../../../enums/theme.enum';
import { color, borderRadius } from '../../../utils/styles';

export const useStyles = (selectedTheme: THEMES) => makeStyles(theme => {

    return ({
        Card: {
            backgroundColor: selectedTheme === THEMES.LIGHT ? "#fff" : "#344355",
            color: selectedTheme === THEMES.LIGHT ? "#344355" : "#fff",
            borderRadius: borderRadius.small,
            // border: `1px solid ${color.borderGrey}`,
            boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%)",
            width: "100%",
            padding: "16px !important",
            position: "relative"
        },
        CardTiny: {
            padding: "20px 32px !important",
        },
        CardSmall: {
            padding: "20px 32px !important",
        },
        CardBright: {
            backgroundColor: color.bgGrey,
            color: color.white,
        },
        CardHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "32px"
        },
        CardHeadline: {
            fontFamily: "Nunito, Open Sans, sans-serif",
            fontSize: "38px",
            display: "inline-block",
            fontWeight: 600,
            lineHeight: "38px",
            marginBottom: "20px"
        }
    });
});
