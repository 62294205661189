import { PushNotifications, PushNotificationSchema, Token } from "@capacitor/push-notifications";
import { getPlatforms } from "@ionic/react";
import { CssBaseline } from "@material-ui/core";
import { isNil, isNull } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import "./App.scss";
import NoInternetConnection from "./components/NoInternetConnection/NoInternetConnection";
import CustomNotification from "./components/shared/CustomNotification";
import LocationContextProvider from "./contexts/LocationContext/LocationProvider";
import PageContextProvider from "./contexts/UserContext/UserProvider";
import { THEMES } from "./enums/theme.enum";
import PrivateRoute from "./helpers/PrivateRoute";
import { useWindowSize } from "./hooks/windowSizeHook";
import routes from "./routes";
import { addNotification, addToken, saveNotificationToken } from "./store/notifications/notificationSlice";
import { setTheme, windowDataSelector } from "./store/window/windowDataSlice";


const App = () => {
  const dispatch = useDispatch();
  const classes = useWindowSize();

  const [isOnline, setIsOnline] = useState<boolean>(true);
  const { theme } = useSelector(windowDataSelector);
  let channel: BroadcastChannel | null = null;
  let onAddNotification: any | null = null;

  useEffect(() => {
    // Firebase notifications
    if (getPlatforms().includes("capacitor") && getPlatforms().includes("android")) {
      PushNotifications.checkPermissions().then((res: any) => {
        if (res.receive !== "granted") {
          PushNotifications.requestPermissions().then((res: any) => {
            if (res.receive === "denied") {
              console.log("Push Notification permission denied");
            } else {
              registerAndroidNotification();
            }
          });
        } else {
          registerAndroidNotification();
        }
      });
    }
    // else {
    //   initializeFirebase();
    //   askForPermissioToReceiveNotifications()
    //     .then((token: string | undefined) => {
    //       const lsToken: string | null = localStorage.getItem("notificationToken");
    //       if (isNil(lsToken) || !isEqual(lsToken, token)) {
    //         dispatch(addToken(token));
    //
    //         dispatch({
    //           ...saveNotificationToken({
    //             requestType: "POST",
    //             request: "/notification-tokens",
    //             body: { token }
    //           }),
    //         });
    //       }
    //     });
    //
    //   channel = new BroadcastChannel("firebase");
    //   onAddNotification = (e: any): void => {
    //     const lsToken: string | null = localStorage.getItem("notificationToken");
    //     if (lsToken) {
    //       dispatch(addNotification(e.data));
    //     }
    //   };
    //   channel.addEventListener("message", onAddNotification);
    // }

    // Theme
    const theme: string | null = localStorage.getItem("theme");
    if (isNull(theme)) {
      localStorage.setItem("theme", THEMES.LIGHT);
    } else {
      dispatch(setTheme(theme));
    }

    // Checks internet connectivity
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      if (!isNil(channel)) {
        channel.removeEventListener("message", onAddNotification);
      }
    };
  }, []);

  const registerAndroidNotification = (): void => {
    PushNotifications.register();
    PushNotifications.addListener("registration", (token: Token) => {
        dispatch(addToken(token.value));

        dispatch({
          ...saveNotificationToken({
            requestType: "POST",
            request: "/notification-tokens",
            body: { token: token.value }
          })
        });
      }
    );

    PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
        dispatch(addNotification(notification.data));
      }
    );
  };
  console.log(classes, theme, isOnline);
  return useMemo(() => (
    <>
      <div className={`app ${theme}-theme ${classes}`}>
        <CssBaseline />
        {classes && isOnline &&
        <LocationContextProvider>
          <PageContextProvider>
            <BrowserRouter basename={process.env.REACT_APP_BASENAME || ""}>
              <Switch>
                {routes.map((route, index) => {
                  return (
                    <PrivateRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      allowedRoles={route.allowedRoles}
                      component={(props: object) => {

                        return (
                          <route.layout {...props}>
                            {route.component ? <route.component {...props} /> : null}
                          </route.layout>
                        );
                      }}
                    />
                  );
                })}
                <Redirect to="/dashboard" />
              </Switch>
            </BrowserRouter>
          </PageContextProvider>
        </LocationContextProvider>}

        {!isOnline && <NoInternetConnection />}
      </div>

      <CustomNotification />
    </>
  ), [classes, theme, isOnline]);
};

export default App;
