import React, { FunctionComponent, useEffect, useState } from "react";
import http from "../../../app/axios";
import { useAlerts } from "../../../hooks/alertHook";
import { StorageType } from "../../../enums/kpi.enum";
import { ProcessesEnums } from "../../../enums/processes-enums";
import { IStorageBarChart } from "../../../interfaces/chart.interface";
import { AlertListByProcess } from "../../shared/AlertListByProcess/AlertListByProcess";
import BarChart from "../../shared/KpiGraphs/BarChart/BarChart";
import StorageOverviewContainer from "../../shared/StorageOverviewContainer/StorageOverviewContainer";
import styles from "./LocationTabsStorage.module.scss";

interface LocationTabsStorageProps {
  locationId: string;
}

const LocationTabsStorage: FunctionComponent<LocationTabsStorageProps> = ({ locationId }) => {
  const [storageABC, setStorageABC] = useState<IStorageBarChart[]>([]);
  const [storageZone, setStorageZone] = useState<IStorageBarChart[]>([]);
  const { alertsData } = useAlerts(locationId, ProcessesEnums.STORAGE);

  useEffect(() => {
    const getStorageData = async () => {
      const contractValue = await http.get(`kpi/storageByTypeAndCategory?location=${locationId}`).then((res: any) => res.data);
      for (const storage of contractValue) {
        if (storage.type === StorageType.STORAGE_ABC) {
          setStorageABC((prevState: IStorageBarChart[]) => [...prevState, storage]);
        } else {
          setStorageZone((prevState: IStorageBarChart[]) => [...prevState, storage]);
        }
      }
    };

    getStorageData();
  }, [locationId]);

  return (
    <div className={styles.storageTab}>
      <div className={styles.onlyMobile}>
        <AlertListByProcess alertsData={alertsData} locationId={locationId} />
      </div>

      <div className={styles.overview}>
        <StorageOverviewContainer locationId={locationId} />
      </div>

      <div className={styles.chart}>
        <BarChart chartData={storageABC} type={StorageType.STORAGE_ABC} />
      </div>

      <div className={styles.chart}>
        <BarChart chartData={storageZone} type={StorageType.STORAGE_ZONE} />
      </div>
    </div>
  );
};

export default LocationTabsStorage;