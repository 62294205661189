import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "semantic-ui-react";
import useUserContext from "../../../contexts/UserContext/UserContext";
import { CompanyType } from "../../../enums/companies-enums";
import { THEMES } from "../../../enums/theme.enum";
import { translate } from "../../../i18n/i18n";
import { addCompany, deleteCompany } from "../../../store/companies/companySlice";
import { userSelector } from "../../../store/users/userSlice";
import { windowDataSelector } from "../../../store/window/windowDataSlice";
import { Input } from "../../shared/Input/Input";
import { ModalButtonWrapper } from "../../shared/ModalButtonWrapper/ModalButtonWrapper";
import { ModalCancelButton } from "../../shared/ModalCancelButton/ModalCancelButton";
import { ModalCreateButton } from "../../shared/ModalConfirmButton/ModalCreateButton";
import { ModalWrapper } from "../../shared/ModalWrapper/ModalWrapper";
import BaseModal, { validateNotEmpty } from "../BaseModal/BaseModal";
import ConfirmModal, { useConfirmModal } from "../ConfirmModal/ConfirmModal";

interface AddCompanyModalProps {
  initialCompany?: CompanyType,
  closeModal: () => void
}


const AddCompanyModal: React.FunctionComponent<AddCompanyModalProps> = ({ closeModal, initialCompany }) => {
  const dispatch = useDispatch();
  const { theme } = useSelector(windowDataSelector);
  const { confirm, closeConfirm, getConfirmation } = useConfirmModal();
  const [error, setError] = useState<string | null>();
  const [company, setCompany] = useState<CompanyType | undefined>(initialCompany);

  const handleChange = (e: React.ChangeEvent<any>) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value
    });
  };


  const validate = (company: any) => {

    if (!company) {
      setError(`AddCompanyModal.error.emptyForm`);
      return false;
    }

    if (!validateNotEmpty(company.name)) {
      setError(`AddCompanyModal.error.name`);
      return false;
    }

    if (!validateNotEmpty(company.apiConstant)) {
      setError(`AddCompanyModal.error.apiConstant`);
      return false;
    }

    if (!validateNotEmpty(company.street)) {
      setError(`AddCompanyModal.error.street`);
      return false;
    }

    if (!validateNotEmpty(company.streetNo)) {
      setError(`AddCompanyModal.error.streetNo`);
      return false;
    }

    if (!validateNotEmpty(company.zip)) {
      setError(`AddCompanyModal.error.zip`);
      return false;
    }

    if (!validateNotEmpty(company.city)) {
      setError(`AddCompanyModal.error.city`);
      return false;
    }

    if (!validateNotEmpty(company.country)) {
      setError(`AddCompanyModal.error.country`);
      return false;
    }

    return true;
  };

  const onSubmit = (): void => {

    if (initialCompany && validate(company)) {
      dispatch({
        ...addCompany({
          requestType: "PATCH",
          body: company,
          request: `/company/${initialCompany.id}`
        })
      });
      closeModal();
    } else if (validate(company)) {
      dispatch({
        ...addCompany({
          requestType: "POST",
          body: company,
          request: `/company`
        })
      });
      closeModal();
    }
  };

  const onDelete = (): void => {
    if (initialCompany) {
      dispatch({
        ...deleteCompany({
          requestType: "DELETE",
          request: `/company/${initialCompany.id}`
        })
      });
      closeModal();
      closeConfirm();
    }
  };

  return <BaseModal closeModal={closeModal}
                    title={initialCompany ? translate(`CompanyCard.edit`) : translate(`CompanyCard.create`)}>
    <ModalWrapper>
      <Input value={company?.name} name={"name"} label={translate(`CompanyCard.name`)}
             handleChange={handleChange} />
      <Input value={company?.apiConstant} name={"apiConstant"} label={translate(`CompanyCard.apiConstant`)}
             handleChange={handleChange} />
      <Input value={company?.street} name={"street"} label={translate(`CompanyCard.street`)}
             handleChange={handleChange} />
      <Input value={company?.streetNo} name={"streetNo"} label={translate(`CompanyCard.streetNo`)}
             handleChange={handleChange} />
      <Input value={company?.zip} name={"zip"} label={translate(`CompanyCard.zip`)}
             handleChange={handleChange} />
      <Input value={company?.city} name={"city"} label={translate(`CompanyCard.city`)}
             handleChange={handleChange} />
      <Input value={company?.country} name={"country"} label={translate(`CompanyCard.country`)}
             handleChange={handleChange} />
      <Input value={company?.taxId} name={"taxId"} label={translate(`CompanyCard.taxId`)}
             handleChange={handleChange} />
    </ModalWrapper>
    <ul >
      {company?.locations?.map((location: any, index: number) => <li key={index} style={{ color: `${theme === THEMES.LIGHT ? "#11243b" : "#fff"}` }}>{location.name}</li>)}
    </ul>

    {error && (<Message error content={translate(error)} />)}
    <ModalButtonWrapper>
      {initialCompany &&
      <ModalCancelButton getConfirmation={getConfirmation}>{translate(`CompanyCard.delete`)}</ModalCancelButton>}
      <ModalCreateButton onSubmit={onSubmit}>{initialCompany ? translate(`CompanyCard.edit`) : translate(`CompanyCard.create`)}</ModalCreateButton>

    </ModalButtonWrapper>
    {initialCompany && confirm && <ConfirmModal closeModal={closeConfirm} title={``} onConfirm={onDelete} />}
  </BaseModal>;
};

export default AddCompanyModal;
