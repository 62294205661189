import React, { FunctionComponent } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles";

interface LoaderProps { }

const Loader: FunctionComponent<LoaderProps> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.Loader}>
      <CircularProgress />
    </Box>
  );
}

export default Loader;