import React, { FunctionComponent, useState } from "react";
import { translate } from "../../../i18n/i18n";

import styles from "./PerPageDropdown.module.scss";

interface PerPageDropdownProps {
  value: number | string | undefined,
  options: number[] | string[],
  uom?: string,
  valueText?: string,
  label?: string,
  handleChange: (value: number | string) => void
}

export const PerPageDropdown: FunctionComponent<PerPageDropdownProps> = ({ value, handleChange, options, valueText, uom, label }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onElementClick = (value: number | string): void => {
    handleChange(value);
    setIsOpen((prevState: boolean) => !prevState);
  };

  return <>
  {label && <div className={styles.label}>{label}</div>}
    <div className={`${styles.dropdown} ${isOpen ? styles.opened : ""}`}>
      <div className={styles.selected} onClick={() => setIsOpen((prevState: boolean) => !prevState)}>
        {valueText || value && `Show ${value} results per page` || translate(`dropdown.pleaseSelect`)}
        <div className={styles.arrow} />
      </div>
      <div className={styles.list}>
        {
          options.map((value: number | string, index: number) => {

            return (
              <div key={index} className={styles.value} onClick={() => onElementClick(value)}>
                <div className={styles.valueContainer}>
                  <div className={styles.valueName}>{value} {uom}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  </>;
};