import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Chart from "react-apexcharts";
import { scale } from "chroma-js";
import { isEmpty, isNil } from "lodash";
import { getAlertsCount, getBarValue, getStorageValueStyle } from "../../../helpers/locationHelper";
import { UrlParams } from "../../../interfaces/url-params.interface";
import Loader from "../Loader";
import styles from "./LocationsDropdown.module.scss";

interface LocationsDropdownProps {
  locations: any[];
}

const LocationsDropdown: FunctionComponent<LocationsDropdownProps> = ({ locations }) => {
  const history = useHistory();
  const { id: locationId, menuItem } = useParams<UrlParams>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [locationsList, setLocationsList] = useState<{ selected: any, dropdownList: any; }>({
    selected: null,
    dropdownList: []
  });
  const { selected, dropdownList } = locationsList;
  const radialBarOptions: any = {
    chart: {
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "10"
        },
      }
    },
    fill: {
      colors: [(options: any) => {
        const { value } = options;
        const getColor: any = scale(["#507ee3", "#2fc07d"]).domain([0, 100]);

        return getColor(value).hex();
      }]
    },
    stroke: {
      width: 2,
    }
  };

  useEffect(() => {
    const selectedLocation: any = locations.find((location: any) => location.id == locationId);

    setLocationsList({
      selected: selectedLocation,
      dropdownList: locations.filter((location: any) => location.id !== selectedLocation.id)
    });
  }, []);

  const onLocationClick = (location: any, elIndex: number): void => {
    history.push(`/location/${location.id}/${menuItem}`);

    setIsOpen((prevState: boolean) => !prevState);
    setLocationsList((prevState: any) => {
      const previousState: any[] = [...prevState.dropdownList];
      previousState.splice(elIndex, 1);

      return {
        selected: location,
        dropdownList: [...previousState, prevState.selected]
      };
    });
  };

  return (
    <>
      {
        isNil(selected) || isEmpty(dropdownList) ? <Loader /> :
          <div className={`${styles.dropdownContainer} ${isOpen ? styles.opened : ""}`}>
            <div className={styles.selectedLocation} onClick={() => setIsOpen((prevState: boolean) => !prevState)}>
              <div className={`${styles.name} ${selected.id == locationId && isOpen ? styles.active : ""}`}>{selected.name}</div>

              <div className={styles.info}>
                <Chart className={styles.radialChart} 
                       type="radialBar" 
                       options={radialBarOptions} 
                       series={[
                        (selected.goals.outbound.totalAmount / selected.goals.outbound.goalAmount * 100).toFixed(),
                        (selected.goals.inbound.totalAmount / selected.goals.inbound.goalAmount * 100).toFixed()
                        ]} 
                       width="100" />
                <div className={styles.barChart} style={getStorageValueStyle(getBarValue(selected))} />
                <div className={`${styles.alerts} ${getAlertsCount(selected) === 0 ? styles.noAlerts : ""}`}>{getAlertsCount(selected)}</div>
              </div>

              <div className={styles.arrow} />
            </div>

            <div className={styles.locationsList}>
              {
                dropdownList.map((location: any, index: number) => {
                  const barValue: number = Number(location.storageAll.ofValue) === Number(location.storageAll.value) ? 100 :
                    Math.floor((Number(location.storageAll.ofValue) - (Number(location.storageAll.value))) / Number(location.storageAll.ofValue) * 100);

                  return (
                    <div key={index} className={styles.dropdownLocation} onClick={() => onLocationClick(location, index)}>
                      <div className={`${styles.name} ${location.id == locationId && isOpen ? styles.active : ""}`}>{location.name}</div>

                      <div className={styles.info}>
                        <Chart className={styles.radialChart} 
                               type="radialBar" 
                               options={radialBarOptions} 
                               series={[
                                 (location.goals.outbound.totalAmount / location.goals.outbound.goalAmount * 100).toFixed(),
                                 (location.goals.inbound.totalAmount / location.goals.inbound.goalAmount * 100).toFixed()
                               ]}
                               width="100" />
                        <div className={styles.barChart} style={getStorageValueStyle(barValue)} />
                        <div className={`${styles.alerts} ${getAlertsCount(location) === 0 ? styles.noAlerts : ""}`}>{getAlertsCount(location)}</div>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
      }

      {isOpen && <div className={styles.overlay} onClick={() => setIsOpen(false)} />}
    </>
  );
};

export default LocationsDropdown;