import React, { CSSProperties, FunctionComponent, useState } from "react";
import { SwipeableHandlers } from "react-swipeable";
import { useSwipe } from "../../../../hooks/swipeHook";
import { KpiListElement } from "../../../../interfaces/kpi.interface";
import styles from "./StorageDropdown.module.scss";

interface StorageDropdownProps {
  chartOptions: KpiListElement[];
  selectedOption: KpiListElement;
  getSelectedKpi: (kpi: KpiListElement) => void;
  disabled?: boolean;
}

const StorageDropdown: FunctionComponent<StorageDropdownProps> = ({ chartOptions, selectedOption, getSelectedKpi, disabled }) => {
  const handlers: SwipeableHandlers | null = useSwipe();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedStyle = { "--bg-color": selectedOption.color } as CSSProperties;

  const onElementClick = (kpi: KpiListElement): void => {
    getSelectedKpi(kpi);
    setIsOpen((prevState: boolean) => !prevState);
  };

  return (
    <>
      <div className={`${styles.storageDropdownContainer} ${isOpen ? styles.opened : ""} ${disabled ? styles.disabled : ""}`} {...handlers}>
        <div className={styles.selectedKpi} onClick={() => !disabled && setIsOpen((prevState: boolean) => !prevState)}>
          <div className={styles.kpiContainer}>
            <div className={`${styles.kpiColor} ${selectedOption.name === "Select metric" ? styles.default : ""}`} style={selectedStyle} />
            <div className={styles.kpiName}>{selectedOption.name}</div>
          </div>

          <div className={styles.arrow} />
        </div>

        {
          !disabled && <div className={styles.kpiList}>
            {
              chartOptions.map((kpi: KpiListElement, index: number) => {
                const style = { "--bg-color": kpi.color } as CSSProperties;

                return (
                  <div key={index} className={styles.dropdownKpi} onClick={() => onElementClick(kpi)}>
                    <div className={styles.kpiContainer}>
                      <div className={`${styles.kpiColor} ${kpi.name === "Select metric" ? styles.default : ""}`} style={style} />
                      <div className={styles.kpiName}>{kpi.name}</div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        }

      </div>

      {isOpen && <div className={styles.overlay} onClick={() => setIsOpen(false)} />}
    </>
  );
};

export default StorageDropdown;