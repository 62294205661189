import React from "react";
import UserOverviewTab from "../../components/tabs/UserOverviewTab/UserOverviewTab";

export default function Users() {

  return (
    <div>
      <UserOverviewTab />
    </div>
  );
}
