import { format, parse, parseISO, parseJSON } from "date-fns";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { translate } from "../../../i18n/i18n";
import { editAlert } from "../../../store/alerts/alertSlice";
import alertIcon from "./../../../assets/icons/alert.svg";

import styles from "./AlertCard.module.scss";

interface AlertCardProps {
  alert?: any,
  index?: number,
  header?: boolean
}

export const AlertCard: FunctionComponent<AlertCardProps> = ({ alert, index, header }) => {
  const dispatch = useDispatch();

  const confirm = (id: number) => {
    dispatch({
      ...editAlert({
        requestType: "PATCH",
        request: `/alert/confirm/${id}`
      })
    });
  };
  console.log(alert && parseJSON(alert.updatedAt));
  return <div key={index || `header-0`}>
    { header && !index ? (<div className={`${styles.wrapper} ${styles.header}`}>
      <div className={styles.header}>{translate(`alertsOverviewTab.title`)}</div>
      <div className={styles.value}>{translate(`alertsOverviewTab.value`)}</div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`alertsOverviewTab.process`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`alertsOverviewTab.timestamp`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`alertsOverviewTab.confirmed`)}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`alertsOverviewTab.markAsResolve`)}</span>
      </div>
    </div>) : (<div key={index} className={`${styles.wrapper} ${styles.body}`}>
      <div className={styles.header}><img alt={"alertIcon"} src={alertIcon} /> {alert.header} </div>
      <div className={styles.value}>{alert.value}</div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`alertsOverviewTab.process`)}</span>
        <span className={styles.attributeValue}>{alert.process}</span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`alertsOverviewTab.timestamp`)}</span>
        <span className={styles.attributeValue}>{format(parseJSON(alert.createdAt), "MMM dd yyyy")} <span
          className={styles.time}>{format(parseJSON(alert.createdAt), "HH:mm")}</span></span>
      </div>
      <div className={styles.attribute}>
        <span className={styles.attributeName}>{translate(`alertsOverviewTab.confirmed`)}</span>
        <span
          className={styles.attributeValue}>{alert.confirmed ? <>{format(parseJSON(alert.updatedAt), "MMM dd yyyy")} <span
            className={styles.time}>{format(parseJSON(alert.updatedAt), "HH:mm")}</span></> :
          <span className={styles.warning}>{translate(`alertsOverviewTab.no`)}</span>}</span>
      </div>
      {!alert.confirmed && <button className={styles.confirmButton} onClick={() => {
        confirm(alert.id);
      }
      }>{translate(`alertsOverviewTab.markAsResolve`)}</button>}
    </div>) }
  </div>

};