import React, { FunctionComponent } from "react";
import { translate } from "../../i18n/i18n";
import styles from "./NoInternetConnection.module.scss";

interface NoInternetConnectionProps { }

const NoInternetConnection: FunctionComponent<NoInternetConnectionProps> = () => {
  return (
    <>
      <div className={styles.overlayContainer}>
        <div className={styles.logo} />

        <div className={styles.text}>
          {translate(`noInternetConnetion`)}
        </div>
      </div>
    </>
  );
};

export default NoInternetConnection;