import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

interface LoginState {
	isSuccess: boolean;
	isLoading: boolean;
	errorMessage: string;
	data: any;
}

const initialState: LoginState = {
	isSuccess: false,
	isLoading: false,
	errorMessage: '',
	data: null,
};

export const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		logIn: (state, action: any) => {
			if (action.state === 'send') {
				state.isLoading = true;
			}

			if (action.state === 'finished') {
				state.isLoading = false;
				localStorage.setItem('jwt', action.response.accessToken);
				state.isSuccess = true;
			} else if (action.state === 'error') {
				state.errorMessage = 'Wrong e-mail or password.';
			}
		},
		clearState: state => {
			state.isSuccess = false;
			state.data = null;
			return state;
		},
		clearLoginStore: () => {
			return initialState;
		}
	},
});

export const { logIn, clearState, clearLoginStore } = loginSlice.actions;

export const loginSelector = (state: RootState) => state.login;

export default loginSlice.reducer;
