import React from "react";
import ContractOverviewTab from "../../components/tabs/ContractOverviewTab/ContractOverviewTab";

export default function ContractValues() {

  return (
    <div>
      <ContractOverviewTab />
    </div>
  );
}
