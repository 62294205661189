import * as React from "react";

type LocationContextState = {
  locations: any[],
  location: any,
  setLocation: any

};

const contextDefaultValues: LocationContextState = {
  locations: [],
  location: null,
  setLocation: null
};

export const LocationContext = React.createContext<LocationContextState>(contextDefaultValues);


export default function useLocationContext() {
  let context = React.useContext(LocationContext);
  // If context is undefined, we know we used RadioGroupItem
  // outside of our provider so we can throw a more helpful
  // error!
  if (context === undefined) {
    throw Error(
      "no user loaded"
    );
  }

  // Because of TypeScript's type narrowing, if we make it past
  // the error the compiler knows that context is always defined
  // at this point, so we don't need to do any conditional
  // checking on its values when we use this hook!
  return context;
}
