import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getPlatforms } from "@ionic/react";
import { isEmpty, isNil } from "lodash";
import { useAlerts } from "../../hooks/alertHook";
import { ProcessesEnums } from "../../enums/processes-enums";
import { LocationMenuItems } from "../../enums/users/locationMenuItems-enum";
import { UrlParams } from "../../interfaces/url-params.interface";
import { kpiSelector } from "../../store/kpis/kpiSlice";
import LocationTabsInbound from "../../components/locationTabs/LocationTabsInbound/LocationTabsInbound";
import LocationTabsOutbound from "../../components/locationTabs/LocationTabsOutbound/LocationTabsOutbound";
import LocationTabsOverview from "../../components/locationTabs/LocationTabsOverview/LocationTabsOverview";
import LocationTabsStorage from "../../components/locationTabs/LocationTabsStorage/LocationTabsStorage";
import { AlertListHeader } from "../../components/shared/AlertListHeader/AlertListHeader";
import { AlertWrapper } from "../../components/shared/AlertWrapper/AlertWrapper";
import Loader from "../../components/shared/Loader";
import LocationMenu from "../../components/shared/LocationMenu/LocationMenu";
import LocationsDropdown from "../../components/shared/LocationsDropdown/LocationsDropdown";
import styles from "./SingleLocation.module.scss";

interface SingleLocationProps { }

const SingleLocation: FunctionComponent<SingleLocationProps> = () => {
  const { id: locationId, menuItem } = useParams<UrlParams>();
  const { locations } = useSelector(kpiSelector);
  const selectedLocation: any = !isEmpty(locations) && locations.find((location: any) => location.id == locationId);
  const { alertsData, setProcess, showAlerts, setShowAlerts, allAlerts } = useAlerts(locationId);
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    if (menuItem && selectedLocation) {
      switch (menuItem) {
        case LocationMenuItems.OVERVIEW:
          setShow(false);
          break;
        case LocationMenuItems.INBOUND:
          setProcess(ProcessesEnums.INBOUND);
          setShow(!!selectedLocation.alerts.inbound.length);
          break;
        case LocationMenuItems.OUTBOUND:
          setProcess(ProcessesEnums.OUTBOUND);
          setShow(!!selectedLocation.alerts.outbound.length);
          break;
        case LocationMenuItems.STORAGE:
          setProcess(ProcessesEnums.STORAGE);
          setShow(!!selectedLocation.alerts.storage.length);
          break;
      }
    }
  }, [menuItem, selectedLocation]);

  const getTabInformation = (): ReactNode => {
    switch (menuItem) {
      case LocationMenuItems.OVERVIEW:
        return <LocationTabsOverview locationId={locationId} />;
      case LocationMenuItems.INBOUND:
        return <LocationTabsInbound locationId={locationId} />;
      case LocationMenuItems.OUTBOUND:
        return <LocationTabsOutbound locationId={locationId} />;
      case LocationMenuItems.STORAGE:
        return <LocationTabsStorage locationId={locationId} />;
    }
  };

  return (
    <>
      {
        isEmpty(locations) || isNil(selectedLocation) ? <Loader /> :
          <AlertWrapper alertsData={alertsData} show={show} showAlerts={showAlerts} setShowAlerts={setShowAlerts}>
            <div className={styles.locationContainer}>
              <div className={styles.header}>
                <LocationsDropdown locations={locations} />
                <LocationMenu alerts={allAlerts} />

                {
                  !showAlerts && alertsData?.total > 0 &&
                  <button className={styles.showAlertsButton} onClick={() => setShowAlerts(true)}>
                    <AlertListHeader embedded={false} amount={alertsData.total} />
                  </button>
                }
              </div>

              {getTabInformation()}
            </div>
          </AlertWrapper>
      }
    </>
  );
};

export default SingleLocation;