import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react';
import { Container, CssBaseline } from '@material-ui/core';

import { SetPasswordInterface } from '../../../interfaces/set-password-interface';
import { clearState, registerSelector, setPassword } from './registerSlice';
import { useStyles } from '../styles';
import { validationSchema } from './validationSchemaPassword';
import logo from '../../../assets/images/logo-ldpi.png';
import { Alert, AlertTitle } from '@material-ui/lab';

const SetPassword = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	var url = new URL(window.location.href);
	var params = url.searchParams;
	const history = useHistory();

	const { isSuccess } = useSelector(registerSelector);

	useEffect(() => {
		if (isSuccess) {
			dispatch(clearState());
			history.push('/dashboard');
		}
	}, [isSuccess]);

	const onSubmit = (values: SetPasswordInterface) => {
		dispatch({
			...setPassword({
				body: {
					token: params.get('token'),
					password: values.password,
					c_password: values.passwordConfirm,
				},
				request: '/user/confirm',
			}),
		});
	};

	const initialValues: SetPasswordInterface = {
		password: '',
		passwordConfirm: '',
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<img className={classes.Logo} src={logo} />
					{(!!formik.errors.password ||
						!!formik.errors.passwordConfirm) && (
						<div className={classes.ErrorMsg}>
							{formik.errors.password ||
								formik.errors.passwordConfirm}
						</div>
					)}
					<Form
						onSubmit={formik.handleSubmit}
						style={{ width: '100%' }}
						size="big"
					>
						<label htmlFor="password" className={classes.Label}>
							Set your password
						</label>
						<input
							type="password"
							name="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							className={classes.Input}
						/>
						<label
							htmlFor="passwordConfirm"
							className={classes.Label}
						>
							Repeat your password
						</label>
						<input
							type="password"
							name="passwordConfirm"
							value={formik.values.passwordConfirm}
							onChange={formik.handleChange}
							className={classes.Input}
						/>
						<button
							disabled={
								formik.isSubmitting ||
								!formik.isValid ||
								!formik.dirty
							}
							type="submit"
							className={classes.SubmitButton}
						>
							Register
						</button>
					</Form>
				</div>
			</Container>
		</div>
	);
};

export default SetPassword;
