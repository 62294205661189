import { isNil } from "lodash";

export enum SwipeDirection {
  LEFT = 1,
  RIGHT = -1
}

interface ISwipeItem {
  index: number;
  path: string;
}

const ROUTES: ISwipeItem[] = [
  {
    index: 0,
    path: "overview",
  },
  {
    index: 1,
    path: "outbound",
  },
  {
    index: 2,
    path: "storage",
  },
  {
    index: 3,
    path: "inbound",
  }
];

export const onSwipe = (currentRoute: string, direction: SwipeDirection): string => {
  const currentPage: string | undefined = currentRoute.split("/").pop();

  if (!isNil(currentPage)) {
    const currentSwipeItem: ISwipeItem | undefined = ROUTES.find((swipeItem: ISwipeItem) => swipeItem.path === currentPage);
    const nextSwipeItemIndex: number | undefined = currentSwipeItem && currentSwipeItem?.index + direction;
    const nextSwipeItem: ISwipeItem | undefined = ROUTES.find((swipeItem: ISwipeItem) => swipeItem.index === nextSwipeItemIndex);

    if (isNil(nextSwipeItem)) {
      return direction === SwipeDirection.LEFT ? ROUTES[0].path : ROUTES[ROUTES.length - 1].path;
    } else {
      return nextSwipeItem.path;
    }
  }

  return currentRoute;
};