import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomNavigation from '../components/layout/CustomNavigation/CustomNavigation';

const DefaultLayout = ({ children }) => {
	const history = useHistory();

	useEffect(() => {
		if (!localStorage.getItem('jwt')) {
			history.push('/login');
		}
	});

	return <CustomNavigation children={children} />;
};

export default DefaultLayout;
