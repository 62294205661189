import React, { useState } from "react";
import { translate } from "../../../i18n/i18n";
import BaseModal from "../BaseModal/BaseModal";
import styles from "./ConfirmModal.module.scss";


interface ConfirmModalProps {
  closeModal: () => void,
  title: string,
  onConfirm: () => void,
}

export const useConfirmModal = () => {
  const [confirm, setConfirm] = useState<boolean>(false);

  const closeConfirm = () => {
    setConfirm(false);
  }

  const getConfirmation = () => {
    setConfirm(true);
  }


  return {
    confirm,
    setConfirm,
    closeConfirm,
    getConfirmation
  }
}

const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = ({ title, closeModal, onConfirm }) => {
  return (
    <BaseModal closeModal={closeModal} style={`small`} title={translate(`ConfirmModal.areYouSure`)}>
      <div className={styles.buttonWrapper}>
        <a className={styles.cancel} onClick={closeModal}>{translate(`ConfirmModal.cancel`)}</a>
        <button onClick={onConfirm}>{translate(`ConfirmModal.confirm`)}</button>
      </div>
    </BaseModal>
  );
};

export default ConfirmModal;
