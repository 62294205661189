import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { isNil } from "lodash";
import { SwipeableHandlers } from "react-swipeable";
import { useSwipe } from "../../../hooks/swipeHook";
import { DropdownPosition } from "../../../enums/chart.enum";
import { KpiType } from "../../../enums/kpi.enum";
import { TimeFrame } from "../../../enums/timeFrame.enum";
import { ChartOptions } from "../../../interfaces/chart.interface";
import { KpiListElement } from "../../../interfaces/kpi.interface";
import { IDates } from "../../../interfaces/calendar.interface";
import {
  CHART_TIMES,
  INBOUND,
  TIMES,
  OUTBOUND,
  PALLET_AMOUNT,
  PARCEL_AMOUNT
} from "../../../libs/kpi.constants";
import { ComparisonOptions, COMPARISON_OPTIONS, IComparisonOption } from "../../../libs/comparison.constants";
import { getDropdownOptions, getInitialDropdownOptions } from "../../../helpers/locationHelper";
import CalendarDropdown from "../CalendarDropdown/CalendarDropdown";
import ChartDropdown from "../ChartDropdown/ChartDropdown";
import LineChart from "../KpiGraphs/LineChart/LineChart";
import ComparisonDropdown from "../ComparisonDropdown/ComparisonDropdown";
import styles from './KpiContainer.module.scss';

interface KpiContainerProps {
  kpiType: KpiType;
  location: string;
  comparison?: boolean;
}

const KpiContainer: FunctionComponent<KpiContainerProps> = ({ kpiType, location, comparison = false }) => {
  const handlers: SwipeableHandlers | null = useSwipe();
  const [dropdownOptions, setDropdownOptions] = useState<ChartOptions>();
  const [fullOptionsList, setFullOptionsList] = useState<KpiListElement[]>([]);
  const [mainKpi, setMainKpi] = useState<KpiListElement>();
  const [activeKpis, setActiveKpis] = useState<KpiListElement[]>([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>(TimeFrame.LAST_24_HOURS);
  const [dates, setDates] = useState<IDates>({ from: null, to: null });
  const [selectedComparisonOption, setSelectedComparisonOption] = useState<IComparisonOption>(COMPARISON_OPTIONS[0]);
  const [timeKpis, setTimeKpis] = useState<string[] | null>();
  const [selectedTimeKpi, setSelectedTimeKpi] = useState<string | null>();
  const [showFullCalendarDropdownList, setShowFullCalendarDropdownList] = useState<boolean>(true);

  useEffect(() => {
    switch (kpiType) {
      case KpiType.OUTBOUND:
        setFullOptionsList(OUTBOUND.list);
        setMainKpi(OUTBOUND.mainKpi);
        setDropdownOptions(getInitialDropdownOptions(OUTBOUND.list, true));
        break;
      case KpiType.INBOUND:
        setFullOptionsList(INBOUND.list);
        setMainKpi(INBOUND.mainKpi);
        setDropdownOptions(getInitialDropdownOptions(INBOUND.list, true));
        break;
      case KpiType.PALLET_AMOUNT:
        setFullOptionsList(PALLET_AMOUNT.list);
        setMainKpi(PALLET_AMOUNT.mainKpi);
        setDropdownOptions(getInitialDropdownOptions(PALLET_AMOUNT.list, true));
        break;
      case KpiType.INBOUND_TIME:
        setFullOptionsList(TIMES.inbound.list);
        setMainKpi(TIMES.inbound.mainKpi);
        setDropdownOptions(getInitialDropdownOptions(TIMES.inbound.list, true));
        setTimeKpis(CHART_TIMES.INBOUND);
        setSelectedTimeKpi(CHART_TIMES.INBOUND[0]);
        break;
      case KpiType.PARCEL_AMOUNT:
        setFullOptionsList(PARCEL_AMOUNT.list);
        setMainKpi(PARCEL_AMOUNT.mainKpi);
        setDropdownOptions(getInitialDropdownOptions(PARCEL_AMOUNT.list, true));
        break;
      case KpiType.OUTBOUND_TIME:
        setFullOptionsList(TIMES.outbound.list);
        setMainKpi(TIMES.outbound.mainKpi);
        setDropdownOptions(getInitialDropdownOptions(TIMES.outbound.list, true));
        setTimeKpis(CHART_TIMES.OUTBOUND);
        setSelectedTimeKpi(CHART_TIMES.OUTBOUND[0]);
        break;
    }
  }, []);

  useEffect(() => {
    if (!isNil(selectedTimeKpi)) {
      // @ts-ignore
      setFullOptionsList(TIMES[selectedTimeKpi].list);
      // @ts-ignore
      setMainKpi(TIMES[selectedTimeKpi].mainKpi);
      // @ts-ignore
      setDropdownOptions(getInitialDropdownOptions(TIMES[selectedTimeKpi].list, true));

      if (selectedComparisonOption.value !== ComparisonOptions.PLEASE_SELECT) {
        setSelectedComparisonOption(COMPARISON_OPTIONS[0]);
      }
    }
  }, [selectedTimeKpi]);

  useEffect(() => {
    if (selectedComparisonOption.value !== ComparisonOptions.PLEASE_SELECT && !isNil(mainKpi) && !isNil(selectedTimeKpi)) {
      setActiveKpis([mainKpi]);
      switch (kpiType) {
        case KpiType.OUTBOUND:
          setDropdownOptions(getInitialDropdownOptions(OUTBOUND.list));
          break;
        case KpiType.INBOUND:
          setDropdownOptions(getInitialDropdownOptions(INBOUND.list));
          break;
        case KpiType.PALLET_AMOUNT:
          setDropdownOptions(getInitialDropdownOptions(PALLET_AMOUNT.list));
          break;
        case KpiType.INBOUND_TIME:
          // @ts-ignore
          setDropdownOptions(getInitialDropdownOptions(TIMES[selectedTimeKpi].list));
          break;
        case KpiType.PARCEL_AMOUNT:
          setDropdownOptions(getInitialDropdownOptions(PARCEL_AMOUNT.list));
          break;
        case KpiType.OUTBOUND_TIME:
          // @ts-ignore
          setDropdownOptions(getInitialDropdownOptions(TIMES[selectedTimeKpi].list));
          break;
      }
    }

    if (
      selectedComparisonOption.value === ComparisonOptions.AVERAGE ||
      selectedComparisonOption.value === ComparisonOptions.PREVIOUS_TIMEFRAME
    ) {
      setShowFullCalendarDropdownList(false);
    } else {
      setShowFullCalendarDropdownList(true);
    }
  }, [selectedComparisonOption]);

  useEffect(() => {
    if (!isNil(dropdownOptions) && !isNil(mainKpi)) {
      setActiveKpis([mainKpi, dropdownOptions[0].selected, dropdownOptions[1].selected].filter((el: KpiListElement) => el.value !== "CLEAR"));
    }
  }, [dropdownOptions]);

  const getSelectedKpi = (kpi: KpiListElement): void => {
    if (kpi.dropdownPosition === DropdownPosition.FIRST) {
      setDropdownOptions((prevState: any) => getDropdownOptions(kpi, prevState, fullOptionsList, DropdownPosition.FIRST));
    } else {
      setDropdownOptions((prevState: any) => getDropdownOptions(kpi, prevState, fullOptionsList, DropdownPosition.SECOND));
    }
  };

  const getSelectedTimeFrame = (timeFrame: TimeFrame): void => {
    setSelectedTimeFrame(timeFrame);

    if (timeFrame === TimeFrame.CUSTOM) {
      setSelectedComparisonOption(COMPARISON_OPTIONS[0]);
    }
  };

  const getSelectedComparisonOption = (comparisonOption: IComparisonOption): void => {
    setSelectedComparisonOption(comparisonOption);

    if (comparisonOption.value !== ComparisonOptions.PLEASE_SELECT && !isNil(mainKpi)) {
      setActiveKpis([mainKpi]);
      switch (kpiType) {
        case KpiType.OUTBOUND:
          setDropdownOptions(getInitialDropdownOptions(OUTBOUND.list, false));
          break;
        case KpiType.INBOUND:
          setDropdownOptions(getInitialDropdownOptions(INBOUND.list, false));
          break;
        case KpiType.PALLET_AMOUNT:
          setDropdownOptions(getInitialDropdownOptions(PALLET_AMOUNT.list, false));
          break;
        case KpiType.INBOUND_TIME:
          setDropdownOptions(getInitialDropdownOptions(TIMES.inbound.list, false));
          break;
        case KpiType.PARCEL_AMOUNT:
          setDropdownOptions(getInitialDropdownOptions(PARCEL_AMOUNT.list, false));
          break;
        case KpiType.OUTBOUND_TIME:
          setDropdownOptions(getInitialDropdownOptions(TIMES.outbound.list, false));
          break;
      }
    }
  };

  const getKpiTitle = (kpiType: KpiType): string => {
    if (kpiType.includes("_TIME")) {
      return "time";
    } else {
      return kpiType.replace("_", " ").toLowerCase();
    }
  };

  const getChartTimes = (): ReactNode => {
    return (
      <div className={styles.timesContainer}>
        {
          timeKpis?.map((time: string, index: number) => (
            <div key={index}
                 className={`${styles.singleTime} ${selectedTimeKpi === time ? styles.selected : ""}`}
                 onClick={() => setSelectedTimeKpi(time)}>
              {time}
            </div>
          ))
        }
      </div>
    );
  };


  return (
    <>
      {
        !isNil(dropdownOptions) &&
        <div className={styles.kpiContainer}>
          <div className={styles.header} {...handlers}>
            <div className={`${styles.kpiName} ${comparison ? styles.comparison : ""}`} >{getKpiTitle(kpiType)}</div>
            {
              comparison && selectedTimeFrame !== TimeFrame.CUSTOM && selectedTimeFrame !== TimeFrame.LAST_6_MONTHS &&
              selectedTimeFrame !== TimeFrame.LAST_YEAR &&
              <>
                <ComparisonDropdown comparisonOption={selectedComparisonOption} selectedComparisonOption={getSelectedComparisonOption} />
                <div className={styles.delimiter}>/</div>
              </>
            }

            <CalendarDropdown fullList={showFullCalendarDropdownList}
                              getSelectedTimeFrame={getSelectedTimeFrame}
                              getSelectedCustomIntervals={(dates) => setDates(dates)} />
          </div>

          {!isNil(timeKpis) && getChartTimes()}

          <LineChart location={location}
                     kpiType={kpiType}
                     kpis={activeKpis}
                     selectedTimeFrame={selectedTimeFrame}
                     selectedComparisonOption={selectedComparisonOption}
                     dates={dates} />

          <div className={styles.dropdowns}>
            <ChartDropdown chartOptions={dropdownOptions}
                           position={DropdownPosition.FIRST}
                           getSelectedKpi={getSelectedKpi} />

            <ChartDropdown chartOptions={dropdownOptions}
                           position={DropdownPosition.SECOND}
                           getSelectedKpi={getSelectedKpi} />
          </div>
        </div>
      }
    </>
  );
};

export default KpiContainer;