import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import { companyFunctions, CompanyType } from "../../../enums/companies-enums";
import { UserType } from "../../../enums/users/users-enums";
import { usePagination } from "../../../helpers/usePagination";
import { useTableSearch } from "../../../helpers/useTableSearch/useTableSearch";
import { translate } from "../../../i18n/i18n";
import { initialPaginationState } from "../../../interfaces/pagination-interface";
import { companySelector, fetchCompanies } from "../../../store/companies/companySlice";
import { setSelectedUser } from "../../../store/users/userSlice";
import AddCompanyModal from "../../modals/AddCompanyModal/AddCompanyModal";
import AddUserModal from "../../modals/AddUserModal/AddUserModal";
import { CompanyCard } from "../../shared/CompanyCard/CompanyCard";
import ContentCard from "../../shared/ContentCard";
import { CreateNewButton } from "../../shared/CreateNewButton/CreateNewButton";
import { InputForSearch } from "../../shared/InputForSearch/InputForSearch";
import Loader from "../../shared/Loader";
import { PageWrapper } from "../../shared/PageWrapper/PageWrapper";
import { Pagination } from "../../shared/Pagination/Pagination";
import { PerPageDropdown } from "../../shared/PerPageDropdown/PerPageDropdown";

import styles from "./CompanyOverviewTab.module.scss";

interface CompanyOverviewTabProps { }

const CompanyOverviewTab: React.FunctionComponent<CompanyOverviewTabProps> = () => {
  const dispatch = useDispatch();
  const [shownModal, setShownModal] = useState<companyFunctions | null>(null);
  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } = usePagination();
  const { handleSearchChange, search } = useTableSearch();
  const [company, setCompany] = useState<CompanyType | null>(null);
  const { isLoading, data: companyDataPaginated } = useSelector(companySelector);

  useEffect(() => {
    if(!isLoading) {
      dispatch({
        ...fetchCompanies({
          requestType: "GET",
          request: `/company?page=${page}&limit=${rowsPerPage}${search !== "" ? `&search=${search}` : ""}`
        })
      });
    }
  }, [isLoading, search, rowsPerPage, page]);

  const onEditButtonClick = (item: UserType) => {
    setCompany(item);
    setShownModal(companyFunctions.EDIT);
  };

  const closeModal = (): void => {
    setShownModal(null);
    dispatch(setSelectedUser(null));
  };

  const create = () => {
    setShownModal(companyFunctions.CREATE)
  };

  return (
    <PageWrapper>
      {
        isNil(companyDataPaginated) ?
          <Loader /> :
          <>{isLoading && <Loader />}
            <Grid>

              <Grid.Row>
                <Grid.Column width={16}>
                  <div className={styles.headerWrapper}>
                    <h3 className={styles.header}>{translate(`CompanyOverviewTab.pageName`)}</h3>
                    <InputForSearch value={search} handleChange={handleSearchChange}
                                    placeholder={translate(`CompanyOverviewTab.filterBy`)} />
                    <PerPageDropdown value={rowsPerPage} options={initialPaginationState.optionsRowsPerPage}
                                     handleChange={handleChangeRowsPerPage} />
                    <CreateNewButton handleClick={create}>{translate(`CompanyOverviewTab.create`)}</CreateNewButton>
                  </div>
                  <ContentCard>
                    <CompanyCard header={true} />
                    {!!companyDataPaginated.data.length && companyDataPaginated.data.map((company: any, index: number) => {
                      return <CompanyCard index={index} company={company} handleEdit={onEditButtonClick} />;
                    })}
                    <Pagination page={companyDataPaginated.page} pages={companyDataPaginated.pages}
                                handleChange={handleChangePage} />
                  </ContentCard>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
      }
      {shownModal === companyFunctions.CREATE && <AddCompanyModal closeModal={closeModal} />}
      {shownModal === companyFunctions.EDIT && company && <AddCompanyModal initialCompany={company} closeModal={closeModal} />}
    </PageWrapper>
  );
};

export default CompanyOverviewTab;
