import axios from "axios";

import { ERROR, FINISHED, SEND } from "./constants";

const apiBasePath = process.env.REACT_APP_API_URL;


const requestMiddleware = (store: any) => (next: any) => (action: any) => {
  const _action = { ...action };
  if (_action && _action.payload && action.payload.request) {
    _action.state = SEND;

    next(_action);

    // Get the body.
    const body = Array.isArray(_action.payload.body)
      ? _action.payload.body.concat([])
      : Object.assign({}, _action.payload.body);

    // Setup headers from defaults.
    const headers = Object.assign(
      {
        "Content-Type": "application/json"
      },
      _action.headers
    );

    const axiosDefinition = {
      method: _action.payload.requestType || "POST",
      url: `${_action.payload.specificURL ||
      [
        _action.payload.rawRequest ? null : apiBasePath,
        _action.payload.request.startsWith("/")
          ? _action.payload.request.substr(1, _action.payload.request.length)
          : _action.payload.request
      ]
        .filter(e => e)
        .join("/")}`,
      headers,
      data: _action.payload.body,
      responseType: _action.payload.responseType || "json"
    };

    const persistStore: any = localStorage.getItem("persist:root");
    let jwt: any = localStorage.getItem("jwt");
    const config = Object.assign(axiosDefinition, _action.axios || {});
    if (persistStore) {
      const authData = JSON.parse(JSON.parse(persistStore).auth);
      jwt = authData.token || null;
    }
    if (jwt) {
      config.headers["Authorization"] = "Bearer " + jwt;
    }

    return axios(config)
      .then(response => {
        /* 	_action.state =
          FAIL_REQUEST_CODES.indexOf(response.status) !== -1
            ? ERROR
            : FINISHED;
 */
        _action.state = FINISHED;
        const isBlob = response.data instanceof Blob;

        _action.response = response.data;

        if (!isBlob && typeof _action.response === "object") {
          _action.response = _action.response.result || _action.response;
        }

        if (!isBlob && typeof _action.response === "object" && _action.response.error) {
          /* throw new Error({
            response,
          }); */
        }

        next(_action);

        return _action;
      })
      .catch(error => {
        /* `if (error instanceof TypeError || !error.response) {
          throw error;
        }` */

        /* 				if (error.response.data[0] === 'jwt expired') {
          error.response.data[0] = 'Your session is expired. Please log in. ';
        }
 */
        _action.state = ERROR;

        _action.response = error.response && error.response.data;

        if (error?.response?.status === 401) {
          localStorage.clear();
        }

        // Check if response is empty, and generate an empty map.
        if (!_action.response) {
          if (error.hasOwnProperty("status") && error.status === 0) {
            // Check for no connection error.
            _action.response = {
              message: "No connection."
            };
          } else if (error.isAxiosError) {
            const { data } = error.response || {};

            _action.response = {
              code: data && data.messageCode,
              message: data && data[0]
            };
          } else if (error.reason) {
            // If the error has a reason, set it under the message.
            _action.response = {
              message: error.reason
            };
          } else {
            // Else set the whole error object to the response.
            /* const friendlyMessage = tryFriendlyErrorMessage(error);

            error = friendlyMessage !== false ? { message: friendlyMessage } : error;
            action.response = friendlyMessage !== false ? error : error; */
          }
        }

        // Force logout users with expired session (unauthorized)
        if (error.response && error.response && error.response.shouldRelog) {
          // store.dispatch(shouldRelog());
        } else {
          next(_action);
        }

        return _action;
      });
  }

  return new Promise(resolve => {
    if (_action.type === undefined) {
      _action.type = "";
    }

    next(_action);
    resolve(true);
  });
};

export default requestMiddleware;
