import { createSlice } from "@reduxjs/toolkit";
import { isNil } from "lodash";
import { RootState } from "../../app/store";
import { ChartContainersList } from "../../interfaces/kpi.interface";

export interface bulkErrorInterface {
  type: string,
  message: string;
}

interface initialStateInterface {
  data: any,
  locations: any,
  location: any,
  storageOverview: any,
  storageAll: any,
  storageAbc: any,
  storageZone: any,
  inboundOverview: any,
  outboundOverview: any,
  selectedTabKpisInfo: ChartContainersList | null,
  selectedKpisComparisons: any | null,
  isLoading: boolean,
  error: string,
  reload: boolean;
}

const initialState: initialStateInterface = {
  data: null,
  locations: null,
  location: [],
  storageOverview: null,
  storageAll: null,
  storageAbc: null,
  storageZone: null,
  outboundOverview: {
    parcelAmount: null,
    deliveryAmount: null,
    outboundTime: null,
    employeesInOutbound: null,
    employeesInOutboundWeek: null
  },
  inboundOverview: {
    palletAmount: null,
    inboundAmount: null,
    employeesInInbound: null,
    employeesInInboundDay: null,
    employeesInInboundMonth: null,
  },
  selectedTabKpisInfo: null,
  selectedKpisComparisons: null,
  isLoading: false,
  error: "",
  reload: false
};

export const kpiSlice = createSlice({
  name: "kpi",
  initialState,
  reducers: {
    fetchDashboard: (state, action: any) => {

      if (action.state === "send") {
        // state.isLoading = true;
      }

      if (action.state === "finished" && !!action.response) {
        state.locations = action.response;
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchDashboardLocation: (state, action: any) => {

      if (action.state === "send") {
        // state.isLoading = true;
      }

      if (action.state === "finished" && !!action.response) {
        state.location = action.response;
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchStorageOverview: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && !!action.response) {
        state.storageOverview = action.response;
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    fetchOutboundOverview: (state, action: any) => {

      if (action.state === "send") {
        state.isLoading = true;
        state.storageAll = null;
      }

      if (action.state === "finished" && !!action.response) {
        state.outboundOverview = action.response;
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    fetchInboundOverview: (state, action: any) => {

      if (action.state === "send") {
        state.isLoading = true;
        state.storageAll = null;
      }

      if (action.state === "finished" && !!action.response) {
        state.inboundOverview = action.response;
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    fetchStorageAll: (state, action: any) => {

      if (action.state === "send") {
        state.isLoading = true;
        state.storageAll = null;
      }

      if (action.state === "finished" && !!action.response) {
        state.storageAll = action.response;
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    fetchStorageZone: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
        state.storageZone = null;
      }
      if (action.state === "finished" && !!action.response) {
        state.storageZone = action.response;
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    fetchStorageAbc: (state, action: any) => {

      if (action.state === "send") {
        state.isLoading = true;
        state.storageAbc = null;
      }

      if (action.state === "finished" && !!action.response) {
        state.storageAbc = action.response;
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    add: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        state.error = "";
        state.isLoading = false;
      }
      if (action.state === "error") {
        state.isLoading = false;
        state.error = action.response?.error;
      }
    },
    edit: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        state.isLoading = false;
        state.locations = null;
      }
    },
    deleteOne: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && action.response) {
        state.isLoading = false;
        state.error = "";
      }
    },
    finishLoading(state) {
      state.isLoading = false;
    },
    clear: (state) => {
      state.locations = null;
      state.error = "";
      state.isLoading = false;
    },
    triggerKpiFetch: (state) => {
      state.reload = true;
    },
    fetchOverview: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && !isNil(action.response)) {
        state.selectedTabKpisInfo = { ...state.selectedTabKpisInfo, ...action.response };
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    fetchComparisons: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && !isNil(action.response)) {
        state.selectedKpisComparisons = { ...state.selectedKpisComparisons, ...action.response };
        state.isLoading = false;
        state.reload = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    clearKpisInfo: (state) => {
      state.selectedTabKpisInfo = null;
    },
    clearComparisons: (state, action: any) => {
      if (!isNil(state.selectedKpisComparisons) && state.selectedKpisComparisons.hasOwnProperty(action.payload)) {
        delete state.selectedKpisComparisons[action.payload];
      }
    },
    clearStorageCategoryList: (state) => {
      if (!isNil(state.selectedTabKpisInfo) && !isNil(state.selectedTabKpisInfo.STORAGE_CATEGORY_LIST)) {
        delete state.selectedTabKpisInfo.STORAGE_CATEGORY_LIST;
      }
    },
    clearKpiStore: () => {
      return initialState;
    }
  }
});

export const {
  fetchDashboard,
  triggerKpiFetch,
  fetchDashboardLocation,
  fetchStorageOverview,
  fetchInboundOverview,
  fetchOutboundOverview,
  fetchStorageAll,
  fetchStorageAbc,
  fetchStorageZone,
  fetchOverview,
  fetchComparisons,
  clearKpisInfo,
  clearComparisons,
  clearStorageCategoryList,
  clearKpiStore
} = kpiSlice.actions;
export const kpiSelector = (state: RootState) => state.kpi;
export default kpiSlice.reducer;