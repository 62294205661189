import { Box, CircularProgress } from "@material-ui/core";
import format from "date-fns/format";
import { isEmpty, isNil } from "lodash";
import React, { FunctionComponent, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { KpiType } from "../../../../enums/kpi.enum";
import { THEMES } from "../../../../enums/theme.enum";
import { TimeFrame } from "../../../../enums/timeFrame.enum";
import {
  getChartSeriesColors,
  getChartSeriesData,
  getChartSeriesForecastData,
  getChartTooltip,
  getYAxisOptions
} from "../../../../helpers/chartHelper";
import { IDates } from "../../../../interfaces/calendar.interface";
import { KpiListElement } from "../../../../interfaces/kpi.interface";
import { ComparisonOptions, IComparisonOption } from "../../../../libs/comparison.constants";
import {
  clearComparisons,
  clearKpisInfo,
  fetchComparisons,
  fetchOverview,
  kpiSelector
} from "../../../../store/kpis/kpiSlice";
import { windowDataSelector } from "../../../../store/window/windowDataSlice";
import styles from "./LineChart.module.scss";

interface LineChartProps {
  location: string;
  kpiType: KpiType;
  kpis: KpiListElement[];
  selectedTimeFrame: TimeFrame;
  dates: IDates;
  selectedComparisonOption: IComparisonOption;
}

const LineChart: FunctionComponent<LineChartProps> = ({
  location,
  kpiType,
  kpis,
  selectedTimeFrame,
  dates,
  selectedComparisonOption
}) => {
  const dispatch = useDispatch();
  const { selectedTabKpisInfo, selectedKpisComparisons } = useSelector(kpiSelector);
  const { isDesktop, theme } = useSelector(windowDataSelector);
  const [series, setSeries] = useState<any>({ main: [], comparison: [] });

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: 'line',
      redrawOnParentResize: true,
      toolbar: false
    },
    forecastDataPoints: {
      count: 0,
    },
    plotOptions: {
        bar: {
          columnWidth: '50%'
        }
    },
    grid: {
      borderColor: theme === THEMES.LIGHT ? "#FFFFFF" : "#344355",
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      width: 3
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: theme === THEMES.LIGHT ? "#F4F7F9" : "#11243B",
        height: -320,
        offsetX: 0,
        offsetY: 0
      },
      axisBorder: {
        show: true,
        color: theme === THEMES.LIGHT ? "#F4F7F9" : "#11243B",
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: {
      show: false
    },
    legend: {
      show: false,
      inverseOrder: false
    }
  });

  useEffect(() => {
    return () => {
      dispatch(clearKpisInfo());
    };
  }, []);

  useEffect(() => {
    if (!isNil(location) && !isEmpty(kpis)) {
      let kpisQueryParam: string = "";
      kpis.forEach((kpi: KpiListElement, index: number) => kpisQueryParam = kpisQueryParam.concat(index === 0 ? "?kpi=" : "&kpi=", kpi.value));

      dispatch({
        ...fetchOverview(
          {
            requestType: "GET",
            request: `/kpi/kpis${kpisQueryParam}&kpiType=${kpiType}&location=${location}&timeFrame=${selectedTimeFrame}${!isNil(dates.from) ? `&from=${dates.from}&to=${dates.to}` : ""}`
          }
        )
      });
    }
  }, [location, kpis, selectedTimeFrame, dates]);

  useEffect(() => {
    if (!isNil(selectedTabKpisInfo) && !isNil(selectedTabKpisInfo[kpiType])) {
      const newForecastPoints = getChartSeriesForecastData(selectedTabKpisInfo[kpiType]);
      setChartOptions({
        ...chartOptions,
        forecastDataPoints: {
          count: newForecastPoints
        }
      });

      setSeries((prevState: any) => ({
        ...prevState,
        main: [...getChartSeriesData(selectedTabKpisInfo[kpiType], false)]
      }));

      setChartOptions((prevState: any) => ({
        ...prevState,
        yaxis: getYAxisOptions(selectedTabKpisInfo[kpiType], !isNil(selectedKpisComparisons) && selectedKpisComparisons[kpiType]),
        colors: getChartSeriesColors(kpis, !isNil(selectedKpisComparisons) && selectedKpisComparisons[kpiType])
      }));
    }

    if (!isNil(selectedKpisComparisons) && !isNil(selectedKpisComparisons[kpiType])) {
      setSeries((prevState: any) => ({
        ...prevState,
        comparison: [...getChartSeriesData(selectedKpisComparisons[kpiType], false, selectedTimeFrame, selectedComparisonOption)]
      }));
    } else {
      setSeries((prevState: any) => ({
        ...prevState,
        comparison: []
      }));
    }
  }, [selectedTabKpisInfo, selectedKpisComparisons]);

  useEffect(() => {
    if (!isNil(selectedTabKpisInfo) && !isNil(selectedTabKpisInfo[kpiType])) {
      setChartOptions((prevState: any) => ({
        ...prevState,
        legend: {
          ...prevState.legend,
          inverseOrder: !prevState.legend.inverseOrder
        },
        tooltip: getChartTooltip(selectedTimeFrame, [...Object.keys(selectedTabKpisInfo[kpiType]), ...Object.keys(selectedTabKpisInfo[kpiType])])
      }));
    }

    if (isBigInterval()) {
      setChartOptions((prevState: any) => ({
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          labels: {
            show: true,
            formatter: (val: any) => {
              return format(val, "MMM dd");
            }
          }
        }
      }));
    } else {
      setChartOptions((prevState: any) => ({
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          labels: {
            show: false
          }
        }
      }));
    }
  }, [selectedTimeFrame, selectedTabKpisInfo]);

  useEffect(() => {
    if (selectedComparisonOption.value !== ComparisonOptions.PLEASE_SELECT && selectedTimeFrame !== TimeFrame.CUSTOM) {
      let kpisQueryParam: string = "";
      kpis.forEach((kpi: KpiListElement, index: number) => kpisQueryParam = kpisQueryParam.concat(index === 0 ? "?kpi=" : "&kpi=", kpi.value));

      dispatch({
        ...fetchComparisons(
          {
            requestType: "GET",
            request: `/kpi/comparisons${kpisQueryParam}&kpiType=${kpiType}&location=${location}&timeFrame=${selectedTimeFrame}&comparisonOption=${selectedComparisonOption.value}`
          }
        )
      });
    } else {
      dispatch(clearComparisons(kpiType));
    }
  }, [selectedComparisonOption, selectedTimeFrame, location, kpis]);

  const isBigInterval = (): boolean => {
    return selectedTimeFrame === TimeFrame.LAST_6_MONTHS || selectedTimeFrame === TimeFrame.LAST_YEAR;
  };

  return (
    <>
      {
        !isNil(series) ?
          <div className={`${styles.lineChart} ${isBigInterval() ? styles.xaxisLabels : ""}`}>
            <Chart type="line" options={chartOptions} series={[...series.main, ...series.comparison]} height={isDesktop ? 350 : 160} />
          </div> :
          <Box className={styles.loader}>
            <CircularProgress />
          </Box>
      }
    </>
  );
};

export default LineChart;