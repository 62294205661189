import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "semantic-ui-react";

import useUserContext from "../../../contexts/UserContext/UserContext";
import { CompanyType } from "../../../enums/companies-enums";
import { UserRole, UserType } from "../../../enums/users/users-enums";
import { translate } from "../../../i18n/i18n";
import { companySelector, fetchCompanies } from "../../../store/companies/companySlice";
import { fetchLocations, locationSelector } from "../../../store/locations/locationSlice";
import { addUser, deleteUser, userSelector } from "../../../store/users/userSlice";
import { Checkbox } from "../../shared/Checkbox/Checkbox";
import { Dropdown } from "../../shared/Dropdown/Dropdown";
import { Input } from "../../shared/Input/Input";
import { ModalButtonWrapper } from "../../shared/ModalButtonWrapper/ModalButtonWrapper";
import { ModalCancelButton } from "../../shared/ModalCancelButton/ModalCancelButton";
import { ModalCreateButton } from "../../shared/ModalConfirmButton/ModalCreateButton";
import { ModalWrapper } from "../../shared/ModalWrapper/ModalWrapper";
import BaseModal, { validateEmail, validateNotEmpty } from "../BaseModal/BaseModal";
import ConfirmModal, { useConfirmModal } from "../ConfirmModal/ConfirmModal";
import styles from "./AddUserModal.module.scss";

interface Role {
  id: number;
  name: string;
}

interface AddUserModalProps {
  initialUser?: UserType,
  closeModal: () => void
}


const AddUserModal: React.FunctionComponent<AddUserModalProps> = ({ closeModal, initialUser }) => {
  const dispatch = useDispatch();
  const { confirm, closeConfirm, getConfirmation } = useConfirmModal();
  const [error, setError] = useState<string | null>();
  const { user: currentUser } = useUserContext();
  const [user, setUser] = useState<UserType | undefined>(initialUser);
  const { data: locationData } = useSelector(locationSelector);
  const { data: companyData } = useSelector(companySelector);
  const { error: apiError } = useSelector(userSelector);
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {

    dispatch({
        ...fetchLocations({
          requestType: "GET",
          request: `/location/all`
        })
      }
    );
    if(currentUser.role === UserRole.SUPER) {
      dispatch({
          ...fetchCompanies({
            requestType: "GET",
            request: `/company/all`
          })
        }
      );
    }


    switch (currentUser.role) {
      case UserRole.USER:
        setRoles([{ id: 1, name: UserRole.USER }]);
        break;
      case UserRole.ADMIN:
        setRoles([
          {
            id: 1,
            name: UserRole.USER
          },
          {
            id: 2,
            name: UserRole.ADMIN
          }
        ]);
        break;
      default:
        setRoles([
          {
            id: 1,
            name: UserRole.USER
          },
          {
            id: 2,
            name: UserRole.ADMIN
          },
          {
            id: 3,
            name: UserRole.SUPER
          }
        ]);
        break;
    }
  }, [currentUser]);

  const handleChange = (e: ChangeEvent<any>, valueAttribute: string = "value") => {
    setUser({
      ...user,
      [e.target.name]: e.target[valueAttribute]
    });
  };

  const handleChangeCheckbox = (e: ChangeEvent<any>) => {
    const locationId = +e.target.value;
    let locations = user?.locations ? [...user.locations] : [];
    if (e.target.checked && !locations.includes(locationId)) {
      locations.push(locationId);
    }

    if (!e.target.checked && locations.includes(locationId)) {
      locations.splice(locations.indexOf(locationId), 1);
    }

    setUser({
      ...user,
      locations
    });
  };

  const handleRoleChange = (roleId: number) => {
    setUser({
      ...user,
      role: roles.find((role: any) => role.id === roleId)?.name
    });
  };

  const handleCompanyChange = (id: number) => {
    setUser({
      ...user,
      company: {
        id
      }
    });
  };

  const validate = (user: any) => {

    if (!user) {
      setError(`AddUserModal.error.emptyForm`);
      return false;
    }

    if (!validateEmail(user.email)) {
      setError(`AddUserModal.error.incorrectEmail`);
      return false;
    }

    if (!validateNotEmpty(user.firstName)) {
      setError(`AddUserModal.error.firstNameEmpty`);
      return false;
    }

    if (!validateNotEmpty(user.lastName)) {
      setError(`AddUserModal.error.lastNameEmpty`);
      return false;
    }

    if (!validateNotEmpty(user.role)) {
      setError(`AddUserModal.error.roleEmpty`);
      return false;
    }

    if (!validateNotEmpty(user.locations)) {
      setError(`AddUserModal.error.locationEmpty`);
      return false;
    }

    return true;
  };

  const onSubmit = (): void => {

    if (initialUser && validate(user)) {
      dispatch({
        ...addUser({
          requestType: "PATCH",
          body: user,
          request: `/user/${initialUser.id}`
        })
      });
    } else if (validate(user)) {
      dispatch({
        ...addUser({
          requestType: "POST",
          body: user,
          request: `/user/invite`
        })
      });
    }
  };

  const onDelete = (): void => {
    if (initialUser) {
      dispatch({
        ...deleteUser({
          requestType: "DELETE",
          request: `/user/${initialUser.id}`
        })
      });
      closeModal();
      closeConfirm();
    }
  };
  console.log(user);
  return <BaseModal closeModal={closeModal}
                    title={initialUser ? translate(`userOverviewTab.edit`) : translate(`userOverviewTab.create`)}>
    <ModalWrapper>
      <Input value={user?.firstName} name={"firstName"} label={translate(`AddUserModal.label.firstName`)}
             handleChange={handleChange} />
      <Input value={user?.lastName} name={"lastName"} label={translate(`AddUserModal.label.lastName`)}
             handleChange={handleChange} />
      <Input value={user?.email} name={"email"} label={translate(`AddUserModal.label.email`)}
             handleChange={handleChange} />

      <Checkbox value={1}
                name={"sendAlert"}
                label={translate(`AddUserModal.label.sendAlert`)}
                checked={user?.sendAlert || false}
                handleChange={(e) => handleChange(e, "checked")} />

      <Dropdown handleChange={handleRoleChange}
                valueText={user?.role && `${user?.role} selected`}
                label={translate(`AddUserModal.label.role`)}
                value={roles?.find((role: any) => role.name === user?.role)?.id}
                options={roles} />
      {currentUser.role === UserRole.SUPER && companyData && !!companyData.length ? (<Dropdown handleChange={handleCompanyChange}
                                                        valueText={companyData?.find( (company:any) => company.id === user?.company?.id)?.name + ` selected`}
                                                        label={translate(`AddUserModal.label.company`)}
                                                        value={companyData?.find((company: any) => company.id === user?.company?.id)?.id}
                                                        options={companyData.map( (company: any) => {
                                                          return {
                                                            id: company.id,
                                                            name: company.name
                                                          }
                                                        })} />) : null}


    </ModalWrapper>
    {currentUser && currentUser.role !== UserRole.SUPER && currentUser?.locations?.map((location: any, index: number) => <Checkbox key={index} value={location.id}
                                                                            name={"locations[]"}
                                                                            label={location.name}
                                                                            checked={user?.locations?.includes(location.id) || false}
                                                                            handleChange={handleChangeCheckbox} />)}

    {currentUser && user?.company?.id && currentUser.role === UserRole.SUPER && locationData && locationData?.filter( (location:any) => location.company.id === user.company?.id )?.map((location: any, index: number) => <Checkbox key={index} value={location.id}
                                                                                                                   name={"locations[]"}
                                                                                                                   label={location.name}
                                                                                                                   checked={user?.locations?.includes(location.id) || false}
                                                                                                                   handleChange={handleChangeCheckbox} />)}


    {!initialUser && <p className={styles.note}>Passwort wird vom Nutzer bei Neuanmeldung selbst erstellt.</p>}
    {error && <Message error content={translate(error)} />}
    {apiError && <Message error content={apiError} />}
    <ModalButtonWrapper>
      {initialUser &&
      <ModalCancelButton getConfirmation={getConfirmation}>{translate(`AddUserModal.delete`)}</ModalCancelButton>}
      <ModalCreateButton
        onSubmit={onSubmit}>{initialUser ? translate(`userOverviewTab.edit`) : translate(`userOverviewTab.create`)}
      </ModalCreateButton>
    </ModalButtonWrapper>

    {initialUser && confirm && <ConfirmModal closeModal={closeConfirm} title={``} onConfirm={onDelete} />}
  </BaseModal>;
};

export default AddUserModal;
