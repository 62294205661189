import React, { FunctionComponent } from "react";
import { useAlerts } from "../../../hooks/alertHook";
import { Kpis, KpiType } from "../../../enums/kpi.enum";
import { ProcessesEnums } from "../../../enums/processes-enums";
import { AlertListByProcess } from "../../shared/AlertListByProcess/AlertListByProcess";
import KpiContainer from "../../shared/KpiContainer/KpiContainer";
import OverviewTabContainer from "../../shared/OverviewTabContainer/OverviewTabContainer";
import styles from "./LocationTabsInbound.module.scss";

interface LocationTabsInboundProps {
  locationId: string;
}

const LocationTabsInbound: FunctionComponent<LocationTabsInboundProps> = ({ locationId }) => {
  const { alertsData } = useAlerts(locationId, ProcessesEnums.INBOUND);

  return (
    <div className={styles.inboundTab}>
      <div className={styles.onlyMobile}>
        <AlertListByProcess alertsData={alertsData} locationId={locationId} />
      </div>

      <div className={styles.overview}>
        <OverviewTabContainer type={Kpis.INBOUND_AMOUNT} />
      </div>

      <div className={styles.chart}>
        <KpiContainer location={locationId} kpiType={KpiType.INBOUND} comparison={true} />
      </div>

      <div className={styles.chart}>
        <KpiContainer location={locationId} kpiType={KpiType.INBOUND_TIME} comparison={true} />
      </div>

      <div className={styles.chart}>
        <KpiContainer location={locationId} kpiType={KpiType.PALLET_AMOUNT} comparison={true} />
      </div>
    </div>
  );
};

export default LocationTabsInbound;