import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNil } from "lodash";
import { Box, CircularProgress } from "@material-ui/core";
import { StorageType } from "../../../enums/kpi.enum";
import { StorageOverviewItem } from "../../../interfaces/kpi.interface";
import { fetchStorageOverview, kpiSelector } from "../../../store/kpis/kpiSlice";
import { getAllStorages, getStorageAllValue, getStorageValue } from "../../../helpers/storageHelper";
import { getStorageValueStyle } from "../../../helpers/locationHelper";
import StorageOptimizationModal from "../../modals/StorageOptimizationModal/StorageOptimizationModal";
import styles from "./StorageContainer.module.scss";

interface StorageContainerProps {
  location: string;
}

const StorageContainer: FunctionComponent<StorageContainerProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { storageOverview } = useSelector(kpiSelector);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch({
      ...fetchStorageOverview(
        {
          requestType: "GET",
          request: `/kpi/storageOverview?location=${location}`
        }
      )
    });
  }, [location]);

  return (
    <div className={styles.storageContainer}>
      <div className={styles.header}>storage</div>

      {
        isNil(storageOverview) || isEmpty(storageOverview.storageOverview) ?
          <Box className={styles.loader}>
            <CircularProgress />
          </Box> :
          <div className={styles.main}>
            <div className={styles.overallBar} style={getStorageValueStyle(getStorageAllValue(storageOverview.storageOverview))} />

            <div className={styles.storageInfo}>
              <div className={styles.overallInfo}>
                <div className={styles.overallPercentageContainer}>
                  <div className={styles.overallPercentage}>{getStorageAllValue(storageOverview.storageOverview)}%</div>
                  <div className={styles.overallText}>storage overall</div>
                </div>

                {
                  !isEmpty(storageOverview.optimization) &&
                  <div className={styles.optimizationButton} onClick={() => setShowModal(true)}>
                    <div className={styles.icon} />
                    <div className={styles.text} >
                      {Number(storageOverview.optimization[0].value)}% could be optimized
                    </div>
                    <div className={styles.arrow} />
                  </div>
                }
              </div>

              <div className={styles.storageBarsContainer}>
                {
                  getAllStorages(storageOverview.storageOverview).map((item: StorageOverviewItem, index: number) => (
                    <div key={index} className={styles.singleBarContainer}>
                      <div className={styles.singleBarPercentage}>{getStorageValue(item)}%</div>
                      <div className={styles.singleBar} style={getStorageValueStyle(getStorageValue(item))} />
                      <div className={styles.singleBarName}>
                        {item.type === StorageType.STORAGE_ABC ? "Storage" : "Zone"} {item.category}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
      }

      {showModal && <StorageOptimizationModal closeModal={() => setShowModal(false)} />}
    </div>
  );
};

export default StorageContainer;;