import React, { FunctionComponent, useState } from "react";
import { addDays, format, subDays } from "date-fns";
import { isEqual, isNil } from "lodash";
import DatePicker from "react-datepicker";
import { ITimeFrame, SHORT_TIME_FRAME_LIST, TimeFrame, TIME_FRAME_LIST } from "../../../enums/timeFrame.enum";
import { IDates } from "../../../interfaces/calendar.interface";
import styles from "./CalendarDropdown.module.scss";
import "react-datepicker/dist/react-datepicker.css";

enum CalendarTab {
  FROM = "from",
  TO = "to"
}

interface CalendarDropdownProps {
  getSelectedTimeFrame: (timeFrame: TimeFrame) => void;
  getSelectedCustomIntervals: (dates: IDates) => void;
  fullList?: boolean;
}

const CalendarDropdown: FunctionComponent<CalendarDropdownProps> = ({
  fullList = true,
  getSelectedTimeFrame,
  getSelectedCustomIntervals
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<ITimeFrame>(TIME_FRAME_LIST[0]);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [activeCalendarTab, setActiveCalendarTab] = useState<CalendarTab>(CalendarTab.FROM);
  const [dates, setDates] = useState<IDates>({ from: null, to: null });

  const selectedTimeFrameClickHandler = (): void => {
    setIsOpen((prevState: boolean) => !prevState);

    if (selectedTimeFrame.name === "Custom") {
      setShowCalendar(true);
    } else {
      setShowCalendar(false);
    }
  };

  const listElementClickHandler = (timeFrame: ITimeFrame): void => {
    setSelectedTimeFrame(timeFrame);

    if (timeFrame.name === "Custom") {
      setShowCalendar((prevState: boolean) => !prevState);
    } else {
      setIsOpen((prevState: boolean) => !prevState);
      getSelectedTimeFrame(timeFrame.value);
    }
  };

  const onDateSelectHandler = (date: Date): void => {
    setDates((prevState: IDates) => ({
      from: activeCalendarTab === CalendarTab.FROM ? date : prevState.from,
      to: activeCalendarTab === CalendarTab.TO ? date : prevState.to,
    }));
    setActiveCalendarTab((prevState: CalendarTab) => {
      if (prevState === CalendarTab.TO) {
        if (!isNil(dates.from)) {
          return CalendarTab.TO;
        } else {
          return CalendarTab.FROM;
        }
      }

      if (prevState === CalendarTab.FROM) {
        if (!isNil(dates.to)) {
          return CalendarTab.FROM;
        } else {
          return CalendarTab.TO;
        }
      }

      return CalendarTab.TO;
    });
  };

  const onApplyClickHandler = (): void => {
    if (isNil(dates) || isNil(dates.from) || isNil(dates.to)) {
      return;
    }
    setIsOpen((prevState: boolean) => !prevState);
    getSelectedCustomIntervals(dates);
    getSelectedTimeFrame(TimeFrame.CUSTOM);
  };

  return (
    <>
      <div className={`${styles.dropdownContainer} ${isOpen ? styles.opened : ""}`}>
        <div className={styles.selectedTimeFrame} onClick={selectedTimeFrameClickHandler}>
          <div className={styles.calendarIcon} />
          {selectedTimeFrame.name}
        </div>

        <div className={styles.timeFramesContainer}>
          <div className={styles.timeFrameList}>
            {
              (fullList ? TIME_FRAME_LIST : SHORT_TIME_FRAME_LIST).map((timeFrame: ITimeFrame, index: number) => (
                <div key={index}
                     className={`${styles.timeFrame} ${isEqual(timeFrame, selectedTimeFrame) ? styles.selected : ""}`}
                     onClick={() => listElementClickHandler(timeFrame)}>
                  {timeFrame.name}
                </div>
              ))
            }
          </div>

          {
            showCalendar &&
            <div className={styles.calendarSection}>
              <div className={styles.calendarButtons}>
                <div className={`${styles.singleCalendarButton} ${activeCalendarTab === CalendarTab.FROM ? styles.active : ""}`}
                     onClick={() => setActiveCalendarTab(CalendarTab.FROM)}>
                  <div className={styles.calendarIcon} />
                  {!isNil(dates) && !isNil(dates.from) ? format(dates.from, "dd.MM.yyyy") : "From"}
                </div>

                <div className={`${styles.singleCalendarButton} ${activeCalendarTab === CalendarTab.TO ? styles.active : ""}`}
                     onClick={() => setActiveCalendarTab(CalendarTab.TO)}>
                  <div className={styles.calendarIcon} />
                  {!isNil(dates) && !isNil(dates.to) ? format(dates.to, "dd.MM.yyyy") : "To"}
                </div>
              </div>

              <DatePicker selected={activeCalendarTab === CalendarTab.FROM ? dates.from : dates.to}
                          onChange={(date: Date) => onDateSelectHandler(date)}
                          calendarStartDay={1}
                          inline
                          disabledKeyboardNavigation
                          minDate={activeCalendarTab === CalendarTab.TO && !isNil(dates.from) ? addDays(dates.from, 1) : null}
                          maxDate={activeCalendarTab === CalendarTab.FROM && !isNil(dates.to) ? subDays(dates.to, 1) : addDays(new Date(), 7)} />

              <div className={`${styles.applyButton} ${!isNil(dates.from) && !isNil(dates.to) ? styles.active : ""}`}
                   onClick={onApplyClickHandler}>
                apply
              </div>
            </div>
          }
        </div>
      </div>

      {isOpen && <div className={styles.overlay} onClick={() => setIsOpen(false)} />}
    </>
  );
};

export default CalendarDropdown;