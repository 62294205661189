import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface bulkErrorInterface {
  type: string,
  message: string
}

interface initialAlertsStateInterface {
  data: any,
  allAlerts: any,
  alertDataPaginated: any,
  alert: any,
  isLoading: boolean,
  error: string,
}

const initialState: initialAlertsStateInterface = {
  data: null,
  alert: null,
  allAlerts: null,
  alertDataPaginated: null,
  isLoading: false,
  error: ""
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    fetchAlertsOnTabs: (state, action: any) => {
      if (action.state === "finished" && !!action.response) {
        state.data = action.response;
        state.isLoading = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchAlertsAllProcesses: (state, action: any) => {
      if (action.state === "finished" && !!action.response) {
        state.allAlerts = action.response;
        state.isLoading = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchForAlertsPage: (state, action: any) => {
      if (action.state === "finished" && !!action.response) {
        state.alertDataPaginated = action.response;
        state.isLoading = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchAlerts: (state, action: any) => {
      if (action.state === "finished" && !!action.response) {
        state.allAlerts = action.response;
        state.isLoading = false;
        state.error = "";
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      } else {
        state.isLoading = false;
      }
    },
    fetchSelf: (state, action: any) => {
      state.isLoading = true;
      if (action.state === "finished") {
        state.data = action.response;
        state.isLoading = false;
      }
    },
    fetchAlert: (state, action: any) => {

      if (action.state === "finished") {
        state.isLoading = false;
        state.error = "";
      }

      if (action.state === "finished" && !!action.response) {
        state.alert = action.response;
      } else if (action.state === "error") {
        state.isLoading = false;
        state.error = "Request error";
      }
    },
    addAlert: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        state.data = [...state.data, action.response];
        state.error = "";
        state.isLoading = false;
      }
      if (action.state === "error") {
        state.isLoading = false;
        state.error = action.response?.error;
      }
    },
    editAlert: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }
      if (action.state === "finished") {
        state.isLoading = false;
      }
    },
    deleteAlert: (state, action: any) => {
      if (action.state === "send") {
        state.isLoading = true;
      }

      if (action.state === "finished" && action.response) {
        state.data = [...state.data.filter((el: any) => el.id !== action.response.id)];
        state.isLoading = false;
        state.error = "";
      }
    },
    clearAlert: (state) => {
      state.alert = null;
      state.error = "";
      state.isLoading = false;
    },
    clearAlertStore: () => {
      return initialState;
    }
  }
});

export const {
  fetchAlerts,
  fetchAlertsAllProcesses,
  fetchAlertsOnTabs,
  fetchForAlertsPage,
  fetchAlert,
  editAlert,
  addAlert,
  clearAlert,
  deleteAlert,
  clearAlertStore
} = alertSlice.actions;
export const alertSelector = (state: RootState) => state.alert;
export default alertSlice.reducer;
