import React, { FunctionComponent } from "react";

import styles from "./PageWrapper.module.scss";

interface PageWrapperProps {
}

export const PageWrapper: FunctionComponent<PageWrapperProps> = ({ children }) => {

  return <div className={styles.wrapper}>{children}</div>;
};
