import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import alertIcon from "../../../assets/icons/alert-white.svg";
import { translate } from "../../../i18n/i18n";
import { editAlert } from "../../../store/alerts/alertSlice";
import styles from "./AlertListByProcessElement.module.scss";


interface AlertListByProcessElementProps {
  alert: any;
  isLoading: boolean;
}

export const AlertListByProcessElement: FunctionComponent<AlertListByProcessElementProps> = ({ alert, isLoading }) => {

  const dispatch = useDispatch();

  const confirm = (id: number) => {
    dispatch({
      ...editAlert({
        requestType: "PATCH",
        request: `/alert/confirm/${id}`
      })
    });
  };

  return <div className={`${styles.listElement} ${isLoading && styles.loading}`}>
    <img alt={"alertIcon"} src={alertIcon} className={styles.alertIcon} />
    <div className={styles.innerWrapper}>
      <span className={styles.header}>{alert.header}</span>
      <span className={styles.value}>{alert.value}</span>
      <button className={styles.confirmButton} disabled={isLoading} onClick={() => {confirm(alert.id)}}>{translate(`alertsOverviewTab.markAsResolve`)}</button>
    </div>
  </div>;
};