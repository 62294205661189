import React, {useEffect} from "react";
import { useHistory } from 'react-router-dom';


const AuthLayout = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('user')) {
      history.push('/dashboard');
    }
  });

  return (
    <>
    {children}
    </>
  );
}

export default AuthLayout;
