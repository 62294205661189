import { UserRole } from '../enums/users/users-enums';

export interface IMenuItem {
	label: string;
	path: string;
	iconPath: string;
	iconPathSelected: string;
	allowedRoles: UserRole[];
}

// /settings to be deleted maybe
export const MAIN_MENU_ITEMS = [
	{
		label: 'dashboard',
		path: '/dashboard',
		iconPath: '/icons/dashboard.svg',
		iconPathSelected: '/icons/dashboard-selected.svg',
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		label: 'users',
		path: '/settings/users',
		iconPath: '/icons/users.svg',
		iconPathSelected: '/icons/users-selected.svg',
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		label: 'contract values',
		path: '/settings/contractValues',
		iconPath: '/icons/contract-values.svg',
		iconPathSelected: '/icons/contract-values-selected.svg',
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN],
	},
	{
		label: 'alert list',
		path: '/alerts',
		iconPath: '/icons/alerts.svg',
		iconPathSelected: '/icons/alerts-selected.svg',
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
];

export const SUB_MENU_ITEMS = [
	{
		label: 'companies',
		path: '/settings/companies',
		iconPath: '/icons/companies.svg',
		iconPathSelected: '/icons/companies-selected.svg',
		allowedRoles: [UserRole.SUPER],
	},
	{
		label: 'locations',
		path: '/settings/locations',
		iconPath: '/icons/locations.svg',
		iconPathSelected: '/icons/locations-selected.svg',
		allowedRoles: [UserRole.SUPER],
	},
	{
		label: 'my profile',
		path: '/my-profile',
		iconPath: '',
		iconPathSelected: '',
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
	{
		label: 'logout',
		path: '/login',
		iconPath: '',
		iconPathSelected: '',
		allowedRoles: [UserRole.SUPER, UserRole.ADMIN, UserRole.USER],
	},
];
