export enum KpiType {
  OUTBOUND = "OUTBOUND",
  INBOUND = "INBOUND",
  PALLET_AMOUNT = "PALLET_AMOUNT",
  INBOUND_TIME = "INBOUND_TIME",
  OUTBOUND_TIME = "OUTBOUND_TIME",
  PARCEL_AMOUNT = "PARCEL_AMOUNT"
};

export enum KpiReference {
  PALLET_AMOUNT = "PALLET_AMOUNT",
  INBOUND_AMOUNT = "INBOUND_AMOUNT",
  INBOUND_TIME = "INBOUND_TIME",
  PUTAWAY_TIME = "PUTAWAY_TIME",
  UNLOAD_TIME = "UNLOAD_TIME",
  INBOUND_CHILL_TIME = "INBOUND_CHILL_TIME",
  EMPLOYEES_IN_INBOUND = "EMPLOYEES_IN_INBOUND",
  PARCEL_AMOUNT = "PARCEL_AMOUNT",
  DELIVERY_AMOUNT = "DELIVERY_AMOUNT",
  OUTBOUND_TIME = "OUTBOUND_TIME",
  PICK_TIME = "PICK_TIME",
  PACK_TIME = "PACK_TIME",
  LOAD_TIME = "LOAD_TIME",
  CONSOLIDATION_TIME = "CONSOLIDATION_TIME",
  OUTBOUND_CHILL_TIME = "OUTBOUND_CHILL_TIME",
  EMPLOYEES_IN_PICKING = "EMPLOYEES_IN_PICKING",
  EMPLOYEES_IN_PACKING = "EMPLOYEES_IN_PACKING",
  EMPLOYEES_IN_OUTBOUND = "EMPLOYEES_IN_OUTBOUND",
  STORAGE_ABC = "STORAGE_ABC",
  STORAGE_ZONE = "STORAGE_ZONE",
  STORAGE_ALL = "STORAGE_ALL",
  OPTIMIZATION_ALL = "OPTIMIZATION_ALL",
  OPTIMIZATION_ABC = "OPTIMIZATION_ABC",
  OPTIMIZATION_ZONE = "OPTIMIZATION_ZONE",
  EMPLOYEES = "EMPLOYEES"
}

export enum Kpis {
  PALLET_AMOUNT = "PALLET_AMOUNT",
  INBOUND_AMOUNT = "INBOUND_AMOUNT",
  INBOUND_TIME = "INBOUND_TIME",
  PUTAWAY_TIME = "PUTAWAY_TIME",
  UNLOAD_TIME = "UNLOAD_TIME",
  INBOUND_CHILL_TIME = "INBOUND_CHILL_TIME",
  EMPLOYEES_IN_INBOUND = "EMPLOYEES_IN_INBOUND",
  PARCEL_AMOUNT = "PARCEL_AMOUNT",
  DELIVERY_AMOUNT = "DELIVERY_AMOUNT",
  OUTBOUND_TIME = "OUTBOUND_TIME",
  PICK_TIME = "PICK_TIME",
  PACK_TIME = "PACK_TIME",
  LOAD_TIME = "LOAD_TIME",
  CONSOLIDATION_TIME = "CONSOLIDATION_TIME",
  OUTBOUND_CHILL_TIME = "OUTBOUND_CHILL_TIME",
  EMPLOYEES_IN_PICKING = "EMPLOYEES_IN_PICKING",
  EMPLOYEES_IN_PACKING = "EMPLOYEES_IN_PACKING",
  EMPLOYEES_IN_OUTBOUND = "EMPLOYEES_IN_OUTBOUND",
  STORAGE_ABC = "STORAGE_ABC",
  STORAGE_ZONE = "STORAGE_ZONE",
  STORAGE_ALL = "STORAGE_ALL",
  OPTIMIZATION_ALL = "OPTIMIZATION_ALL",
  OPTIMIZATION_ABC = "OPTIMIZATION_ABC",
  OPTIMIZATION_ZONE = "OPTIMIZATION_ZONE",
  EMPLOYEES = "EMPLOYEES"
}

export enum StorageType {
  STORAGE_ALL = "STORAGE_ALL",
  STORAGE_ABC = "STORAGE_ABC",
  STORAGE_ZONE = "STORAGE_ZONE"
}