import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { Grid } from 'semantic-ui-react';
import Loader from '../../components/shared/Loader';

import { translate } from '../../i18n/i18n';
import {
	userSelector,
	editMyUser,
	deleteUser,
	resetError,
	clearUserStore,
} from '../../store/users/userSlice';
import { PageWrapper } from '../../components/shared/PageWrapper/PageWrapper';
import ProfileInformationTab from '../../components/tabs/ProfileInformationTab';
import ProfilePasswordTab from '../../components/tabs/ProfilePasswordTab';
import useUserContext from '../../contexts/UserContext/UserContext';

import styles from './Profile.module.scss';
import ConfirmModal from '../../components/modals/ConfirmModal/ConfirmModal';
import { clearKpiStore } from '../../store/kpis/kpiSlice';
import { clearAlertStore } from '../../store/alerts/alertSlice';
import { clearCompanyStore } from '../../store/companies/companySlice';
import { clearContractStore } from '../../store/contracts/contractSlice';
import { clearLocationStore } from '../../store/locations/locationSlice';
import { clearNotificationStore } from '../../store/notifications/notificationSlice';
import { clearLoginStore } from '../auth/Login/loginSlice';
import axios from '../../app/axios';

export default function Profile() {
	const [activeTab, setActiveTab] = useState('info');
	const [showDelete, setShowDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [submittingPassword, setSubmittingPassword] = useState(false);

	const [passwordError, setPasswordError] = useState<null | 1 | 2 | 3>(null);
	const [disableButton, setDisableButton] = useState(true);
	const { user } = useUserContext();
	const history = useHistory();

	const { isLoading, error: userSubmitError } = useSelector(userSelector);
	const [values, setValues] = useState({
		lastName: '',
		firstName: '',
		email: '',
		oldPassword: '',
		newPassword: '',
		newPasswordConfirm: '',
	});

	const {
		lastName,
		firstName,
		email,
		oldPassword,
		newPassword,
		newPasswordConfirm,
	} = values;

	useEffect(() => {
		if (!!user) {
			setInitialValues();
		}
	}, [user, activeTab]);

	useEffect(() => {
		if (isLoading && showDelete) {
			setIsDeleting(true);
		}
	}, [isLoading]);

	useEffect(() => {
		if (isDeleting && !isLoading) {
			dispatch(clearKpiStore());
			dispatch(clearUserStore());
			dispatch(clearCompanyStore());
			dispatch(clearLocationStore());
			dispatch(clearContractStore());
			dispatch(clearAlertStore());
			dispatch(clearNotificationStore());
			dispatch(clearLoginStore());
			localStorage.clear();
			history.push('/login');
		}
	}, [isDeleting, isLoading]);

	const setInitialValues = () => {
		setValues({
			oldPassword: '',
			newPassword: '',
			newPasswordConfirm: '',
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
		});
	};

	useEffect(() => {
		if (!!userSubmitError) {
			setInitialValues();
		}
	}, [userSubmitError]);

	const [isMobile, _setIsMobile] = useState(window.innerWidth < 1024);
	const isMobileRef = useRef(isMobile);
	const setIsMobile = (val: boolean) => {
		isMobileRef.current = val;
		_setIsMobile(val);
	};
	useEffect(() => {
		const checkIfMobile = () => {
			if (window.innerWidth < 1024 && !isMobileRef.current) {
				setIsMobile(true);
			}
			if (window.innerWidth >= 1024 && isMobileRef.current) {
				setIsMobile(false);
			}
		};
		window.addEventListener('resize', checkIfMobile);
		return () => window.removeEventListener('resize', checkIfMobile);
	}, []);
	const dispatch = useDispatch();

	const handleChangeInput = (
		property: string,
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		if (!!userSubmitError) {
			dispatch({ ...resetError() });
		}
		if (passwordError) setPasswordError(null);

		setValues({ ...values, [property]: e.target.value });
	};

	useEffect(() => {
		if (!isEmpty(user)) {
			let buttonDisabled = true;

			if (
				(newPassword === newPasswordConfirm && !isEmpty(newPassword) && !isEmpty(oldPassword)) ||
				(
					(firstName !== user.firstName || lastName !== user.lastName || email !== user.email) &&
					!isEmpty(firstName) && !isEmpty(lastName) && !isEmpty(email) &&
					/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
				)
			) {
				buttonDisabled = false;
			}

			if (!oldPassword && !!newPassword && !!newPasswordConfirm) {
				buttonDisabled = true;
				setPasswordError(1);
			} else if (
				!!oldPassword &&
				!!newPassword &&
				!!newPasswordConfirm &&
				newPassword !== newPasswordConfirm
			) {
				buttonDisabled = true;
				setPasswordError(2);
			} else {
				// setPasswordError(null);
			}
			setDisableButton(buttonDisabled);
		}
	}, [values]);

	const handleUpdateUser = async () => {
		if (!isEmpty(oldPassword) && !isEmpty(newPassword) && newPassword === newPasswordConfirm) {
			setSubmittingPassword(true);
			const res = await axios
				.post('/user/change-password', {
					old_password: oldPassword,
					password: newPassword,
					c_password: newPassword,
				})
				.catch(error => {
					if (error.response.status === 401) {
						setPasswordError(3);
					}
				});
			if (res) {
				setValues({
					...values,
					oldPassword: '',
					newPassword: '',
					newPasswordConfirm: '',
				});
			}
		}

		if (firstName !== user.firstName || email !== user.email || lastName !== user.lastName) {
			const body: {
				firstName?: string;
				lastName?: string;
				email?: string;
			} = { firstName, lastName, email };
			dispatch({
				...editMyUser({
					requestType: 'PATCH',
					body,
					request: `/user/${user.id}`,
				}),
			});
		}

		setSubmittingPassword(false);
	};

	const handleDeleteUser = () => {
		dispatch({
			...deleteUser({
				requestType: 'DELETE',
				request: `/user/${user.id}`,
			}),
		});
	};

	return (
		<PageWrapper>
			{isLoading || !user || submittingPassword ? (
				<Loader />
			) : (
				<Grid>
					<Grid.Row>
						<Grid.Column width={16}>
							<div className={styles.headerWrapper}>
								<h3 className={styles.header}>
									{translate(`editProfile.myProfile`)}
								</h3>
							</div>
							<div className={styles.profileCard}>
								<div className={styles.tabNavigationWrapper}>
									<p
										onClick={() => setActiveTab('info')}
										className={`${styles.tabItem} ${
											activeTab === 'info'
												? styles.tabItemActive
												: ''
										}`}
									>
										{translate(
											'editProfile.profileInfoTab',
										)}
									</p>
									<p
										onClick={() => setActiveTab('password')}
										className={`${styles.tabItem} ${
											activeTab === 'password'
												? styles.tabItemActive
												: ''
										}`}
									>
										{translate(
											'editProfile.changePasswordTab',
										)}
									</p>
								</div>
								<div className={styles.tabWrapper}>
									{(activeTab === 'info' || !isMobile) && (
										<ProfileInformationTab
											handleChange={handleChangeInput}
											values={{
												firstName,
												lastName,
												email,
											}}
										/>
									)}
									{(activeTab === 'password' ||
										!isMobile) && (
										<ProfilePasswordTab
											passwordError={passwordError}
											handleChange={handleChangeInput}
											values={{
												oldPassword,
												newPassword,
												newPasswordConfirm,
											}}
										/>
									)}
								</div>
								<div className={styles.submitWrapper}>
									<button
										className={styles.submitButton}
										onClick={handleUpdateUser}
										disabled={disableButton}
									>
										{translate('editProfile.save')}
									</button>
									<a
										className={styles.deleteButton}
										onClick={() => setShowDelete(true)}
									>
										{translate('editProfile.delete')}
									</a>
								</div>
							</div>{' '}
							{!!passwordError && (
								<div className={styles.errorWrapper}>
									{translate(
										`editProfile.passwordError.${passwordError}`,
									)}
								</div>
							)}
							{!!userSubmitError && (
								<div className={styles.errorWrapper}>
									{userSubmitError}
								</div>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
			{showDelete && (
				<ConfirmModal
					closeModal={() => setShowDelete(false)}
					title={``}
					onConfirm={handleDeleteUser}
				/>
			)}
		</PageWrapper>
	);
}
