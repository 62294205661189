import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react';
import { Container, CssBaseline } from '@material-ui/core';
import logo from '../../../assets/images/logo-ldpi.png';
import { translate } from '../../../i18n/i18n';
import { logIn, loginSelector } from './loginSlice';
import { useStyles } from '../styles';

interface State {
	username: string;
	password: string;
	isLoading: boolean;
	loggingErrors: boolean;
	usernameError: boolean;
	passwordError: boolean;
	showPassword: boolean;
}

const Login = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { isSuccess, errorMessage } = useSelector(loginSelector);

	const [values, setValues] = useState<State>({
		username: '',
		password: '',
		isLoading: false,
		loggingErrors: false,
		usernameError: false,
		passwordError: false,
		showPassword: false,
	});

	useEffect(() => {
		if (localStorage.getItem('jwt')) {
			history.push('/dashboard');
		}
	}, []);

	useEffect(() => {
		if (isSuccess) {
			history.push('/dashboard');
		}
	}, [isSuccess]);

	const handleChange = (prop: keyof State) => (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const onSubmit = () => {
		dispatch({
			...logIn({
				body: {
					email: values.username,
					password: values.password,
				},
				request: '/user/login',
			}),
		});
	};

	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<img className={classes.Logo} src={logo} />
					{errorMessage && (
						<div className={classes.ErrorMsg}>{errorMessage}</div>
					)}
					<Form style={{ width: '100%' }} size="big">
						<label htmlFor="email" className={classes.Label}>
							Your email
						</label>
						<input
							placeholder={translate(
								`authentication.register.email`,
							)}
							onChange={handleChange('username')}
							className={classes.Input}
							name="email"
						/>
						<label htmlFor="password" className={classes.Label}>
							Password
						</label>
						<input
							placeholder="Passwort"
							type="password"
							onChange={handleChange('password')}
							className={classes.Input}
							name="password"
						/>
						<button
							disabled={!values.username || !values.password}
							onClick={onSubmit}
							className={classes.SubmitButton}
						>
							Anmelden
						</button>
					</Form>
				</div>
				<div className={classes.ForgotPasswordWrapper}>
					<Link
						className={classes.ForgotPasswordLink}
						to="/forgot-password"
					>
						{translate('forgetPassword')}
					</Link>
				</div>
			</Container>
		</div>
	);
};

export default Login;
