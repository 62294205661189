import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSelector } from "../../pages/auth/Login/loginSlice";
import { registerSelector } from "../../pages/auth/register/registerSlice";
import { fetchSelf, userSelector } from "../../store/users/userSlice";
import { UserContext } from "./UserContext";


type Props = {
  children: React.ReactNode
};

const PageContextProvider = ({ children }: Props) => {
  const [user, setUser] = React.useState<any>(null);
  const { isLoading } = useSelector(loginSelector);
  const { isSuccess } = useSelector(registerSelector);

  const { myUser } = useSelector(userSelector);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isLoading || isSuccess) {
      dispatch({
        ...fetchSelf({
          requestType: "GET",
          request: `/user/me`
        })
      });
    }
  }, [isLoading, isSuccess]);

  React.useEffect(() => {
    if (!isLoading && myUser) {
      setUser(myUser);
    }
  }, [isLoading, myUser]);


  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};
export default PageContextProvider;
