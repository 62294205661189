import React, { FunctionComponent } from "react";
import styles from "./ModalCreateButton.module.scss";

interface ModalCreateButtonInterface {
  onSubmit: () => void
}

export const ModalCreateButton: FunctionComponent<ModalCreateButtonInterface> = ({ onSubmit, children }) => {
  return <button onClick={onSubmit}
                 className={styles.createButton}>{children}</button>;
};