export interface paginationInterface {
    page: number,
    rowsPerPage: number,
    optionsRowsPerPage: number[]
};


export const initialPaginationState: paginationInterface = {
    page: 1,
    rowsPerPage: 10,
    optionsRowsPerPage: [10, 25, 50, 100]
}
